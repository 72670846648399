<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             placeholder="请选择"
             :clearable="clearable"
             :disabled="disabled"
             :multiple="multiple"
             @clear="clearAction"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.label"
      :value="item.value"
      @click.native="handleSelect(item)">
      <span style="float: left;"><i class="fa fa-modx" :style="{'color': '#66b1ff','margin-right': '5px'}"></i>{{ item.label }}</span>
    </el-option>
  </el-select>
</template>
<script>

// import { getCapacityPerson } from '@/api/admin/user'
import { getCapacityManager } from '@/api/admin/dept'
// 指派运力人员
export default {
  name: 'd2-container-capacity-user',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    findData () {
      this.loading = true
      getCapacityManager().then(response => {
        this.loading = false
        if (response.code === 0) {
          let listItem = []
          response.data.forEach(item => {
            let option = {}
            option.label = item.name
            option.value = item.username
            option.id = item.id
            listItem.push(option)
          })
          this.options = listItem
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      console.log('handleSelect', val)
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      console.log('values', val)
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      console.log('value', val)
      this.values = val
      // this.$emit('change', val)
    }
  },
  mounted () {
    this.findData()
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-capacity-user/index.vue"
}
</vue-filename-injector>
