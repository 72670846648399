import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsBid/page',
    method: 'post',
    data: query
  })
}
export function getSummary (query) {
  return request({
    url: '/tms/tmsBid/bondSummary',
    method: 'post',
    data: query
  })
}
/*
* 保证金台账 上传付款凭证*
*/
export function uploadAliPaymentCertificate (obj) {
  return request({
    url: '/tms/ossobject/uploadPaymentCertificate',
    method: 'post',
    data: obj
  })
}
/*
* 保证金台账 上传收款收据*
*/
export function uploadAliPaymentReceipt (obj) {
  return request({
    url: '/tms/ossobject/uploadPaymentReceipt',
    method: 'post',
    data: obj
  })
}
/**
 *
 批量新增跟踪备注
 */
export function batchAddTrack (obj) {
  return request({
    url: '/bms/accsettle/insertTrackRemark',
    method: 'post',
    params: obj
  })
}
/**
 *
 获取跟踪记录
 */
export function getObjList (customersId) {
  return request({
    url: '/tms/customersTrack/selectBondStanding',
    method: 'post',
    params: { customersId: customersId }
  })
}
/**
 *
 新增跟踪记录
 */
export function addCustomerTrack (obj) {
  return request({
    url: '/tms/customersTrack/insertBondStanding',
    method: 'post',
    data: obj
  })
}
/**
 *
 通过id删除跟踪记录
 */
export function delObj (id) {
  return request({
    url: '/tms/customersTrack/deleteCustomersTrack',
    method: 'post',
    params: { id: id }
  })
}
/**
 *
 新增保存
 */
export function savaDD (obj) {
  return request({
    url: '/tms/tmsBid/insertTmsBid',
    method: 'post',
    data: obj
  })
}
/**
 *
 修改保存
 */
export function updateDD (obj) {
  return request({
    url: '/tms/tmsBid/updateTmsBid',
    method: 'post',
    data: obj
  })
}
/**
 *
 删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsBid/deleteTmsBid',
    method: 'delete',
    params: ids
  })
}
/**
 *
 通过id 获取详情
 */
export function getData (id) {
  return request({
    url: '/tms/tmsBid/queryTmsBidMessage/' + id,
    method: 'get'
  })
}
/**
 * 通过ids进行审核
 */
export function dtExamine (ids) {
  return request({
    url: '/tms/tmsBid/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function dtUnexamine (ids) {
  return request({
    url: '/tms/tmsBid/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审单
 */
export function dtAudit (ids) {
  return request({
    url: '/tms/tmsBid/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function dtUnAudit (ids) {
  return request({
    url: '/tms/tmsBid/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 导出明细表
 */
export function secondFloorExport (ids) {
  return request({
    url: '/tms/tmsBid/biddtsExport',
    method: 'get',
    responseType: 'blob',
    params: ids
  })
}
