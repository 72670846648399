<template>
  <el-date-picker
      v-model="values"
      type="date"
      placeholder="选择日期"
      :disabled="disabled"
      :value-format="valueFormat"
      :picker-options="pickerOptions">
  </el-date-picker>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
// 截止日期 限制只能选择今天及以后的日期
export default {
  name: 'd2-container-expiry-date',
  data () {
    return {
      values: this.value,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000
        }
      }
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    valueFormat: {
      type: String,
      default: () => 'yyyy-MM-dd'
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      if (!validatenull(val)) {
        this.values = val
      } else {
        this.value = ''
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-expiry-date/index.vue"
}
</vue-filename-injector>
