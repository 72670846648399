<template>
    <div :id="id" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-line-trend',
  data () {
    return {
      xAxisData: [],
      seriesTotalNum: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
  },
  methods: {
    initChart (seriesname, xAxisData, seriesTotalNum) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入折线图组件
      require('echarts/lib/chart/line')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上一次值
      this.xAxisData = []
      this.seriesTotalNum = []
      // 赋值
      this.xAxisData = xAxisData
      this.seriesTotalNum = seriesTotalNum

      let myChart = echarts.init(document.getElementById(this.id))
      var options = {
        // backgroundColor: '#2c343c',
        // textStyle: {
        //   color: 'rgba(255, 255, 255, 1)'
        // },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] }
            // restore: { show: true },
            // saveAsImage: { show: true }
          }
        },
        // grid: {
        //   left: '3%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              // color: 'rgba(255, 255, 255, 1)'
              // color: '#7581BD'
            }
          },
          axisLabel: {
            // formatter: function (value) {
            //   return value.split('').join('\n')
            // }
          }
        },
        yAxis: {
          type: 'value',
          // y轴刻度线
          axisLine: {
            show: false
          },
          // 是否显示坐标轴刻度
          axisTick: {
            show: false
          },
          // 去掉网格线
          splitLine: {
            show: true
          }
        },
        series: [{
          name: seriesname,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          // sampling: 'average',
          itemStyle: {
            color: '#0770FF'
          },
          // zlevel: 2,
          // barWidth: '10%',
          // barGap: '100%',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(58,77,233,0.8)'
            }, {
              offset: 1,
              color: 'rgba(58,77,233,0.3)'
            }])
          },
          data: this.seriesTotalNum
        }]
      }
      myChart.setOption(options, true)
      // 浏览器监听屏幕大小变化，执行echarts中的chart.resize()方法。只要屏幕尺寸发生改变，echarts实例会紧跟着同比例放大缩小
      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-line-trend/index.vue"
}
</vue-filename-injector>
