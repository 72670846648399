<template>
  <el-select class="td_select"
             style="width: 100%"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             collapse-tags
             :placeholder="placeholder"
             :clearable="clearable"
             :disabled="disabled"
             :loading="loading"
             :multiple="multiple">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.companyName"
      :value="item.companyCode"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>

// import { validatenull } from '@/tdcommon/validate'
import { fetchTree } from '@/api/admin/company'
import { mapState } from 'vuex'

export default {
  name: 'd2-container-companyCode-moreselect',
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => '请选择法人公司'
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  created () {
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      fetchTree().then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      // console.log('watch: val', val)
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
      // this.$emit('change', '')
    }
    // value: function (val, oldVal) {
    //   console.log('watch: value-val', val)
    //   this.values = validatenull(val) ? [] : val
    //   this.remoteMethod(this.values)
    //   if (validatenull(this.values)) {
    //     this.$emit('change', [])
    //   }
    // }
  },
  mounted () {
    // console.log('mounted: this.values', this.values)
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-company-moreselect/index.vue"
}
</vue-filename-injector>
