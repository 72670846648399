<template>
    <div id="mySupplyChartLine" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-line',
  data () {
    return {
      xAxisData: [],
      seriesTotalNum: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    lineData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
  },
  methods: {
    initChart (xAxisData, seriesTotalNum) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入折线图组件
      require('echarts/lib/chart/line')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上一次值
      this.xAxisData = []
      this.seriesTotalNum = []
      // 赋值
      this.xAxisData = xAxisData
      this.seriesTotalNum = seriesTotalNum

      let myChart = echarts.init(document.getElementById('mySupplyChartLine'))
      var options = {
        backgroundColor: '#2c343c',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)'
        },
        tooltip: {
          trigger: 'axis'
        },
        // grid: {
        //   left: '1%',
        //   right: '4%',
        //   bottom: '1%',
        //   containLabel: true
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 1)'
            }
          },
          axisLabel: {
            // formatter: function (value) {
            //   return value.split('').join('\n')
            // }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '金额',
          type: 'line',
          // zlevel: 2,
          // barWidth: '10%',
          // barGap: '100%',
          data: this.seriesTotalNum,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(58,77,233,0.8)'
            }, {
              offset: 1,
              color: 'rgba(58,77,233,0.3)'
            }])
          }
        }]
      }
      myChart.setOption(options, true)
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-line-supplyhistory/index.vue"
}
</vue-filename-injector>
