<template>
  <el-pagination v-if="isPagination"
     :background="background"
     :small="small"
     :page-sizes="page_sizes"
     :page-size="page_size"
     :style="isPagination !== 'right'? 'text-align:left' : 'text-align:right'"
     layout="total, prev, pager, next, slot, jumper, sizes"
     :total="total"
     :page-count="page_count"
     :current-page="current_page"
     :popper-class="popper_class"
     :prev-text="prev_text"
     :next-text="next_text"
     @size-change="handleSizeChange"
     @current-change="handleCurrentChange"
     @click.native="tablePagination">
<!--     <span class="pageSizeInput">-->
<!--       <el-input class="pageSizeBorder" size="mini" v-model.number="page_size" @input="handlePageSize"></el-input> 条/页-->
<!--     </span>-->
  </el-pagination>
</template>

<script>
export default {
  name: 'd2-container-pagination',
  data () {
    return {
      current_page: 1 // 当前页
    }
  },
  props: {
    // 分页显示
    isPagination: {
      type: [Boolean, String],
      default: () => false
    },
    // 是否使用小型分页样式
    small: {
      type: Boolean,
      default: () => false
    },
    // 是否为分页按钮添加背景色
    background: {
      type: Boolean,
      default: () => true
    },
    // 每页显示个数选择器的选项设置
    page_sizes: {
      type: Array,
      default: () => [50, 100, 200, 300, 400, 500]
    },
    // 每页显示条目个数
    page_size: {
      type: Number,
      default: () => 50
    },
    // 总条目数
    total: {
      type: Number,
      default: () => 5000
    },
    // 总页数，total 和 page-count 设置任意一个就可以达到显示页码的功能；如果要支持 page-sizes 的更改，则需要使用 total 属性
    page_count: {
      type: Number
    },
    // 每页显示个数选择器的下拉框类名
    popper_class: {
      type: String
    },
    // 替代图标显示的上一页文字
    prev_text: {
      type: String
    },
    // 替代图标显示的下一页文字
    next_text: {
      type: String
    }
  },
  created () {
    this.tablePagination()
  },
  methods: {
    // 每页条数 （pageSize 改变时会触发，回调参数：每页条数size）
    handleSizeChange (size) {
      this.limit = size
      this.$emit('handleSizeChange', size)
    },
    // 当前页 （currentPage 改变时会触发，回调参数：当前页currentPage）
    handleCurrentChange (val) {
      this.page = val
      this.$emit('handleCurrentChange', val)
    },
    // 自定义输入页码
    handlePageSize (val) {
      this.$emit('handlePageSize', Number(val))
    },
    // 点击切换的事件
    tablePagination (row, column, cell, event) {
      this.$emit('tablePagination', row, column, cell, event)
    }
  }
}
</script>

<style lang="scss">
  .pageSizeInput {
    margin-left: 20px;
    font-weight: 400;
    color: #606266;
  }
  .pageSizeBorder {
    width: 100px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-pagination-slotinput/index.vue"
}
</vue-filename-injector>
