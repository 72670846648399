<template>
    <el-dialog title="导出记录" :visible.sync="exportRecordsVisible" :width="dialogWidth" style="height: 90%;" :close-on-click-modal="false" append-to-body :before-close="handleClose">
      <div style="margin-bottom: 10px;">
        <el-button type="primary" title="刷新导出记录" size="mini" icon="el-icon-refresh" @click.native.prevent="handleRefresh()">刷新记录</el-button>
      </div>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="loading_text"
          :height="table_height"
          border
          style="width: 100%">
        <el-table-column prop="downloadTime" label="日期" width="180" header-align="center" align="center"></el-table-column>
        <el-table-column prop="source" label="来源" width="180" header-align="center" align="center"></el-table-column>
        <el-table-column prop="downloadUser" label="操作人" header-align="center" align="center"></el-table-column>
        <el-table-column prop="address" label="状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="mini" v-if="scope.row.states === '1'">导出完成</el-tag>
            <el-tag type="info" size="mini" v-else>导出中</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="primary" title="下载" size="mini" :disabled="scope.row.states !== '1'" @click.native.prevent="handleDownload(scope.row)">下载</el-button>
            <el-button type="danger" title="删除" size="mini" @click.native.prevent="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页显示-->
      <el-pagination
          v-if="isPagination"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="50"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.page.total"
          :style="isPagination !== 'right'? 'text-align:left' : 'text-align:right'">
      </el-pagination>
    </el-dialog>
</template>
<script>
import { fetchList, delObj } from '@/api/tms/tmsexportrecords'
// import { downloadDoc } from '@/tdcommon/ExcelExport'
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-export-records',
  data () {
    return {
      tableData: [],
      loading: false,
      page: {
        total: 0, // 总条数
        currentPage: 1, // 当前页
        pageSize: 50 // 每页显示多少条
      }
    }
  },
  props: {
    exportRecordsVisible: {
      type: Boolean,
      default: () => false
    },
    dialogWidth: {
      type: String,
      default: () => '50%'
    },
    // 加载文字信息
    loading_text: {
      type: String,
      default: () => '正在加载数据，请稍等.....'
    },
    // 表格高度
    table_height: {
      type: [String, Number],
      default: () => '500px'
    },
    // 分页显示位置
    isPagination: {
      type: [Boolean, String],
      default: () => 'right'
    }
  },
  methods: {
    handleRefresh () {
      this.remoteMethod()
    },
    remoteMethod () {
      this.loading = true
      fetchList(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize
      })).then(response => {
        this.loading = false
        console.log('获取记录', response)
        this.tableData = response.data.records
        this.page.total = response.data.total
        this.page.pageSize = response.data.size
        this.page.currentPage = response.data.current
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    // 关闭时动作处理
    handleClose (done) {
      this.$emit('exportHandleClose', false)
    },
    // 下载
    handleDownload (row) {
      // const ele = document.createElement('a')
      // ele.setAttribute('href', row.url) // 设置下载文件的url地址
      // ele.setAttribute('download', row.source) // 用于设置下载文件的文件名
      // ele.click()
      var eleLink = document.createElement('a')
      eleLink.download = row.source + '.csv'
      eleLink.style.display = 'none'
      eleLink.href = row.url
      // 自动触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    // 删除
    handleDelete (row) {
      this.$confirm('是否确认删除此条导出记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delObj(Object.assign({
          ids: row.id
        })).then(response => {
          if (response.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.remoteMethod()
          } else {
            this.$message.error(response.msg)
          }
        })
      })
    },
    // 每页条数
    handleSizeChange (val) {
      this.page.pageSize = val
      this.remoteMethod(this.page)
    },
    // 当前页
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.remoteMethod(this.page)
    }
  },
  watch: {
  },
  mounted () {
    // this.remoteMethod()
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-export-records/index.vue"
}
</vue-filename-injector>
