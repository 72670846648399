<template>
  <div class="amap-page-container">
    <div :style="{width:'100%',height:'680px'}">
      <el-amap vid="amapDemo" class="amap-demo" :center="center" :zoom="zoom" :amap-manager="amapManager" :events="events">
        <div v-if="this.positionType === 'carPosition'">
          <el-amap-marker vid="component-marker" :position="position" :events="marker.events"></el-amap-marker>
          <el-amap-info-window v-if="currentWindow.visible" :position="currentWindow.position" :content="currentWindow.content" :visible="currentWindow.visible" :events="currentWindow.events"></el-amap-info-window>
        </div>
        <div v-else-if="this.positionType === 'carTrack'">
          <div v-for="(item, index) in markers" :key="index">
            <el-amap-marker vid="polyline-marker" :position="item.position" :events="item.events" :title="index === 0 ? '起点' : '终点'" :label="{ content: (index === 0 ? '起点' : '终点') + '（' + item.content + '）', offset: item.offset }"></el-amap-marker>
<!--            <el-amap-text :text="index === 0 ? '起点（时间：' + item.time + '）' : '终点（时间：' + item.time + '）'" :offset="item.offset"></el-amap-text>-->
          </div>
<!--          <el-amap-text v-if="index === 1" text="终点" :offset="item.offset"></el-amap-text>-->
<!--          <el-amap-info-window v-if="window" :position="window.position" :visible="window.visible" :content="window.content"></el-amap-info-window>-->
          <el-amap-polyline :editable="polyline.editable"  :path="polyline.path" :events="polyline.events"></el-amap-polyline>
        </div>
      </el-amap>
    </div>
    <div class="toolbar">
        <span v-if="loaded">
          <el-tag size="" type="danger">经纬度：</el-tag>
          <el-tag size="" type="info">经度</el-tag><span style="font-size: 14px;">= {{ lng }}</span>
          <el-tag size="" type="info">纬度</el-tag><span style="font-size: 14px;">= {{ lat }}</span>
        </span>
        <div v-if="loadedTrack">
          <div v-for="(item, index) in markers" :key="index">
            <el-tag size="" type="danger">{{index === 0 ? '起点：' : '终点：'}}</el-tag>
            <el-tag size="" type="info">时间</el-tag><span style="font-size: 14px;">= {{ item.time }}</span>
            <el-tag size="" type="info">经度</el-tag><span style="font-size: 14px;">= {{ item.lon }}</span>
            <el-tag size="" type="info">纬度</el-tag><span style="font-size: 14px;">= {{ item.lat }}</span>
          </div>
      </div>
      <span v-else></span>
    </div>
  </div>
</template>
<script>

import { getGpsPosition, getGpsTrack } from '@/api/basic/carinfo'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
import { validatenull } from '@/tdcommon/validate'
let amapManager = new AMapManager() // 新建生成地图画布
export default {
  name: 'd2-container-golde-location',
  data () {
    let self = this
    return {
      lng: 0,
      lat: 0,
      adr: '',
      loaded: false,
      loadedTrack: false,
      zoom: 9,
      center: [121.59996, 31.197646],
      amapManager,
      positionType: '', // 定位类型（定位或者轨迹）
      position: [121.59996, 31.197646],
      windows: [],
      window: '',
      currentWindow: {
        position: [0, 0],
        content: '',
        offset: [5, -15],
        events: {
          close () {
            self.currentWindow.visible = false
          }
        },
        visible: false
      },
      marker: {
        events: {
          click (e) {
            const { lng, lat } = e.lnglat
            self.lng = lng
            self.lat = lat
            // this.slotWindow.position = [this.lng, this.lat]
            // self.currentWindow.position = [this.lng, this.lat]
            // self.currentWindow.content = '地址：' + self.adr
            self.currentWindow.visible = true
          }
        }
      },
      markers: [],
      polyline: {
        path: [],
        events: {
          click (e) {
            // alert('click polyline')
            this.polyline.editable = true
          },
          end: (e) => {
            // e.target.getPath().map(point => [point.lng, point.lat])
          }
        },
        editable: false
      },
      events: {
        click (e) {
          const { lng, lat } = e.lnglat
          self.lng = lng
          self.lat = lat
        },
        init (o) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initPage() // 初始化数据self指向this
            // self.changeMap() // 绑定鼠标缩放事件
            // self.setLine() // 画一条北京到莫斯科的路线
          })
        }
      }
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    // this.getInit()
    // this.point()
  },
  methods: {
    initPage () {},
    getCarTrackMsg (carNo, startDate, endDate) {
      this.getCarPositionMsg(carNo) // 先调一下定位
      this.positionType = 'carTrack' // 类型设为轨迹
      getGpsTrack(carNo, startDate, endDate).then(response => {
        if (response.code === 0) {
          let resObjs = JSON.parse(response.data)
          // 轨迹
          let itenMsg = []
          // 点
          let markers = []
          // 信息窗体
          // let windows = []

          // let self = this
          resObjs.forEach((item, index) => {
            // 轨迹
            let option = []
            option.push((Number(item.lon) / 600000).toFixed(6), (Number(item.lat) / 600000).toFixed(6))
            itenMsg.push(option)
            // 点
            markers.push({
              position: [(Number(item.lon) / 600000).toFixed(6), (Number(item.lat) / 600000).toFixed(6)],
              // icon: item.url
              // events: {
              //   click () {
              //     self.windows.forEach(window => {
              //       window.visible = false // 关闭窗体
              //     })
              //
              //     self.window = self.windows[index]
              //     self.$nextTick(() => {
              //       self.window.visible = true // 点击点坐标，出现信息窗体
              //     })
              //   }
              // },
              time: item.gtm,
              lon: (Number(item.lon) / 600000).toFixed(6),
              lat: (Number(item.lat) / 600000).toFixed(6),
              content: '时间：' + item.gtm + '； 经度：' + (Number(item.lon) / 600000).toFixed(6) + '； 纬度：' + (Number(item.lat) / 600000).toFixed(6),
              // text: '终点',
              offset: [0, -25]
            })
            // 信息窗体
            // windows.push({
            //   position: [(Number(item.lon) / 600000).toFixed(6), (Number(item.lat) / 600000).toFixed(6)],
            //   content: ' 经度：' + (Number(item.lon) / 600000).toFixed(6) + ' 纬度：' + (Number(item.lat) / 600000).toFixed(6),
            //   visible: false // 初始是否显示
            // })
          })
          // 加轨迹
          this.polyline.path = itenMsg
          //  加点
          // this.markers = markers
          this.markers = markers.slice(0, 1).concat(markers.slice(-1)) // 轨迹点取首尾两点
          this.loadedTrack = true
          this.loaded = false
          // 加弹窗
          // this.windows = windows
        } else {
          this.$alert('未找到此车牌号的定位信息！', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
            }
          })
          // this.$message.error('未找到此车牌号的定位信息！')
        }
      })
    },
    getCarPositionMsg (carno) {
      this.positionType = 'carPosition' // 类型设为定位
      if (validatenull(carno)) {
        return ''
      } else {
        getGpsPosition(carno).then(response => { // 豫FB5520
          if (response.code === 0) {
            let resObj = JSON.parse(response.data)
            this.lng = (Number(resObj.lon) / 600000).toFixed(6)
            this.lat = (Number(resObj.lat) / 600000).toFixed(6)
            this.adr = resObj.adr
            this.center = [this.lng, this.lat]
            this.position = [this.lng, this.lat]
            this.currentWindow.position = [this.lng, this.lat]
            this.currentWindow.content = '地址：' + this.adr
            this.loaded = true
            this.loadedTrack = false
            this.$nextTick(() => {
              this.currentWindow.visible = true
            })
          } else {
            this.$alert('未找到此车牌号的定位信息！', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
              }
            })
            // this.$message.error('未找到此车牌号的定位信息！')
          }
        })
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .amap-demo {
    height: 300px;
  }
  .toolbar {
    margin-top: 10px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-golde-location/index.vue"
}
</vue-filename-injector>
