<template>
    <el-table :data="resultData" stripe border show-overflow-tooltip="true" height="300px">
      <el-table-column header-align="center" align="center" prop="number" width="60" type="index"
                       :show-overflow-tooltip="true"></el-table-column>
      <el-table-column header-align="center" align="center" prop="status" label="状态" width="70"
                       :show-overflow-tooltip="true">
        <template slot-scope="slot">
          <el-tag type="danger" size="mini" v-if="slot.row.status === 0 || validatenullMark(slot.row.status)">失败</el-tag>
          <el-tag type="success" size="mini" v-if="slot.row.status === 1">成功</el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="content" label="结果"
                       :show-overflow-tooltip="true"></el-table-column>
      <el-table-column header-align="center" align="center" prop="docNo" :label="docNoLabel" :width="docNoWidth"
                       :show-overflow-tooltip="true"></el-table-column>
    </el-table>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-msg-list',
  data () {
    return {
    }
  },
  props: {
    resultData: {
      type: Array,
      default: () => []
    },
    docNoLabel: {
      type: String,
      default: () => '标记'
    },
    docNoWidth: {
      type: String,
      default: () => '170'
    }
  },
  methods: {
    validatenullMark (val) {
      return validatenull(val)
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-msg-list/index.vue"
}
</vue-filename-injector>
