import request from '@/plugin/axios'
/*
* 分页查询*
*/
export function fetchList (query) {
  return request({
    url: '/tms/accfp/page',
    method: 'post',
    data: query
  })
}
/*
* 新增发票管理*
*/
export function addObj (obj) {
  return request({
    url: '/tms/accfp',
    method: 'post',
    data: obj
  })
}
/*
*修改发票管理*
*/
export function putObj (obj) {
  return request({
    url: '/tms/accfp',
    method: 'put',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function getObj (id) {
  return request({
    url: '/tms/accfp/' + id,
    method: 'get'
  })
}
/*
*通过id删除*
*/
export function delObj (id) {
  return request({
    url: '/tms/accfp/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids进行审单
 */
export function fpAudit (ids) {
  return request({
    url: '/tms/accfp/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function fpUnaudit (ids) {
  return request({
    url: '/tms/accfp/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function fpExamine (ids) {
  return request({
    url: '/tms/accfp/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function fpUnexamine (ids) {
  return request({
    url: '/tms/accfp/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行作废
 */
export function fpcancell (ids) {
  return request({
    url: '/tms/accfp/invalid',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反作废
 */
export function fpUncancell (ids) {
  return request({
    url: '/tms/accfp/invalidundo',
    method: 'post',
    params: ids
  })
}
/**
 * 修改发票
 */
export function fpupdate (obj) {
  return request({
    url: '/tms/accfp/update',
    method: 'post',
    data: obj
  })
}
/**
 * 新增保存
 */
export function fpsava (obj) {
  return request({
    url: '/tms/accfp/create',
    method: 'post',
    data: obj
  })
}
/**
 * 发票 库存信息
 */
export function fpoutpage (query) {
  return request({
    url: '/tms/accfp/search/stock',
    method: 'post',
    data: query
  })
}
/**
 * 传参取得库存
 */
export function fpgenerate (obj) {
  return request({
    url: '/tms/accfp/search/verify',
    method: 'post',
    data: obj
  })
}
/**
 * 通过搜索 得到车牌号名称
 */
export function getsalesman (search) {
  return request({
    url: '/tms/accfprealcompany/list',
    method: 'get',
    params: { companyName: search }
  })
}
/**
 *
 库存预装删除操作
 */
export function fpoutdel (id) {
  return request({
    url: '/tms/accfp/details/remove/' + id,
    method: 'get'
  })
}
/**
 * 发票登记
 */
export function fpregister (obj) {
  return request({
    url: '/tms/accfp/register',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function fpedit (id) {
  return request({
    url: '/tms/accfp/' + id,
    method: 'get'
  })
}
/**
 * 发票明细导出*
 */
export function fpDetailsExport (ids) {
  return request({
    url: '/tms/accfp/export',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 打印 开票申请单
 */
export function printKpApplication (obj) {
  return request({
    url: '/tms/accfp/billingApplication',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 打印 发票签收单
 */
export function printFpSign (obj) {
  return request({
    url: '/tms/accfp/invoiceReceipt',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 销项发票跳转客户结算 获取销项发票运单明细数据
 */
export function toCussettle (ids) {
  return request({
    url: '/tms/accfp/settle/details',
    method: 'get',
    params: ids
  })
}
/*
* 上传阿里 销项发票申请单*
*/
export function uploadAliSalesInvoice (obj) {
  return request({
    url: '/tms/ossobject/outputInvoiceUpload',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行上传阿里 申请单审核
 */
export function uploadAliAudit (ids) {
  return request({
    url: '/tms/accfp/auditFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 申请单反审
 */
export function uploadAliUnAudit (ids) {
  return request({
    url: '/tms/accfp/auditDocUndoFpIds',
    method: 'post',
    params: ids
  })
}
/*
* 上传阿里 销项发票对账单*
*/
export function uploadAliSalesStatement (obj) {
  return request({
    url: '/tms/ossobject/accountStatementUpload',
    method: 'post',
    data: obj
  })
}
/*
* 上传阿里 销项发票签收单*
*/
export function uploadAliSalesSignature (obj) {
  return request({
    url: '/tms/ossobject/receiptFormUpload',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 批量下载
 */
export function aliBatchSalesDownload (obj) {
  return request({
    url: '/tms/ossobject/salesInvoiceAli',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 通过ids进行上传阿里 对账单审核
 */
export function uploadAliStatement (ids) {
  return request({
    url: '/tms/accfp/accountStatementFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 对账单反审
 */
export function uploadAliUnStatement (ids) {
  return request({
    url: '/tms/accfp/accountStatementUndoFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 签收单审核
 */
export function uploadAliReceiptForm (ids) {
  return request({
    url: '/tms/accfp/receiptFormFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 签收单反审
 */
export function uploadAliUnReceiptForm (ids) {
  return request({
    url: '/tms/accfp/receiptFormFpIdsUndoFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 寄件登记
 */
export function mailRegister (obj) {
  return request({
    url: '/tms/accfp/mailRegisterContent',
    method: 'post',
    data: obj
  })
}
