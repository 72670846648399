import request from '@/plugin/axios'
// 分页查询
export function fetchList (query) {
  return request({
    url: '/convoy/mangement/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObj (obj) {
  return request({
    url: '/convoy/mangement/insertMangement',
    method: 'post',
    data: obj
  })
}
// 修改保存
export function putObj (obj) {
  return request({
    url: '/convoy/mangement/updateMangement',
    method: 'post',
    data: obj
  })
}

/**
 * 通过ids批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/convoy/mangement/deleteMangement',
    method: 'post',
    params: ids
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/convoy/mangement/queryById/' + id,
    method: 'get'
  })
}
/**
 * 通过ids进行 初审
 */
export function trAudit (ids) {
  return request({
    url: '/convoy/mangement/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function trUnAudit (ids) {
  return request({
    url: '/convoy/mangement/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function trExamine (ids) {
  return request({
    url: '/convoy/mangement/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function trUnExamine (ids) {
  return request({
    url: '/convoy/mangement/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliCostManagement (obj) {
  return request({
    url: '/tms/ossobject/costManagement',
    method: 'post',
    data: obj
  })
}
