import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsBid/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsBid/insertTmsBid',
    method: 'post',
    data: obj
  })
}

export function tredit (id) {
  return request({
    url: '/tms/tmsBid/queryTmsBidMessage/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsBid/updateTmsBid',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsBid/deleteTmsBid',
    method: 'delete',
    params: ids
  })
}
/**
 * 统计
 */
export function statisticsMsg () {
  return request({
    url: '/tms/tmsBid/bidBondStatistics',
    method: 'post'
  })
}
/**
 * 投标保证金导入 返回结果
 */
export function returnTenderImport () {
  return request({
    url: '/tms/tmsBid/import/progress',
    method: 'get'
  })
}
export function blacklistImport () {
  return request({
    url: '/basic/blacklist/import/progress',
    method: 'get'
  })
}
