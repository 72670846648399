import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/bms/accdtitems/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/bms/accdtitems',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/bms/accdtitems/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/bms/accdtitems/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/bms/accdtitems',
    method: 'put',
    data: obj
  })
}

/**
 * 会计科目 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/bms/accdtitems/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 会计科目 获取树形数据
 */
export function fetchtree (query) {
  return request({
    url: '/bms/accdtitems/tree',
    method: 'get',
    data: query
  })
}
/**
 * 会计科目 获取树形数据
 */
export function getParentId (parentId) {
  return request({
    url: '/bms/accdtitems/treenode/' + parentId,
    method: 'get'
  })
}
/**
 * 会计科目 批量删除
 */
export function getSubjectObjs (ids) {
  return request({
    url: '/bms/accdtitems/items',
    method: 'post',
    params: { itemId: ids }
  })
}
/**
 * 查询科目编码是否重复
 */
export function getCodeRepeat (obj) {
  return request({
    url: '/bms/accdtitems/selectSubjectCodeRepeat',
    method: 'post',
    params: obj
  })
}
