<template>
  <div>
    <el-progress :width="160" :text-inside="true" :stroke-width="26" :percentage="percentage"></el-progress>
    <i class="el-icon-loading"></i><span>{{perTag}}</span>
  </div>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { excelExportFile } from '@/tdcommon/ExcelExport'
import { getdict } from '@api/admin/dict'
import { floatAdd } from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-progress',
  data () {
    return {
      percentage: 0,
      perTag: '平台正在请求数据，请稍等',
      options: [],
      loading: false,
      tableColumnData: [],
      allData: []
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  beforeDestroy () {
    this.allData.clear()
    this.percentage = 0
    this.perTag = '平台正在请求数据，请稍等'
  },
  methods: {
    getVisible () {},
    remoteMethod (firstpage, getlistdata, tablecolumns, fileName, searchform, totalNum, pageSize, descs, amount) {
      this.allData = []
      this.percentage = 0
      this.perTag = '平台正在请求数据，请稍等'
      this.tableColumnData = tablecolumns
      pageSize = 1000
      if (getlistdata !== undefined) {
        this.exelDate(getlistdata, tablecolumns, fileName, searchform, totalNum, pageSize, descs, 1, amount)
      }
    },
    // 字典项内容显示  drawer页面
    getDataUrlValue () {
      this.tableColumnData.forEach((item, index) => {
        if (item.dictType !== null && item.dictType.length > 0) {
          // 调取字典项获取方法
          this.settingTypeAsync(item.dictType, index, item)
        }
      })
    },
    // 字典项内容获取方法
    settingTypeAsync (type, index, item) {
      getdict(type).then(response => {
        this.tableColumnData[index].editOptions = response.data
      }).catch(() => {
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    exelDate (getlistdata, tablecolumns, fileName, searchform, totalNum, pageSize, descs, currentPage, amount) {
      if (this.allData.length === totalNum) {
        this.perTag = '正在生成EXCEL,请稍等'
        excelExportFile(tablecolumns, this.allData, fileName)
        setTimeout(() => {
          this.$emit('closeProgressVisible')
          this.perTag = '平台正在请求数据，请稍等'
        }, 2000)
        return null
      } else {
        getlistdata(Object.assign({
          current: currentPage,
          size: pageSize,
          descs: descs
        }, searchform)).then(response => {
          this.perTag = '正在下载数据，请稍等'
          if (validatenull(response.data.records)) {
            excelExportFile(tablecolumns, this.allData, fileName)
            setTimeout(() => {
              // this.progressVisible = false
              this.$emit('closeProgressVisible')
            }, 2000)
            return null
          } else {
            // for (var i = 0, len = response.data.records.length; i < len; i++) {
            //   this.allData.push(response.data.records[i])
            //   this.percentage = parseInt((this.allData.length / totalNum) * 100)
            // }
            this.allData = this.allData.concat(response.data.records)
            // 全部页合计导出
            if (!validatenull(amount)) {
              this.allData = this.allData.concat(amount)
            }
            this.percentage = parseInt((this.allData.length / totalNum) * 100)
            setTimeout(() => {
              this.exelDate(getlistdata, tablecolumns, fileName, searchform, totalNum, pageSize, descs, floatAdd(currentPage, 1), amount)
            }, 200)
          }
        })
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.values = validatenull(val) ? '' : val
      this.remoteMethod(this.values)
      if (validatenull(this.values)) {
        this.$emit('change', '')
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-progress/index.vue"
}
</vue-filename-injector>
