import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/subprojectsQuotation/page',
    method: 'post',
    data: query
  })
}

/**
 * 通过id删除
 */
export function deltrids (id) {
  return request({
    url: '/basic/subprojectsQuotation/deleteSubprojectsQuotation',
    method: 'post',
    params: { id: id }
  })
}

/**
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/basic/subprojectsQuotation/querySubprojectsQuotationMessage/' + id,
    method: 'get'
  })
}
/**
 * 新增 铁路线路成本保存
 */
export function saveLineCb (obj) {
  return request({
    url: '/basic/costRailwayLine/insertRailwayLine',
    method: 'post',
    data: obj
  })
}
/**
 * 通过id删除 铁路线路成本
 */
export function delLineCb (id) {
  return request({
    url: '/basic/quotationRailwayLine/deleteRailwayLine',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 通过主表id查询铁路线路成本 线路名称
 */
export function selectRailwayLine (id) {
  return request({
    url: '/basic/quotationRailwayLine/selectRailwayLine',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 新增保存
 */
export function addSubProject (obj) {
  return request({
    url: '/basic/subprojectsQuotation/insertSubprojectsQuotation',
    method: 'post',
    data: obj
  })
}
/**
* 更新保存
*/
export function updateSubProject (obj) {
  return request({
    url: '/basic/subprojectsQuotation/updateSubprojectsQuotation',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 初审
 */
export function auditSubProject (ids) {
  return request({
    url: '/basic/subprojectsQuotation/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function unAuditSubProject (ids) {
  return request({
    url: '/basic/subprojectsQuotation/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function examineSubProject (ids) {
  return request({
    url: '/basic/subprojectsQuotation/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function unExamineSubProject (ids) {
  return request({
    url: '/basic/subprojectsQuotation/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过id进行 启用
 */
export function enableObj (ids) {
  return request({
    url: '/basic/subprojectsQuotation/enableStatus',
    method: 'post',
    params: ids
  })
}
/**
 * 通过id进行 停用
 */
export function stopObj (ids) {
  return request({
    url: '/basic/subprojectsQuotation/disableStatus',
    method: 'post',
    params: ids
  })
}
/**
 * 根据始发地目的地查询公里数
 */
export function obtainKilometers (obj) {
  return request({
    url: '/basic/subprojectsCost/obtainKilometerInformation',
    method: 'post',
    params: obj
  })
}
/**
 * 上传合同 保存到后台
 */
export function uploadAliSubprojectQuotation (obj) {
  return request({
    url: '/tms/ossobject/uploadSubprojectsQuotation',
    method: 'post',
    data: obj
  })
}
/**
 * 预订单、托运单 根据 项目id 查询子项目
 */
export function selectCustomerSubProject (obj) {
  return request({
    url: '/basic/subprojectsQuotation/selectCustomerSubProject',
    method: 'post',
    params: obj
  })
}
/**
 * 获取子项目（搜索下拉）
 */
export function getSubprojectQuotation (subproject) {
  return request({
    url: '/basic/subprojectsQuotation/getDropDownListData',
    method: 'get',
    params: { subproject: subproject }
  })
}
/**
 * 子项目报价单 托运单金额判断
 */
export function judgeSubprojectsQuotation (obj) {
  return request({
    url: '/basic/subprojectsQuotation/judgeSubprojectsQuotation',
    method: 'post',
    params: obj
  })
}
/**
 * 子项目报价单 短驳单金额判断
 */
export function judgeWhetherShortBarge (obj) {
  return request({
    url: '/basic/subprojectsQuotation/selectJudgmentWhetherShortBarge',
    method: 'post',
    params: obj
  })
}
/**
 * 子项目报价单 中转单金额判断
 */
export function judgeWhetherTransfer (obj) {
  return request({
    url: '/basic/subprojectsQuotation/selectJudgmentWhetherTransfer',
    method: 'post',
    params: obj
  })
}
/**
 * 子项目报价单 送货单金额判断
 */
export function judgeWhetherDelivery (obj) {
  return request({
    url: '/basic/subprojectsQuotation/selectJudgmentWhetherDelivery',
    method: 'post',
    params: obj
  })
}
