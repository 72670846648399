<template>
  <el-collapse v-model="activeNames">
    <el-collapse-item title="导入文件" name="1">
      <el-card shadow="hover">
        <el-upload
          class="import-demo"
          ref="upload"
          :headers="headers"
          :action="url"
          :data="importData"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          accept=".csv"
          :before-upload="beforeAvatarUpload"
          :limit="limitUpload"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :file-list="fileList">
          <el-button slot="trigger" size="mini" type="primary">选择文件</el-button>
          <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload" :loading="loading">开始导入</el-button>
          <el-button v-if="this.importType === 'kyImport'"  size="mini" type="info" @click="kyDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'lybzjImport'"  size="mini" type="info" @click="lybzjDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'tbbzjImport'"  size="mini" type="info" @click="tbbzjDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'httzImport'"  size="mini" type="info" @click="httzDownload">下载模板</el-button>
          <div slot="tip" class="el-upload__tip">只能上传csv文件</div>
        </el-upload>
      </el-card>
    </el-collapse-item>
    <el-collapse-item title="导入结果" name="2">
      <el-button style="float: right; margin-bottom: 5px;" type="warning" plain round size="mini" @click="exportResultEvent">导出数据</el-button>
      <el-table ref="resultData" :data="resultData" stripe border show-overflow-tooltip="true" height="300px">
        <el-table-column header-align="center" align="center" prop="number" label="序号" width="80"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column header-align="center" align="center" prop="status" label="状态" width="100"
                         :show-overflow-tooltip="true">
          <template slot-scope="slot">
            <el-tag type="danger" size="mini" v-if="slot.row.status === 0">失败</el-tag>
            <el-tag type="success" size="mini" v-if="slot.row.status === 1">成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="content" label="结果" width=""
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column header-align="center" align="center" prop="docNo" label="单号" width=""
                         :show-overflow-tooltip="true"></el-table-column>
      </el-table>
      <el-row style="margin-top: 20px;">
        <el-col :span="6">
          <el-tag size="mini" type="">总条数：</el-tag><span style="margin-left: 5px;">{{formData.totalSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="">导入条数：</el-tag><span style="margin-left: 5px;">{{formData.importSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="success">成功条数：</el-tag><span style="margin-left: 5px;">{{formData.sessceSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="danger">失败条数：</el-tag><span style="margin-left: 5px;">{{formData.errorSize}}</span>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>
<script>

import store from '@/store/index'
import { returnImport } from '@/api/basic/customer'
import { returnImportPrice } from '@/api/basic/logistic'
import { returnContractImport } from '@/api/tms/contractaccount'
import { returnTenderImport } from '@/api/tms/tenderbond'
import { returnPerformImport } from '@/api/tms/performancebond'
import { returnPassengerImport } from '@/api/basic/passenger'
import { validatenull } from '@/tdcommon/validate'
import { excelExportResult } from '@/tdcommon/ExcelExport'

export default {
  name: 'd2-container-import',
  data () {
    return {
      loading: false,
      fileList: [],
      limitUpload: 1,
      activeNames: ['1', '2'],
      formData: [],
      resultData: [],
      fileMsg: {},
      importData: {},
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      markone: 0,
      marktwo: 0
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    importType: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleChange (file, fileList) {
      this.fileMsg = file
      this.fileList = fileList
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.resultData = []
      this.$set(this.formData, 'totalSize', 0)
      this.$set(this.formData, 'importSize', 0)
      this.$set(this.formData, 'sessceSize', 0)
      this.$set(this.formData, 'errorSize', 0)
      this.$refs.upload.clearFiles() // 清空上传文件列表
    },
    // 下载模板
    kyDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AE%A2%E6%BA%90%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.csv'
    },
    lybzjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%B1%A5%E7%BA%A6%E4%BF%9D%E8%AF%81%E9%87%91%E5%AF%BC%E5%85%A5.csv'
    },
    tbbzjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%8A%95%E6%A0%87%E4%BF%9D%E8%AF%81%E9%87%91%E5%AF%BC%E5%85%A5.csv'
    },
    httzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%90%88%E5%90%8C%E5%8F%B0%E8%B4%A6%E5%AF%BC%E5%85%A5.csv'
    },
    // 上传
    submitUpload (importType) {
      this.loading = true
      if (!validatenull(this.fileList)) {
        this.$refs.upload.submit()
        this.timer = window.setInterval(this.alertFunc, 1000) // 循环返回值
      } else {
        this.$message.error('请选择导入文件！')
        this.loading = false
      }
    },
    handleError (response) {
      this.loading = false
      return this.$confirm('导入失败！msg：' + response.msg)
    },
    handleSuccess (response, file, fileList) {
      console.log('handleSuccess', response)
      if (response.code !== 0) {
        // this.$message.error(response.msg)
        this.loading = false
        this.fileMsg = {} // 清空this.fileMsg
        window.clearInterval(this.timer) // 结束循环
        this.$refs.upload.clearFiles() // 清空上传文件列表
        return this.$alert(response.msg)
      }
    },
    alertFunc () {
      if (this.importType === 'kyImport') {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnPassengerImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else if (this.importType === 'lybzjImport') {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnPerformImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else if (this.importType === 'tbbzjImport') {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnTenderImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else if (this.importType === 'httzImport') {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnContractImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else if (this.importType === 'importPrice') { // 如果是导入运价体系
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnImportPrice().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$emit('refreshMethod')
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$emit('refreshMethod')
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      }
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 判断导入的格式是不是xls、xlsx，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'csv'
      // const extension2 = testmsg === 'xlsx'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension) {
        this.$message.error('导入文件只支持 csv 格式！')
        return false
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return (extension || extension2) && isLt2M
      return extension
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    // 导出 结果数据
    exportResultEvent () {
      excelExportResult(this.$refs.resultData.columns, this.resultData, '导入结果')
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-import-ky/index.vue"
}
</vue-filename-injector>
