import request from '@/plugin/axios'
// 主表分页查询
export function fetchList (query) {
  return request({
    url: '/basic/basicContract/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObj (obj) {
  return request({
    url: '/basic/basicContract/insertBasicContract',
    method: 'post',
    data: obj
  })
}
// 通过ids删除主表
export function delObj (id) {
  return request({
    url: '/basic/basicContract/deleteBasicContract',
    method: 'post',
    params: id
  })
}
// 修改保存
export function putObj (obj) {
  return request({
    url: '/basic/basicContract/updateBasicContract',
    method: 'post',
    data: obj
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/basic/basicContract/getContractById',
    method: 'post',
    params: { id: id }
  })
}
/*
* 查询客户合同价明细表头*
*/
export function trHeader (id) {
  return request({
    url: '/basic/basicContract/detailHeader',
    method: 'post',
    params: id
  })
}
/*
* 查询客户合同价明细表*
*/
export function trQuote (query) {
  return request({
    url: '/basic/basicContract/getBasicContractDetailsPage',
    method: 'post',
    data: query
  })
}
/**
 * 通过ids进行 初审
 */
export function trAudit (ids) {
  return request({
    url: '/basic/basicContract/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function trUnaudit (ids) {
  return request({
    url: '/basic/basicContract/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function trExamine (ids) {
  return request({
    url: '/basic/basicContract/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function trUnexamine (ids) {
  return request({
    url: '/basic/basicContract/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 启用/停用
 */
export function priceLock (ids, type) {
  return request({
    url: '/basic/basicContract/enableStopState',
    method: 'post',
    params: { ids: ids, lockFlag: type }
  })
}
/**
 * 二级表导出*
 */
export function secondFloorExport (ids) {
  return request({
    url: '/basic/clientFreight/exportClientFreightTable',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 获取导入返回值
 */
export function getImportData (key) {
  return request({
    url: '/basic/clientFreight/revertImportClientDetails',
    method: 'post',
    params: key
  })
}
/**
 * 导入 返回结果
 */
export function returnImportPriceCus () {
  return request({
    url: '/basic/project/price/progress',
    method: 'get'
  })
}
// 预订单、托运单 自动计费 调用运价
// export function tmsydGetPrice (obj) {
//   return request({
//     url: '/basic/project/price/tmsyd',
//     method: 'post',
//     data: obj
//   })
// }
export function tmsydGetPrice (obj) {
  return request({
    url: '/basic/clientFreight/selectTmsYd',
    method: 'post',
    data: obj
  })
}
/**
 * 获取导入模板
 */
export function downloadTemplate (obj) {
  return request({
    url: '/basic/clientFreight/exportTemplate',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 关联报价损益查询接口
 */
export function searchRelation (query) {
  return request({
    url: '/tms/tmsquotation/relationPage  ',
    method: 'post',
    data: query
  })
}
/**
 * 根据报价损益主键id拆解为客户合同明细
 */
export function changeRelation (id) {
  return request({
    url: '/tms/tmsquotation/generateCustomerPrice',
    method: 'get',
    params: { id: id }
  })
}
/**
 * 下载客户合同价表
 */
export function exportFreightData (obj) {
  return request({
    url: '/basic/basicContract/exportContractOne',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
