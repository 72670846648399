<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             collapse-tags
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :loading="loading"
             :multiple="multiple">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.label"
      :value="item.value"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { fetchTree } from '@/api/admin/dept'
import { downValues } from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-dept-moreselect',
  data () {
    return {
      values: [],
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      fetchTree().then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = downValues(response.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      // console.log('values-val', val)
      // console.log('values-oldVal', oldVal)
      if (val !== oldVal) {
        this.$emit('input', val)
      }
    },
    value: function (val, oldVal) {
      // console.log('value-val', val)
      // console.log('value-oldVal', oldVal)
      if (!validatenull(val)) {
        this.values = val
        if (validatenull(this.values)) {
          this.$emit('change', [])
        }
      }
      // if (validatenull(oldVal)) {
      //   this.values = validatenull(val) ? [] : val
      //   if (validatenull(this.values)) {
      //     this.$emit('change', [])
      //   }
      // }
    }
  },
  mounted () {
    this.remoteMethod()
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-dept-moreselect/index.vue"
}
</vue-filename-injector>
