/**
 * 系统取得界面整体信息
 */
import request from '@/plugin/axios'

/**
 * 取得界面整体信息
 * @param obj 模块前缘标识
 * @returns {AxiosPromise}
 */
export function getModularUser (obj) {
  return request({
    url: '/admin/common/modular/' + obj + '_modular',
    method: 'get'
  })
}

/**
 * 取得搜索界面信息
 * @param obj 模块前缘标识
 * @returns {AxiosPromise}
 */
export function getSearchUser (obj) {
  return request({
    url: '/admin/common/search/' + obj + '_modular',
    method: 'get'
  })
}

/**
 * 取得表格头部界面信息
 * @param obj 模块前缘标识
 * @returns {AxiosPromise}
 */
export function getTableHearhUser (obj) {
  return request({
    url: '/admin/common/tablehear/' + obj + '_modular',
    method: 'get'
  })
}

/**
 * 取得表格界面信息
 * @param obj 模块前缘标识
 * @returns {AxiosPromise}
 */
export function getTableUser (obj) {
  return request({
    url: '/admin/common/table/' + obj + '_modular',
    method: 'get'
  })
}

/**
 * 取得编辑界面信息
 * @param obj 模块前缘标识
 * @returns {AxiosPromise}
 */
export function getEditUser (obj) {
  return request({
    url: '/admin/common/edit/' + obj + '_modular',
    method: 'get'
  })
}

/**
 * 动态url取数据
 * @param dyUrl
 * @param param
 * @returns {never}
 */
export function dynamic (dyUrl, search) {
  return request({
    url: dyUrl,
    method: 'get',
    params: Object.assign({ search: search === undefined ? '' : search })
  })
}

/**
 * 公用唯一请求函数
 * @param url
 * @param values
 * @returns {AxiosPromise}
 */
export function validateUrl (url, values) {
  return request({
    url: url + '/' + values,
    method: 'get'
  })
}

export function addSearchUser (obj) {
  return request({
    url: '/admin/common/search/user/add',
    method: 'post',
    data: obj
  })
}

export function addTableUser (obj) {
  return request({
    url: '/admin/common/table/user/add',
    method: 'post',
    data: obj
  })
}

export function getDictUrl (url) {
  return request({
    url: url,
    method: 'get'
  })
}

export function getUrlSearch (url, obj) {
  return request({
    url: url,
    method: 'get',
    params: obj
  })
}
