import request from '@/plugin/axios'

/**
 * 获取数据
 */
export function fetchList (query) {
  return request({
    url: '/tms/tmsGoods/queryAllData',
    method: 'post',
    data: query
  })
}
export function addObj (obj) {
  return request({
    // url: '/tms/tmsGoods/insertTmsGoods',
    url: '/tms/tmsGoods/revisionTmsGoods',
    method: 'post',
    data: obj
  })
}

export function delObjs (ids) {
  return request({
    // url: '/tms/tmsGoods/batchDelete/' + id,
    // method: 'delete'
    url: 'tms/tmsGoods/revisionDelete',
    method: 'post',
    params: ids
  })
}

export function putObj (obj) {
  return request({
    // url: '/tms/tmsGoods/updateTmsGoods',
    url: 'tms/tmsGoods/revisionUpdateTmsGoods',
    method: 'post',
    data: obj
  })
}
export function batchSetSubject (obj) {
  return request({
    url: '/tms/tmsGoods/setAccountInformation',
    method: 'post',
    params: obj
  })
}
export function getAccountName () {
  return request({
    url: 'bms/accdtitems/selectAccountName',
    method: 'get'
  })
}
/*
*物品类别 判断重复 *
*/
export function getRepeatDetails (obj) {
  return request({
    url: '/tms/tmsGoods/queryRepeatData/' + obj,
    method: 'get'
  })
}
/*
*物品名称 判断重复 *
*/
export function getRepeatMetering (obj) {
  return request({
    url: '/tms/tmsGoods/queryRepeatItemCategory',
    method: 'get',
    params: obj
  })
}
/**
 * 物品名称中获取计量组的数据
 */
export function getMeasureData (query) {
  return request({
    url: '/tms/tmsItemSetup/queryGoodsMeteringData',
    method: 'get',
    data: query
  })
}
/**
 * 其他模块 获取物品名称
 */
export function getItemsName () {
  return request({
    url: '/tms/tmsGoods/queryItemNameData',
    method: 'get'
  })
}
/**
 * 会计科目 获取树形数据
 */
export function fetchTree (query) {
  return request({
    url: '/tms/accountItems/accountItemsData',
    method: 'post',
    data: query
  })
}
