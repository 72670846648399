

















































































































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-line-trend/index.vue"
}
