<template>
    <div id="myTrendBar" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-line-trend',
  data () {
    return {
      xAxisData: [],
      seriesTotalNum1: [],
      seriesTotalNum2: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
  },
  methods: {
    initChart (seriesname1, seriesname2, xAxisData, seriesTotalNum1, seriesTotalNum2) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入柱状图组件
      require('echarts/lib/chart/bar')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上一次值
      this.xAxisData = []
      this.seriesTotalNum1 = []
      this.seriesTotalNum2 = []
      // 赋值
      this.xAxisData = xAxisData
      this.seriesTotalNum1 = seriesTotalNum1
      this.seriesTotalNum2 = seriesTotalNum2

      let myChart = echarts.init(document.getElementById('myTrendBar'))
      var colors = ['rgba(84, 112, 198, 1)', 'rgba(145, 204, 117, 1)']
      var options = {
        color: colors,
        // backgroundColor: '#2c343c',
        // textStyle: {
        //   color: 'rgba(255, 255, 255, 1)'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: [seriesname1, seriesname2]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '1%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          // boundaryGap: false,
          axisPointer: {
            type: 'shadow'
          },
          data: this.xAxisData
        },
        xAxis: {
          type: 'value',
          // y轴刻度线
          axisLine: {
            show: false
          },
          // 是否显示坐标轴刻度
          axisTick: {
            show: false
          },
          // 去掉网格线
          splitLine: {
            show: true
          }
        },
        series: [{
          name: seriesname1,
          type: 'bar',
          barGap: '10%',
          itemStyle: {
            // 4个参数用于配置渐变色的起止位置，这4个参数依次对应右/下/左/上四个方位，而0,0,0，1则代表渐变色从正上方开始
            color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
              { offset: 0, color: '#FFF1AD' },
              { offset: 0.5, color: '#dbcf96' },
              { offset: 1, color: '#b7ad7d' }
            ])
          },
          data: this.seriesTotalNum1
        },
        {
          name: seriesname2,
          type: 'bar',
          barGap: '10%',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ])
          },
          data: this.seriesTotalNum2
        }]
      }
      myChart.setOption(options, true)
      // 浏览器监听屏幕大小变化，执行echarts中的chart.resize()方法。只要屏幕尺寸发生改变，echarts实例会紧跟着同比例放大缩小
      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-bar-trend/index.vue"
}
</vue-filename-injector>
