import { validatenull } from './validate'
import request from '@/plugin/axios'
import * as CryptoJS from 'crypto-js'
// import { frameInRoutes } from '@/router/routes'
import layoutHeaderAside from '@/layout/header-aside'
// import store from '@/store'
export const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

// 表单序列化
export const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}

export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}

export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/**
 *  <img> <a> src 处理
 * @returns {PromiseLike<T | never> | Promise<T | never>}
 */
export function handleImg (fileName, id) {
  return validatenull(fileName) ? null : request({
    url: '/admin/sys-file/' + fileName,
    method: 'get',
    responseType: 'blob'
  }).then((response) => { // 处理返回的文件流
    const blob = response
    const img = document.getElementById(id)
    img.src = URL.createObjectURL(blob)
    window.setTimeout(function () {
      window.URL.revokeObjectURL(blob)
    }, 0)
  })
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 菜单处理
 * @param data
 * @returns {*}
 */
export const getMenuD2 = data => {
  const menuList = getChildren(data)
  return menuList
}
/**
 * 菜单模型处理
 * @param data
 * @returns {[]}
 */
export const getChildren = data => {
  const menuList = []
  data.forEach(ele => {
    const menuMap = {}
    if (isURL(ele.path)) {
      menuMap['path'] = ele['path']
      menuMap['fullPath'] = ele['path']
    } else {
      menuMap['path'] = ele['path']
      menuMap['fullPath'] = ele['path']
    }
    menuMap['title'] = ele['label']
    menuMap['icon'] = ele['icon']
    menuMap['name'] = ele['name']
    menuMap['spread'] = ele['spread']
    menuMap['aliveName'] = ele['aliveName']
    menuMap['keepAlive'] = ele['keepAlive']
    menuMap['code'] = ele['code']
    menuMap['type'] = ele['type']
    menuMap['sort'] = ele['sort']
    menuMap['parentId'] = ele['parentId']
    if (ele['children'].length > 0) {
      menuMap['children'] = getChildren(ele['children'])
    }
    menuList.push(menuMap)
  })
  return menuList
}

export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

export function path2name (path = '') {
  return path.split('/').filter(e => e).join('-')
}

export const getMenuToRoutes = menuList => {
  let menus = MenuToRoutes(menuList, true)
  return menus
}

export const MenuToRoutes = (con, first) => {
  let routerMap = []
  if (con.length === 0) return []
  con.forEach((item) => {
    if (Array.isArray(item.children)) {
      if (item.parentId === -1) {
        let ass = MenuToRoutes(item.children, false)
        const router = {
          path: item.path,
          redirect: { name: 'index' },
          component: layoutHeaderAside,
          children: ass
        }
        routerMap.push(router)
      } else {
        routerMap = routerMap.concat(MenuToRoutes(item.children, false))
      }
    } else {
      if (isURL(item.path)) {
        const router = {
          path: item.path,
          name: validatenull(item.aliveName) ? item.name : item.aliveName,
          meta: {
            auth: true,
            cache: item.keepAlive === '0',
            title: item.name
          },
          component: _import('intviews/index')
        }
        routerMap.push(router)
      } else {
        const router = {
          path: item.path,
          name: validatenull(item.aliveName) ? item.name : item.aliveName,
          meta: {
            auth: true,
            title: item.name,
            cache: item.keepAlive === '0'
          },
          component: _import(`${item.path.slice(1)}`)
        }
        routerMap.push(router)
      }
    }
  })
  return routerMap
}

/**
 * 下拉列表处理成list
 * @param urlDownValues
 */
export function downValues (downValues) {
  let list = []
  list = treeToList([], downValues)
  return list
}

function treeToList (list, dataSource) {
  dataSource.forEach((item) => {
    // debugger
    let items = {}
    items.label = item.name
    items.username = item.username
    items.value = item.id
    list.push(items)
    if (item.children.length === 0) {
    } else {
      treeToList(list, item.children)
    }
  })
  // debugger
  return list
}
// 下载文件
export function handleDown (filename, bucket) {
  return request({
    url: '/admin/sys-file/' + bucket + '-' + filename,
    method: 'get',
    responseType: 'blob'
  }).then((response) => { // 处理返回的文件流
    const blob = response
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function () {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

/**
 * 搜索处理
 * @param typeMark Boolean自定义处理
 * @param fromSearch 处理对象集合
 * @returns {null}
 */
export function searchSql (typeMark, fromSearch, username, searchList) {
  if (JSON.stringify(fromSearch) === '{}') {
    return fromSearch
  }
  let fromSearchMark = {}
  if (typeMark) {
  } else {
    if (fromSearch.operator !== undefined) {
      switch (fromSearch.operator) {
        case 1:
          fromSearchMark.operator = username
          break
        case 2:
          fromSearchMark.operator = fromSearch.subordinate.toString()
          break
      }
    }
    if (fromSearch.createTime !== undefined) {
      fromSearchMark.createTime = fromSearch.createTime.toString()
    }
    if (fromSearch.searchLike !== undefined && fromSearch.searchMark !== undefined) {
      fromSearchMark[fromSearch.searchLike] = fromSearch.searchMark
    }
  }
  return fromSearchMark
}
/**
 * 计算两个日期天数差的函数，通用
 * @returns {null}
 */
export function dateDiff (sDate1, sDate2) {
  if (!validatenull(sDate1) && !validatenull(sDate2)) {
    var aDate, oDate1, oDate2, iDays
    aDate = sDate1.split('-')
    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]) // 转换为yyyy-MM-dd格式
    aDate = sDate2.split('-')
    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
    iDays = parseInt((oDate1 - oDate2) / 1000 / 60 / 60 / 24) // 把相差的毫秒数转换为天数
    return iDays // 返回相差天数
  } else {
    return ''
  }
}
/**
 * 批量删除id处理
 * @param 处理对象集合
 * @returns {null}
 */
export function getListId (selectList) {
  let ids = []
  selectList.forEach(item => {
    ids.push(item.id)
  })
  let idsMap = {}
  idsMap.ids = ids.toString()
  return idsMap
}
/**
 * 获取ids
 * @param
 * @returns {null}
 */
export function getIds (selectList) {
  let ids = []
  selectList.forEach(item => {
    ids.push(item.id)
  })
  return ids
}
/**
 * 获取ids
 * @param
 * @returns {null}
 */
export function getStringIds (selectList) {
  let ids = []
  selectList.forEach(item => {
    ids.push(item.id)
  })
  return ids.toString()
}
/**
 * 跳转入新的新增或修改界面
  */
export function onRouteUrl ({ router, name, id, state }) {
  router.push({
    name,
    query: {
      id: id,
      state: state
    }
  })
}

export function onRouteUrl2 ({ router, name, id, read, state }) {
  router.push({
    name,
    meta: {
      auth: true,
      cache: false
    },
    query: {
      id,
      read,
      state
    }
  })
}
export function onRouteUrlParam ({ router, name, id, read, state, param }) {
  router.push({
    name,
    meta: {
      auth: true,
      cache: false
    },
    query: {
      id,
      read,
      state
    },
    params: param
  })
}

/**
 * 跳转入新增修改的URL界面参数
 */
export function onRouteParamURL ({ router, name, id, state, param }) {
  router.push({
    name,
    query: {
      id: id,
      state: state
    },
    params: param
  })
}
/**
 * 批量处理数组转String
 */
export function getArrayToString (formMap) {
  for (var item in formMap) {
    if (formMap[item] !== null && formMap[item] !== undefined && typeof formMap[item] === 'object') {
      formMap[item] = formMap[item].toString()
    }
  }
  return formMap
}
/**
 * 批量处理数组转Tree
 */
export function getArrayToTree (list = [], listRight = []) {
  const data = JSON.parse(JSON.stringify(list)) // 浅拷贝不改变源数据
  const result = []
  if (!Array.isArray(data)) {
    return result
  }
  const parent = {}
  let temp = []
  let tempData = []
  for (let i = 0; i < listRight.length; i++) {
    if (listRight[i].ymeaOutNum === listRight[i].unOutNum) {
      temp.push(listRight[i].id)
    }
  }
  data.forEach((item, index) => {
    item.indexMark = index
    if (temp.indexOf(item.id) < 0) {
      tempData.push(item)
    }
  })
  tempData.forEach(item => {
    if (!parent[item.ddId]) {
      parent[item.ddId] = item.mayDdOutVO
      parent[item.ddId]['pricingNum'] = 0
      parent[item.ddId]['outNum'] = 0
      parent[item.ddId]['unOutNum'] = 0
      parent[item.ddId]['ymeaOutNum'] = 0
      result.push(parent[item.ddId])
    }
  })
  tempData.forEach((item, index) => {
    delete item.mayDdOutVO
    if (parent[item.ddId]) {
      (parent[item.ddId].children || (parent[item.ddId].children = [])).push(item)
      parent[item.ddId].pricingNum = Math.floor(parent[item.ddId].pricingNum * 100 + item.pricingNum * 100) / 100
      parent[item.ddId].outNum = Math.floor(parent[item.ddId].outNum * 100 + item.outNum * 100) / 100
      parent[item.ddId].unOutNum = Math.floor(parent[item.ddId].unOutNum * 100 + item.unOutNum * 100) / 100
      parent[item.ddId].ymeaOutNum = Math.floor(parent[item.ddId].ymeaOutNum * 100 + item.ymeaOutNum * 100) / 100
    }
  })
  return result
}

/**
 * Tree转List
 * @param data 数据源
 * @param pid 根目录
 * @returns {*}
 * @constructor
 */
export function ListData (data, pid) {
  return data.reduce((arr, { citycode, adcode, name, center, level, districts = [] }) =>
    arr.concat([{ citycode, adcode, name, center, level }], ListData(districts, citycode)), [])
}
/**
 * 登陆获取法人公司
 */
export function companyLegal (query) {
  return request({
    url: '/admin/syscompanylegal/list',
    method: 'get'
  })
}

/**
 * 判断List中指定属性是否唯一
 * @param obj list对象
 * @param markObj 对比字段
 * @param saleValues 要进行对比值
 * @returns {boolean}
 */
export function sameInformation (obj, markObj, saleValues) {
  if (validatenull(obj)) {
    return true
  }
  let oneObj = obj[0][markObj]
  if (!validatenull(saleValues)) {
    oneObj = saleValues
  }
  var status = true
  obj.forEach(item => {
    if (validatenull(item[markObj])) {
      status = false
    } else if (item[markObj] !== oneObj) {
      status = false
    }
  })
  return status
}

export function sortFieldMatch (val) {
  const stringArray = val.split('')
  let newField = val
  stringArray.forEach(t => {
    if (/[A-Z]/.test(t)) {
      // 遍历分割之后的字符串组，将找到的大写字母替换成其他字符串，将替换后的字符串赋值给另外一个新的string 变量
      newField = newField.replace(t, `_${t.toLowerCase()}`)
    }
  })
  return newField
}

export function objSum (array, param) {
  return array.reduce(function (total, currentValue, currentIndex, arr) {
    if (validatenull(currentValue[param])) {
      return total + 0
    } else {
      return total + currentValue[param]
    }
  }, 0)
}
// 求和四舍五入取2位小数（不补0）
export function objSumDecimal (array, param) {
  return array.reduce(function (total, currentValue, currentIndex, arr) {
    if (validatenull(currentValue[param])) {
      return Math.round((total + 0) * 100) / 100
    } else {
      return Math.round((total + currentValue[param]) * 100) / 100
    }
  }, 0)
}
// 求和四舍五入取3位小数（不补0）
export function objSumThreeDecimal (array, param) {
  return array.reduce(function (total, currentValue, currentIndex, arr) {
    if (validatenull(currentValue[param])) {
      return Math.round((total + 0) * 1000) / 1000
    } else {
      return Math.round((total + currentValue[param]) * 1000) / 1000
    }
  }, 0)
}
export function sumAdd () {
  var arr = 0
  for (var i = 0; i < arguments.length; i++) {
    if (validatenull(arguments[i])) continue
    arr += parseFloat(arguments[i])
  }
  return arr
}
// 高拍仪开启/关闭方法
export function takePicture (videoId, canvasId, type, _this) {
  const video = document.getElementById(videoId)
  const constraints = {
    video: { width: 3742, height: 2806 },
    audio: true
  }
  /*
  这里介绍新的方法:H5新媒体接口 navigator.mediaDevices.getUserMedia()
  这个方法会提示用户是否允许媒体输入,(媒体输入主要包括相机,视频采集设备,屏幕共享服务,麦克风,A/D转换器等)
  返回的是一个Promise对象。
  如果用户同意使用权限,则会将 MediaStream对象作为resolve()的参数传给then()
  如果用户拒绝使用权限,或者请求的媒体资源不可用,则会将 PermissionDeniedError作为reject()的参数传给catch()
  */
  if (type === 'open') {
    let promise
    if (navigator.mediaDevices.getUserMedia) {
      // 最新的标准API
      promise = navigator.mediaDevices.getUserMedia(constraints)
    } else if (navigator.webkitGetUserMedia) {
      // webkit核心浏览器
      navigator.webkitGetUserMedia(constraints)
    } else if (navigator.mozGetUserMedia) {
      // firfox浏览器
      navigator.mozGetUserMedia(constraints)
    } else if (navigator.getUserMedia) {
      // 旧版API
      navigator.getUserMedia(constraints)
    }
    promise.then(function (MediaStream) {
      video.srcObject = MediaStream
      video.play()
    }).catch(function (PermissionDeniedError) {
      if (PermissionDeniedError.name === 'NotFoundError') {
        _this.$message.error('请确认相关设备已连接电脑！')
      } else {
        _this.$message.error('请检查相关设备是否可以正常工作！')
      }
      return false
    })
    return true
  } else if (type === 'close') {
    if (!video.srcObject) return false
    let stream = video.srcObject
    let tracks = stream.getTracks()
    tracks.forEach(track => {
      track.stop()
    })
    return true
  } else if (type === 'take') {
    let canvas = document.getElementById(canvasId)
    let ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0, 3742, 2806)
    return true
  }
}
export function dtaURItoBlob (base64Data) {
  var byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) { byteString = atob(base64Data.split(',')[1]) } else { byteString = unescape(base64Data.split(',')[1]) }
  var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

/**
 * 解决两个数相加精度丢失问题
 * @param a
 * @param b
 * @returns {Number}
 */
export function floatAdd (a, b) {
  var c, d, e
  if (validatenull(a)) { a = 0 }
  if (validatenull(b)) { b = 0 }
  try {
    c = a.toString().split('.')[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) {
    d = 0
  }
  e = Math.pow(10, Math.max(c, d))
  return (floatMul(a, e) + floatMul(b, e)) / e
}
/**
 * 解决两个数相减精度丢失问题
 * @param a
 * @param b
 * @returns {Number}
 */
export function floatSub (a, b) {
  var c, d, e
  if (validatenull(a)) { a = 0 }
  if (validatenull(b)) { b = 0 }
  try {
    c = a.toString().split('.')[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) {
    d = 0
  }
  e = Math.pow(10, Math.max(c, d))
  return (floatMul(a, e) - floatMul(b, e)) / e
}
/**
 * 解决两个数相乘精度丢失问题
 * @param a
 * @param b
 * @returns {Number}
 */
export function floatMul (a, b) {
  var c = 0
  var d = a.toString()
  var e = b.toString()
  try {
    c += d.split('.')[1].length
  } catch (f) {}
  try {
    c += e.split('.')[1].length
  } catch (f) {}
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}
/**
 * 解决两个数相除精度丢失问题
 * @param a
 * @param b
 * @returns
 */
export function floatDiv (arg1, arg2) {
  var t1 = 0
  var t2 = 0
  var r1
  var r2
  try { t1 = arg1.toString().split('.')[1].length } catch (e) {}
  try { t2 = arg2.toString().split('.')[1].length } catch (e) {}
  r1 = Number(arg1.toString().replace('.', ''))

  r2 = Number(arg2.toString().replace('.', ''))

  return (r1 / r2) * Math.pow(10, t2 - t1)
}

export function moneyToNumFiled (obj) {
  if (obj.length === 0 || validatenull(obj)) {
    return 0
  }
  return Number(obj.toString().replaceAll(',', ''))
}
/**
 * 数组求和
 */
export function sumArray (arr) {
  var s = 0
  for (var i = arr.length - 1; i >= 0; i--) {
    s += arr[i]
  }
  return s
}
