import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/exportsurface/page',
    method: 'post',
    data: query
  })
}
/**
 * 通过id删除
 */
export function delObj (ids) {
  return request({
    url: '/tms/exportsurface/dels',
    method: 'post',
    params: ids
  })
}
