<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             placeholder="请选择角色"
             :clearable="clearable"
             :disabled="disabled"
             :multiple="multiple"
             :collapse-tags="collapseTags"
             @clear="clearAction"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.roleName"
      :value="item.roleId">
      <span style="float: left;"><i class="fa fa-modx" :style="{'color': '#66b1ff','margin-right': '5px'}"></i>{{ item.roleName }}</span>
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { fetchRole } from '@/api/basic/appletRole'

export default {
  name: 'd2-container-role-dispatch',
  data () {
    return {
      values: [],
      tableOptions: [],
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => true
    },
    collapseTags: {
      type: Boolean,
      default: () => true
    },
    url: {
      type: String,
      default: () => ''
    },
    mdmstr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    findData (val) {
      this.loading = true
      let searchStr = validatenull(val) ? '' : val
      fetchRole(Object.assign({
        roleName: searchStr
      })).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // handleSelect (val) {
    //   this.$emit('change', val)
    // },
    clearAction () {
      // this.$emit('change', '')
    },
    getStartValues (val) {
      if (!validatenull(this.mdmstr)) {
        let model = this.$parent.elForm.model[this.mdmstr]
        if (!validatenull(model)) {
          this.values = model
        } else {
          this.values = []
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      // this.values = validatenull(val) ? [] : val
      // this.$emit('change', this.values)
      if (!validatenull(val)) {
        this.values = val
        this.$emit('change', val)
      } else {
        this.values = []
        this.$emit('change', [])
      }
    }
  },
  mounted () {
    this.findData()
    this.getStartValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-role-dispatch/index.vue"
}
</vue-filename-injector>
