<template>
  <el-table
    class="el-table_firs"
    v-if="tableShow"
    ref="el_td_table"
    :data="Data"
    v-loading="loading"
    :element-loading-text="loading_text"
    :height="table_height"
    :max-height="max_height"
    :stripe="stripe"
    :border="border"
    :size="size"
    :fit="fit"
    :show-header="show_header"
    :highlight-current-row="highlight_current_row"
    :current-row-key = "current_row_key"
    :row-class-name="row_class_name"
    :row-style="row_style"
    :cell-class-name="cell_class_name"
    :cell-style="cell_style"
    :header-row-class-name="header_row_class_name"
    :header-row-style="header_row_style"
    :header-cell-class-name="header_cell_class_name"
    :header-cell-style="header_cell_style"
    :row-key="row_key"
    :empty-text="empty_text"
    :default-expand-all="default_expand_all"
    :expand-row-keys="expand_row_keys"
    :default-sort="default_sort"
    :tooltip-effect="tooltip_effect"
    :show-summary="show_summary"
    :sum-text="sum_text"
    :summary-method="getSummaries"
    :span-method="span_method"
    :select-on-indeterminate="select_on_indeterminate"
    :indent="indent"
    :lazy="lazy"
    :tree-props="tree_props"
    @select="select"
    @select-all="select_all"
    @selection-change="selection_change"
    @cell-mouse-enter="cell_mouse_enter"
    @cell-mouse-leave="cell_mouse_leave"
    @cell-click="cell_click"
    @cell-dblclick="cell_dblclick"
    @row-click="row_click"
    @row-contextmenu="row_contextmenu"
    @row-dblclick="row_dblclick"
    @header-click="header_click"
    @header-contextmenu="header_contextmenu"
    @sort-change="sort_change"
    @filter-change="filter_change"
    @current-change="current_change"
    @header-dragend="header_dragend"
    @expand-change="expand_change"
  >
    <!-- 打勾选择 -->
    <template v-if="isSelection">
      <el-table-column
        type="selection"
        width="55px" align="center" fixed="left">
      </el-table-column>
    </template>
    <!-- 树型可扩展按钮 -->
    <template v-if="isExpand">
      <el-table-column type="expand" :width="55" align="center" fixed="left">
        <template slot-scope="scope">
          <slot name="expand_slot" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </template>
    <!-- 序号 -->
    <template v-if="isIndex">
      <el-table-column
        type="index"
        label="序号"
        width="52px"
        :index="indexMethod"
        min-width="50px" align="center" fixed="left">
      </el-table-column>
    </template>
    <template v-for="(item,index) in mtableColumns">
      <el-table-column header-align="center" v-if="item.isShowTable === '0'" :key="index"
                       :prop="item.prop === undefined || item.prop.length === 0 ? '' : item.prop"
                       :label="item.title === undefined || item.title.length === 0 ? '' : item.title"
                       :show-overflow-tooltip="item.tableIsSlot !== '0'"
                       :min-width="item.minwidth === undefined || item.minwidth.length === 0 ? '120' : item.minwidth"
                       :fixed="item.tableFixed === undefined ? false : item.tableFixed === '' ? false : item.tableFixed === 'left' ? true : 'right'"
                       :sortable="item.tableSort === '0' ? true : false"
                       :type="item.tableType"
                       :width="item.width === undefined || item.width.length === 0 ? '0' : item.width">
        <template slot-scope="scope">
          <template v-if="!item.formatter && item.tableType !== undefined && item.tableType === 'tag' && item.tableIsSlot !== '0' && tagMark[item.prop + String(scope.row[item.prop])] !== undefined">
            <el-tag :type="tagMark[item.prop + String(scope.row[item.prop])].type" :effect="tagMark[item.prop + String(scope.row[item.prop])].effect"
                    :size="tagMark[item.prop + String(scope.row[item.prop])].size" :color="tagMark[item.prop + String(scope.row[item.prop])].color"
                    :style="tagMark[item.prop + String(scope.row[item.prop])].style" disable-transitions>{{tagMark[item.prop + String(scope.row[item.prop])].label}}</el-tag>
          </template>
          <slot v-else-if="!item.formatter && item.tableIsSlot === '0'" :name="item.prop" :row="scope.row" :dicData="item.DataDis"></slot>
          <span v-else-if="item.formatter" v-html="item.formatter(scope.row,scope.column, scope.row[item.prop], scope.$index)"></span>
          <template v-else-if="!item.formatter && item.tableType !== undefined && item.tableType === 'openDetails' && item.tableIsSlot !== '0'">
            <el-link type="primary" :underline="false" style="font-size:13px; padding: 0" @click="openDetails(scope.row)">{{scope.row[item.prop]}}</el-link>
          </template>
          <template v-else-if="!item.formatter && item.tableType !== undefined && item.tableType === 'openCompute' && item.tableIsSlot !== '0'">
            <el-input-number :controls="false" size="mini" style="font-size:13px; padding: 0" @change="(val) => openCompute(val, scope.row)" v-model="scope.row[item.prop]"></el-input-number>
          </template>
          <template v-else-if="!item.formatter && item.tableType !== undefined && item.tableType === 'date' && item.tableIsSlot !== '0'">
            <span>{{formDate('YYYY-MM-DD',scope.row[item.prop])}}</span>
          </template>
          <template v-else><span>{{scope.row[item.prop]}}</span></template>
        </template>
      </el-table-column>
    </template>
    <!--无数据时，提示样式-->
    <template slot="empty">
      <div style="display: flex; flex-direction: column; align-items: center; font-size: 16px;" :style="empty">
        <img src="./empty.png" alt="">
        <span :style="empty === '' ? `` : `margin-bottom: 100px`">{{empty_text}}</span>
      </div>
    </template>
  </el-table>
</template>

<script>
import dayjs from 'dayjs'
import { getTableUser, getDictUrl } from '@/api/sys.ui'
import { getdict } from '@api/admin/dict'
import { validatenull } from '@/tdcommon/validate'
import { getDecodeStore, setEncodeStore } from '@/tdcommon/store'

export default {
  name: 'd2-container-table',
  props: {
    ModularName: {
      type: String,
      default: () => ''
    },
    page_count: {
      type: Number,
      default: () => 0
    },
    page_size: {
      type: Number,
      default: () => 0
    },
    page_amount: {
      type: Object,
      default: () => {}
    },
    // 启动自定序号
    indexMark: {
      type: Boolean,
      default: () => false
    },
    // 列名信息数据
    tableColumns: {
      type: Array,
      default: () => []
    },
    // 表格显示数据
    Data: {
      type: Array,
      default: () => []
    },
    // 启动加载动画
    loading: {
      type: Boolean,
      default: () => false
    },
    // 打勾选择
    isSelection: {
      type: Boolean,
      default: () => false
    },
    // 序号显示
    isIndex: {
      type: Boolean,
      default: () => false
    },
    // 展开扩展按钮
    isExpand: {
      type: Boolean,
      default: () => false
    },
    // 加载文字信息
    loading_text: {
      type: String,
      default: () => '正在加载数据，请稍等.....'
    },
    // 带斑马纹的表格
    stripe: {
      type: Boolean,
      default: () => true
    },
    // 边框表格
    border: {
      type: Boolean,
      default: () => true
    },
    // 表格高度
    table_height: {
      type: [String, Number],
      default: () => '100%'
    },
    // 表格最大高度
    max_height: {
      type: [String, Number]
    },
    // 样式大小
    size: {
      type: String,
      default: () => 'mini'
    },
    // 列的宽度是否自撑开
    fit: {
      type: Boolean,
      default: () => true
    },
    // 是否显示表头
    show_header: {
      type: Boolean,
      default: () => true
    },
    // 是否要高亮当前行
    highlight_current_row: {
      type: Boolean,
      default: () => false
    },
    // 当前行的 key，只写属性
    current_row_key: {
      type: [String, Number]
    },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className
    row_class_name: {
      type: [Function, String]
    },
    // 行的 style 的回调方法，也可以使用一个固定的 Object 为所有行设置一样的 Style。
    row_style: {
      type: [Function, Object]
    },
    // 单元格的 className 的回调方法，也可以使用字符串为所有单元格设置一个固定的 className。
    cell_class_name: {
      type: [Function, String],
      default: () => 'td_cell_style'
    },
    // 单元格的 style 的回调方法，也可以使用一个固定的 Object 为所有单元格设置一样的 Style。
    cell_style: {
      type: [Function, Object]
    },
    // 表头行的 className 的回调方法，也可以使用字符串为所有表头行设置一个固定的 className。
    header_row_class_name: {
      type: [Function, String],
      default: () => 'td_table_header_style'
    },
    // 单元格的 style 的回调方法，也可以使用一个固定的 Object 为所有单元格设置一样的 Style。
    header_row_style: {
      type: [Function, Object]
    },
    // 表头单元格的 className 的回调方法，也可以使用字符串为所有表头单元格设置一个固定的 className。
    header_cell_class_name: {
      type: [Function, String],
      default: () => 'td_cell_header_style'
    },
    // 表头单元格的 style 的回调方法，也可以使用一个固定的 Object 为所有表头单元格设置一样的 Style。
    header_cell_style: {
      type: [Function, Object]
    },
    // 行数据的 Key，用来优化 Table 的渲染；在使用 reserve-selection 功能与显示树形数据时，该属性是必填的。类型为 String 时，支持多层访问：user.info.id，但不支持 user.info[0].id，此种情况请使用 Function。
    row_key: {
      type: [Function, String]
    },
    // 空数据时显示的文本内容，也可以通过 slot="empty" 设置
    empty_text: {
      type: String,
      default: () => '太难了，看来我满足不了你的需求'
    },
    // 是否默认展开所有行，当 Table 包含展开行存在或者为树形表格时有效
    default_expand_all: {
      type: Boolean,
      default: () => false
    },
    // 可以通过该属性设置 Table 目前的展开行，需要设置 row-key 属性才能使用，该属性为展开行的 keys 数组。
    expand_row_keys: {
      type: Array
    },
    // 默认的排序列的 prop 和顺序。它的prop属性指定默认的排序的列，order指定默认排序的顺序 {prop:'date',order:'descending'}
    default_sort: {
      type: Object,
      default: () => {}
    },
    // tooltip effect 属性
    tooltip_effect: {
      type: String
    },
    // 是否在表尾显示合计行
    show_summary: {
      type: Boolean,
      default: () => true
    },
    // 合计行第一列的文本
    sum_text: {
      type: String,
      default: () => '合计'
    },
    // 合并行或列的计算方法
    span_method: {
      type: Function
    },
    // 在多选表格中，当仅有部分行被选中时，点击表头的多选框时的行为。若为 true，则选中所有行；若为 false，则取消选择所有行
    select_on_indeterminate: {
      type: Boolean,
      default: () => true
    },
    // 展示树形数据时，树节点的缩进
    indent: {
      type: Number,
      default: () => 16
    },
    // 是否懒加载子节点数据
    lazy: {
      type: Boolean
    },
    // 加载子节点数据的函数，lazy 为 true 时生效，函数第二个参数包含了节点的层级信息
    load: {
      type: Function
    },
    // 渲染嵌套数据的配置选项
    tree_props: {
      type: Object,
      default: () => {
        return { children: 'children', hasChildren: 'hasChildren' }
      }
    },
    empty: {
      type: String,
      default: 'position: fixed; left: 50%; z-index: 0'
    }
  },
  data () {
    return {
      // 因组件加载完成时，相关远程数据没有返回
      tableShow: true,
      mtableColumns: [],
      // 收录远程数据变量
      tagMark: {}
    }
  },
  created () {
    this.getTableUI()
  },
  watch: {
    Data: function () {
      this.$nextTick(() => {
        if (this.$refs.el_td_table !== undefined) {
          this.$refs.el_td_table.doLayout()
        }
      })
    }
  },
  methods: {
    /**
     * 合计
     */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.type !== 'number' && validatenull(this.page_amount)) {
          return
        }
        if (index === 1) {
          sums[index] = validatenull(this.page_amount.total) ? '' : this.page_amount.total
          return
        }
        if (!validatenull(this.page_amount)) {
          sums[index] = validatenull(this.page_amount[column.property]) ? '' : this.page_amount[column.property]
        } else {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // sums[index] = Math.floor(sums[index] * 100) / 100 + 0
            sums[index] = (Math.round(sums[index] * 100) / 100 + 0).toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    /**
     * 日期格式处理
     */
    formDate (formType, Dates) {
      return dayjs(Dates).format(formType)
    },
    // 取得查询条件界面参数
    getTableUI () {
      let tableHear = getDecodeStore({ name: 'tb' + this.ModularName })
      if (validatenull(tableHear)) {
        this.tableShow = false
        getTableUser(this.ModularName).then(response => {
          this.mtableColumns = response.data.tableColumns
          this.settingDataUrl(0)
        }).catch(() => {
          this.tableShow = true
        })
      } else {
        this.tableShow = false
        this.mtableColumns = tableHear
        this.settingDataDis()
        this.tableShow = true
      }
    },
    // 自定义序号 indexMark、page_count、page_size三个要一起配置才起效
    indexMethod (index) {
      if (this.indexMark) {
        return (this.page_count - 1) * this.page_size + index + 1
      } else {
        return index + 1
      }
    },
    /**
     * type='tag'处理方法
     */
    settingDataDis () {
      let tagMark = getDecodeStore({ name: 'tag' + this.ModularName })
      if (validatenull(tagMark)) {
        this.mtableColumns.forEach(item => {
          if (!validatenull(item.editOptions)) {
            if (typeof item.editOptions === 'string') {
              item.editOptions = JSON.parse(item.editOptions)
            }
            item.editOptions.forEach((items) => {
              if (this.tagMark[item.prop + String(items.value)] === undefined) {
                items.type = validatenull(items.type) ? '' : items.type
                items.size = validatenull(items.size) ? 'mini' : items.size
                items.effect = validatenull(items.effect) ? 'light' : items.effect
                items.color = validatenull(items.color) ? '' : items.color
                items.style = validatenull(items.color) ? '' : items.color
                this.tagMark[item.prop + String(items.value)] = items
              }
            })
          }
        })
        setEncodeStore('tag' + this.ModularName, this.tagMark)
      } else {
        this.tagMark = tagMark
      }
    },
    //  筛选组件处理
    getFiltersSearch (type, DataDis) {
      let filterList = []
      if (type === 'tag') {
        if (DataDis !== undefined) {
          DataDis.forEach((item) => {
            let filterItem = { text: item.label, value: item.value }
            filterList.push(filterItem)
          })
          return filterList
        }
      }
    },
    //  筛选动作
    filterMethod (type, value, row, column) {
      if (type === 'tag') {
        const property = column['property']
        return row[property] === value
      }
    },
    /**
     * 实例化组件时通过for进行对DataUrl远程请求数据处理方法及处理是否可以显示
     */
    settingDataUrl (index) {
      if (index === this.mtableColumns.length - 1) {
        setEncodeStore('tb' + this.ModularName, this.mtableColumns)
        this.settingDataDis()
        this.tableShow = true
        return
      }
      if (!validatenull(this.mtableColumns[index])) {
        if (!validatenull(this.mtableColumns[index].editOptions)) {
          this.mtableColumns[index].editOptions = JSON.parse(this.mtableColumns[index].editOptions)
          this.settingDataUrl(index + 1)
        } else if (!validatenull(this.mtableColumns[index].dictUrl)) {
          getDictUrl(this.mtableColumns[index].dictUrl).then((response) => {
            this.mtableColumns[index].editOptions = response.data
            this.settingDataUrl(index + 1)
          }).catch(() => {
            this.settingDataUrl(index + 1)
          })
        } else if (!validatenull(this.mtableColumns[index].dictType)) {
          getdict(this.mtableColumns[index].dictType).then(response => {
            this.mtableColumns[index].editOptions = response.data
            this.settingDataUrl(index + 1)
          }).catch(() => {
            this.settingDataUrl(index + 1)
          })
        } else {
          this.settingDataUrl(index + 1)
        }
      } else {
        this.settingDataUrl(index + 1)
      }
    },
    /**
     * 当用户手动勾选数据行的 Checkbox 时触发的事件
     */
    select (selection, row) {
      this.$emit('select', selection, row)
    },
    /**
     *当用户手动勾选全选 Checkbox 时触发的事件
     */
    select_all (selection) {
      this.$emit('select-all', selection)
    },
    /**
     * 当选择项发生变化时会触发该事件
     */
    selection_change (selection) {
      this.$emit('selection-change', selection)
    },
    /**
     * 当单元格 hover 进入时会触发该事件
     */
    cell_mouse_enter (row, column, cell, event) {
      this.$emit('cell-mouse-enter', row, column, cell, event)
    },
    /**
     * 当单元格 hover 退出时会触发该事件
     */
    cell_mouse_leave (row, column, cell, event) {
      this.$emit('cell-mouse-leave', row, column, cell, event)
    },
    /**
     * 当某个单元格被点击时会触发该事件
     */
    cell_click (row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event)
    },
    /**
     * 当某个单元格被双击击时会触发该事件
     */
    cell_dblclick (row, column, cell, event) {
      this.$emit('cell-dblclick', row, column, cell, event)
    },
    /**
     * 当某一行被点击时会触发该事件
     */
    row_click (row, column, event) {
      this.$emit('row_click', row, column, event)
    },
    /**
     * 当某一行被鼠标右键点击时会触发该事件
     */
    row_contextmenu (row, column, event) {
      this.$emit('row-contextmenu', row, column, event)
    },
    /**
     * 当某一行被双击时会触发该事件
     */
    row_dblclick (row, column, event) {
      this.$emit('row_dblclick', row, column, event)
    },
    /**
     * 当某一列的表头被点击时会触发该事件
     */
    header_click (column, event) {
      this.$emit('header-click', column, event)
    },
    /**
     * 当某一列的表头被鼠标右键点击时触发该事件
     */
    header_contextmenu (column, event) {
      this.$emit('header-contextmenu', column, event)
    },
    /**
     * 当表格的排序条件发生变化的时候会触发该事件
     */
    sort_change (ojbectItem) {
      this.$emit('sort-change', ojbectItem)
    },
    /**
     * 当表格的筛选条件发生变化的时候会触发该事件，参数的值是一个对象，对象的 key 是 column 的 columnKey，对应的 value 为用户选择的筛选条件的数组。
     */
    filter_change (filters) {
      this.$emit('filter-change', filters)
    },
    /**
     * 当表格的当前行发生变化的时候会触发该事件，如果要高亮当前行，请打开表格的 highlight-current-row 属性
     */
    current_change (currentRow, oldCurrentRow) {
      this.$emit('current-change', currentRow, oldCurrentRow)
    },
    /**
     * 当拖动表头改变了列的宽度的时候会触发该事件
     */
    header_dragend (newWidth, oldWidth, column, event) {
      this.$emit('header-dragend', newWidth, oldWidth, column, event)
    },
    /**
     *当用户对某一行展开或者关闭的时候会触发该事件（展开行时，回调的第二个参数为 expandedRows；树形表格时第二参数为 expanded）
     */
    expand_change (row, expanded) {
      this.$emit('expand-change', row, expanded)
    },
    /**
     * 清空用户的选择数据
     */
    clearSelection () {
      this.$refs.el_td_table.clearSelection()
    },
    /**
     * 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
     */
    toggleRowSelection (row, selected) {
      this.$refs.el_td_table.toggleRowSelection(row, selected)
    },
    /**
     * 用于多选表格，切换所有行的选中状态
     */
    toggleAllSelection () {
      this.$refs.el_td_table.toggleAllSelection()
    },
    /**
     * 用于可展开表格与树形表格，切换某一行的展开状态，如果使用了第二个参数，则是设置这一行展开与否（expanded 为 true 则展开）
     */
    toggleRowExpansion (row, expanded) {
      this.$refs.el_td_table.toggleRowExpansion(row, expanded)
    },
    /**
     * 用于单选表格，设定某一行为选中行，如果调用时不加参数，则会取消目前高亮行的选中状态。
     */
    setCurrentRow (row) {
      this.$refs.el_td_table.setCurrentRow(row)
    },
    /**
     * 用于清空排序条件，数据会恢复成未排序的状态
     */
    clearSort () {
      this.$refs.el_td_table.clearSort()
    },
    /**
     * 不传入参数时用于清空所有过滤条件，数据会恢复成未过滤的状态，也可传入由columnKey组成的数组以清除指定列的过滤条件
     */
    clearFilter () {
      this.$refs.el_td_table.clearFilter()
    },
    /**
     * 对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
     */
    doLayout () {
      this.$refs.el_td_table.doLayout()
    },
    /**
     * 手动对 Table 进行排序。参数prop属性指定排序列，order指定排序顺序。
     */
    sort (props, orders) {
      this.$refs.el_td_table.sort({ prop: props, order: orders })
    },
    openDetails (row) {
      this.$emit('openDetails', row)
    },
    openCompute (val, row) {
      this.$emit('openCompute', val, row)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .el-table_firs >>> .td_table_header_style{
    height: 30px;
    padding : 0;
    /*border-left: 1px solid #EBEEF5;*/
  }
  .el-table_firs >>> .td_cell_header_style,.el-table_firs >>> .td_cell_style{
    color: #343434;
    /*font-size: 12px;*/
  }
  .el-table_firs >>> .d2-container-full__body {
    padding: 0px 0px;
    .el-table__fixed-footer-wrapper tbody td {
      border-top: 1px solid #EBEEF5;
      background-color: #F5F7FA;
      color: #606266;
      font-size: 10px;
    }
  }
  // 加载原始图标隐藏
  .el-table_firs >>> .el-loading-spinner .circular{
    display: none;
  }
  // 加载动图样式位置（样式覆盖原图）
  .el-table_firs >>> .el-loading-spinner{
    background: url("./loading.gif") no-repeat;
    background-size: 300px 300px;
    height: 35%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    left: 40%;
    top: 25%;
  }
  // 加载文字提醒样式位置
  .el-table_firs >>> .el-loading-spinner .el-loading-text {
    margin-left: 55px;
    text-align: left;
  }
  // 无数据页面高度设置
  .el-table_firs >>> .el-table__empty-block{
    height: 40% !important;
  }
  // 下滚动条显示在无数据页面之上
  .el-table_firs >>> .el-table__body-wrapper.is-scrolling-left{
    z-index : 2 !important;
    min-height :200px !important;
  }
</style>
<style lang="scss">
  // 分页显示在无数据页面之上
  .d2-container-full__footer{
    z-index :1 !important;
  }
  // 表格最小高度
  .d2-container-full__body__td{
    min-height :400px;
  }
  .el-table_firs .el-table__footer-wrapper .has-gutter {
    font-size: 10px;
  }
  .el-table th {
    background: #EDEFF2;
    border-right: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
  .el-table td {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #343434;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-table/index.vue"
}
</vue-filename-injector>
