<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :remote-method="remoteMethod"
             :loading="loading">
    <el-option
        class="td_option"
        v-for="(item, index) in options"
        :key="index"
        :label="item.label"
        :value="item.value"
        @click.native="handleSelect(item)">
      <span><i class="fa fa-gg-circle" :style="{'color':item.sourceType==='cus'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.label }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { haireOjbName } from '@/api/basic/comlist'
import { validatenull } from '@/tdcommon/validate'
// 应收账单结算单位id查询条件
export default {
  name: 'd2-container-receivable-receiverId',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false,
      mdmStrMark: ''
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    sourcetype: {
      type: String,
      default: () => ''
    },
    mdmstr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      var Searchquery = validatenull(query) ? '' : query
      haireOjbName(Searchquery, this.sourcetype).then(response => {
        this.loading = false
        if (response.code === 0) {
          let options = []
          response.data.forEach(item => {
            let option = {}
            option.label = item.customerName
            option.value = item.mdmCode
            option.sourceType = item.sourceType
            options.push(option)
          })
          this.options = options
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      // let vals = { value: Number(val.value), label: val.label }
      this.$emit('change', val)
    },
    getStartValues (val) {
      if (!validatenull(this.mdmstr)) {
        let model = this.$parent.elForm.model[this.mdmstr]
        if (!validatenull(model)) {
          this.values = Object.assign({ mdmCode: model, customerName: val })
        } else {
          this.values = Object.assign({ mdmCode: '', customerName: val })
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', validatenull(val) ? '' : val)
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      // if (!validatenull(this.options) && val !== oldVal) {
      //   this.remoteMethod(val)
      //   // this.getStartValues(val)
      // }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
    // this.getStartValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-receivable-receiverId/index.vue"
}
</vue-filename-injector>
