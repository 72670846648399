import request from '@/plugin/axios'
/*
** 获取未停用的 项目名称、承运单位
*/
export function firsOjbName (searchStr, type) {
  return request({
    url: '/tms/comlist/firs/OjbName',
    method: 'get',
    params: { searchStr: searchStr, searchType: type }
  })
}

export function haireOjbName (searchStr, type) {
  return request({
    url: '/tms/comlist/haire/OjbName',
    method: 'get',
    params: { searchStr: searchStr, type: type }
  })
}

export function qkation (searchStr) {
  return request({
    url: '/tms/comlist/pro/qkation',
    method: 'get',
    params: { searchStr: searchStr }
  })
}
/*
** 获取所有的（包括停用的） 项目名称、承运单位
*/
export function firsOjbNameAll (searchStr, type) {
  return request({
    url: '/tms/comlist/firs/OjbNameAll',
    method: 'get',
    params: { searchStr: searchStr, searchType: type }
  })
}
/**
 * 结算对象（不包含停用） 弹框查询
 */
export function objInputSearch (query) {
  return request({
    url: '/tms/comlist/firs/OjbName/page',
    method: 'post',
    data: query
  })
}
/**
 * 结算对象（全部） 弹框查询
 */
export function objInputSearchAll (query) {
  return request({
    url: '/tms/comlist/firs/OjbNameAllPage',
    method: 'post',
    data: query
  })
}
