import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmscy/page',
    method: 'post',
    data: query
  })
}
/**
 * 新增 差异管理
 */
export function addObj (obj) {
  return request({
    url: '/tms/tmscy',
    method: 'post',
    data: obj
  })
}
/**
 *
 修改 差异管理
 */
export function putObj (obj) {
  return request({
    url: '/tms/tmscy',
    method: 'put',
    data: obj
  })
}
/**
 *
 通过id查询
 */
export function getObj (id) {
  return request({
    url: '/tms/tmscy/' + id,
    method: 'get'
  })
}
/**
 * 通过id删除 差异管理
 */
export function delObj (id) {
  return request({
    url: '/tms/tmscy/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids批量删除运单
 */
export function cydelObjs (ids) {
  return request({
    url: '/tms/tmscy/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function cyAudit (ids) {
  return request({
    url: '/tms/tmscy/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function cyUnaudit (ids) {
  return request({
    url: '/tms/tmscy/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审单
 */
export function cyExamine (ids) {
  return request({
    url: '/tms/tmscy/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function cyUnexamine (ids) {
  return request({
    url: '/tms/tmscy/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 新增差异单保存
 */
export function savecy (obj) {
  return request({
    url: '/tms/tmscy/create',
    method: 'post',
    data: obj
  })
}
/*
* 更新差异单
*/
export function updatecy (obj) {
  return request({
    url: '/tms/tmscy/update',
    method: 'post',
    data: obj
  })
}
/**
 * 编辑通过id取得运单信息
 */
export function cyedit (id) {
  return request({
    url: '/tms/tmscy/detail/' + id,
    method: 'post'
  })
}
/*
* 通过托运单ID查询*
*/
export function getCyListByTydId (ydNo) {
  return request({
    url: '/tms/tmscy/yddetails',
    method: 'get',
    params: ydNo
  })
}
/**
 * 通过ids进行差异单复审
 */
export function cyRetial (ids) {
  return request({
    url: '/tms/tmscy/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行差异单反复审
 */
export function cyUnretrial (ids) {
  return request({
    url: '/tms/tmscy/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 企业微信审批
 */
export function dingtalk (processCode, id) {
  return request({
    url: '/tms/tmscy/dingtalk/' + processCode + '/' + id,
    method: 'get'
  })
}
export function exportCyTable (obj) {
  return request({
    url: '/ex/export/exportCyInformation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliCy (obj) {
  return request({
    url: '/tms/ossobject/differenceManagementUpload',
    method: 'post',
    data: obj
  })
}
/**
 * 差异明细导出*
 */
export function cyDetailsExport (ids) {
  return request({
    url: '/tms/tmscy/exportDifferenceDetails',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
