<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             collapse-tags
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :loading="loading"
             :multiple="multiple">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.label"
      :value="item.value"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { fetchDispatchName } from '@/api/basic/dispatch'

export default {
  name: 'd2-container-dispatcher',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      let listItem = []
      fetchDispatchName().then(response => {
        this.loading = false
        if (response.code === 0) {
          response.data.forEach(item => {
            if (item.schedulingNature === '0' || item.schedulingNature === '内部') { // 过滤只是内部的调度
              let option = {}
              option.label = item.dispatchName
              option.value = item.dispatchName
              listItem.push(option)
            }
          })
          this.options = listItem
          // this.options = downValues(response.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.values = validatenull(val) ? [] : val
      this.remoteMethod(this.values)
      if (validatenull(this.values)) {
        this.$emit('change', [])
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-dispatcher/index.vue"
}
</vue-filename-injector>
