import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/dtcustomerreceiver/page',
    method: 'get',
    params: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/dtcustomerreceiver',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/dtcustomerreceiver/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/dtcustomerreceiver/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/dtcustomerreceiver',
    method: 'put',
    data: obj
  })
}
/**
 * 通过输入收货单位远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getRecName (custId, contName) {
  return request({
    url: '/basic/dtcustomerreceiver/getcontperlist',
    method: 'get',
    params: { custId: custId, contName: contName }
  })
}
