import request from '@/plugin/axios'

export function addObj (obj) {
  return request({
    url: '/tms/tmsItemSetup/insertItemSetup',
    method: 'post',
    data: obj
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmsItemSetup/batchDelete/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsItemSetup/updateItemSetup',
    method: 'post',
    data: obj
  })
}
/*
*单位组 判断重复 *
*/
export function getRepeatDetails (obj) {
  return request({
    url: '/tms/tmsItemSetup/queryRepeatData/' + obj,
    method: 'get'
  })
}
/*
*计量单位 判断重复 *
*/
export function getRepeatMetering (obj) {
  return request({
    url: '/tms/tmsItemSetup/queryRepeatUnitGroup',
    method: 'get',
    params: obj
  })
}
/**
 * 获取树形数据
 */
export function fetchtree (query) {
  return request({
    url: '/tms/tmsItemSetup/queryAllData',
    method: 'get',
    data: query
  })
}
/**
 * 其他模块 获取计量单位
 */
export function getItemId (id) {
  return request({
    url: '/tms/tmsItemSetup/selectGoodsOrItemData/' + id,
    method: 'get'
  })
}
