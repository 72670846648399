<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder=""
             :clearable="clearable"
             :disabled="disabled"
             :remote-method="remoteMethod"
             @clear="clearAction"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.mdmCode+ '  ' +item.customerName"
      :value="item.customerName"
      @click.native="handleSelect(item)">
      <span style="float: left;width: 130px;"><i class="fa fa-gg-circle" :style="{'color':item.sourceType==='cus'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.mdmCode }}</span>
      <span style="float: left; color: #8492a6; font-size: 13px;">{{ item.customerName }}</span>
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { getUrlSearch } from '@/api/sys.ui'

export default {
  name: 'd2-container-settlemdm-oil',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      getUrlSearch('/tms/haiersupplier/oil', { search: validatenull(query) ? '' : query }).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      if (!validatenull(val)) {
        this.$emit('input', val.toString())
      }
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
      // this.$emit('change', '')
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-settlemdm-oil/index.vue"
}
</vue-filename-injector>
