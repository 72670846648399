import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsCustomers/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsCustomers/insertTmsCustomers',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmsCustomers/queryPieceData/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsCustomers/updateTmsCustomers',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsCustomers/batchDeleteData',
    method: 'post',
    params: ids
  })
}
/**
 * 判断客户名称是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeWhetherRepeat',
    method: 'post',
    params: { customerName: obj }
  })
}
/**
 * 导入
 */
export function excelImport (file) {
  return request({
    url: '/tms/tmsCustomers/importTmsCustomersData',
    method: 'post',
    params: file
  })
}
/**
 * 通过ids进行审核
 */
export function passExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function passUnExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行拒审
 */
export function refuseExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/unExamineFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总审核
 */
export function passReview (ids) {
  return request({
    url: '/tms/tmsCustomers/review',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总反审核
 */
export function passUnReview (ids) {
  return request({
    url: '/tms/tmsCustomers/unReview',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总拒审
 */
export function refuseReview (ids) {
  return request({
    url: '/tms/tmsCustomers/unReviewFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 详情页 获取修改记录list
 */
export function getUpdateList (query) {
  return request({
    url: '/tms/tmslogs/customersQueryRecord',
    method: 'post',
    data: query
  })
}
/**
 * 判断信用代码是否重复
 */
export function getCreditCode (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeCreditCodeRepeat',
    method: 'post',
    params: { creditCode: obj }
  })
}
// 客源管理  跳转 立项报告
export function getIntroduceMsg (id) {
  return request({
    url: '/tms/tmsCustomers/adoptQueryReviewData/' + id,
    method: 'get'
  })
}
/**
 * 客源管理导入 返回结果
 */
export function returnPassengerImport () {
  return request({
    url: '/tms/tmsCustomers/import/progress',
    method: 'get'
  })
}
/**
 * 判断项目名称是否重复
 */
export function getProjectName (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeCustomerRepeat',
    method: 'post',
    params: { projectName: obj }
  })
}
/**
 *
 获取跟踪记录
 */
export function getObjList (customersId) {
  return request({
    url: '/tms/customersTrack/selectCustomersTrack',
    method: 'post',
    params: { customersId: customersId }
  })
}
/**
 *
 新增跟踪记录
 */
export function addCustomerTrack (obj) {
  return request({
    url: '/tms/customersTrack/insertCustomersTrack',
    method: 'post',
    data: obj
  })
}
/**
 *
 通过id删除跟踪记录
 */
export function delObj (id) {
  return request({
    url: '/tms/customersTrack/deleteCustomersTrack',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 获取客户漏斗的 客户名称（搜索下拉）
 */
export function getCustomerFunnel (customerName) {
  return request({
    url: '/tms/tmsCustomers/queryCustomersInformation',
    method: 'post',
    params: { customerName: customerName }
  })
}
/**
 * 询价单 取客户漏斗的 项目名称
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getPassengerProject (projectName) {
  return request({
    url: '/tms/tmsCustomers/fuzzyQueryProjectName',
    method: 'post',
    params: { projectName: projectName }
  })
}
