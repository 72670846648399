import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsContract/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsContract/insertTmsContract',
    method: 'post',
    data: obj
  })
}

export function tredit (id) {
  return request({
    url: '/tms/tmsContract/queryTmsContractMessage/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsContract/updateTmsContract',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsContract/deleteTmsContract',
    method: 'delete',
    params: ids
  })
}
/**
 * 统计
 */
export function statisticsMsg () {
  return request({
    url: '/tms/tmsContract/contractStatisticalTable',
    method: 'post'
  })
}
/**
 * 合同台账导入 返回结果
 */
export function returnContractImport () {
  return request({
    url: '/tms/tmsContract/import/progress',
    method: 'get'
  })
}
