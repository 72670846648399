<template>
  <div>
<!--    <el-dropdown size="small">-->
<!--      <span class="btn-text">{{info.companyName}} </span>-->
<!--      <el-dropdown-menu slot="dropdown" v-if="isCompateNull">-->
<!--        <el-dropdown-item v-for="(item, index) in comOptions" :key="index" @click.native="toggle(item)" :disabled="item.companyName === info.companyName">-->
<!--          <span>{{item.companyCode}}</span>-->
<!--          {{item.companyName}}-->
<!--        </el-dropdown-item>-->
<!--      </el-dropdown-menu>-->
<!--    </el-dropdown>-->
    <el-tooltip
            v-if="info.avatar"
            effect="dark"
            content="用户头像"
            placement="bottom">
      <img
              id="thumbnail"
              class="top-bar__img">
    </el-tooltip>
    <el-dropdown size="small" class="d2-mr">
      <span class="btn-text">{{info.username ? `你好 ${info.name}` : '未登录'}}</span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="toUserpage">
          <d2-icon name="address-card-o" class="d2-mr-5"/>
          个人中心
        </el-dropdown-item>
        <el-dropdown-item @click.native="logOff">
          <d2-icon name="power-off" class="d2-mr-5"/>
          退&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { handleImg } from '@/tdcommon/beeutil'
import { getDecodeStore, setEncodeStore } from '@/tdcommon/store'
import { validatenull } from '@/tdcommon/validate'
import request from '@/plugin/firs'

export default {
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ]),
    ...mapGetters(['roles'])
  },
  created () {
    handleImg(this.info.avatar, 'thumbnail')
    // this.getCompanyList()
  },
  data () {
    return {
      comOptions: []
    }
  },
  methods: {
    ...mapActions('d2admin/account', [
      'logout', 'loginchange', 'GetUserInfo'
    ]),
    ...mapActions('d2admin/page', [
      'closeAll'
    ]),
    /**
     * @description 登出
     */
    logOff () {
      this.logout({
        confirm: true
      })
    },
    isCompateNull () {
      return !validatenull(this.comOptions)
    },
    /**
     * 个人中心
     */
    toUserpage () {
      // 跳转路由
      this.$router.push({
        // name: '/info'
        path: '/info'
      })
    },
    /**
     * 法人公司列表
     */
    getCompanyList () {
      this.comOptions = getDecodeStore('tbCompanyLegal')
      if (validatenull(this.comOptions)) {
        request({
          url: '/admin/syscompanylegal/list',
          method: 'get'
        }).then(response => {
          this.comOptions = response.data
          setEncodeStore('tbCompanyLegal', this.comOptions)
        })
      }
    },
    /**
     * 切换法人公司
     */
    toggle (company) {
      this.$confirm('确认切换到《' + company.companyName + '》？').then(_ => {
        this.loginchange(company).then((res) => {
          if (res.data) {
            this.closeAll()
            this.$message.success('成功能切换到《' + company.companyName + '》')
          }
          this.GetUserInfo()
        }).catch(() => {
          this.$message.error('切换失败')
        })
      }).catch(_ => { this.$message('切换操作已取消') })
    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
