




































































































































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-query-setting/index.vue"
}
