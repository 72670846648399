import request from '@/plugin/axios'

export function roleList () {
  return request({
    url: '/admin/role/roleList',
    method: 'get'
  })
}

export function fetchList (query) {
  return request({
    url: '/basic/oarole/page',
    method: 'get',
    params: query
  })
}

export function deptRoleList () {
  return request({
    url: '/admin/role/list',
    method: 'get'
  })
}

export function getObj (id) {
  return request({
    url: '/admin/role/' + id,
    method: 'get'
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/oarole',
    method: 'post',
    data: obj
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/oarole',
    method: 'put',
    data: obj
  })
}

export function delObj (id) {
  return request({
    url: '/basic/oarole/' + id,
    method: 'delete'
  })
}

export function permissionUpd (roleId, menuIds) {
  return request({
    url: '/basic/oarole/menu/' + roleId,
    method: 'put',
    data: {
      menuIds: menuIds
    }
  })
}

export function fetchRoleTree (roleId) {
  return request({
    url: '/admin/menu/oaTree/' + roleId,
    method: 'get'
  })
}
export function fetchMenuTree (query) {
  return request({
    url: '/admin/menu/oaTree',
    method: 'get',
    params: query
  })
}
/**
 * 角色名 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/oarole/details/' + obj,
    method: 'get'
  })
}
/**
 * 小程序用户 角色下拉
 */
export function fetchRole (query) {
  return request({
    url: '/basic/oarole/selectRoleList',
    method: 'get',
    params: query
  })
}
// 角色对应用户
export function getUser (obj) {
  return request({
    url: '/admin/role/roleCorrespondingUser',
    method: 'post',
    data: obj
  })
}
/**
 * 复制角色
 */
export function copyObj (id) {
  return request({
    url: '/admin/role/oneTouchRole/' + id,
    method: 'put'
  })
}
