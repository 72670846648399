<template>
  <el-dialog title="批量下载回单" :visible.sync="dialogVisible" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <el-button style="margin-bottom: 10px;" type="primary" size="medium" :loading="loading" @click="downloadFiles">下载文件</el-button>
    <el-progress :percentage="progress" :stroke-width="10"></el-progress>
  </el-dialog>
</template>
<script>
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import axios from 'axios'
export default {
  name: 'd2-container-batch-download',
  data () {
    return {
      dialogVisible: false,
      progress: 0,
      loading: false,
      titleText: '',
      urls: []
    }
  },
  props: {
  },
  watch: {
  },
  methods: {
    showDialog (data, text) {
      this.dialogVisible = true
      this.titleText = text
      this.urls = data
    },
    download (href) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: href,
          responseType: 'arraybuffer'
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error.toString())
        })
      })
    },
    downloadFiles () {
      let self = this
      const zip = new JSZip()
      const cache = {}
      const promises = []
      let count = 0
      self.loading = true
      self.urls.forEach((item, index) => {
        let percent = (index + 1) / self.urls.length * 100
        self.progress = Math.round(percent)
        count++
        const promise = this.download(item).then(data => {
          zip.file(item.substring(item.lastIndexOf('/') + 1), data, { binary: true })
          cache[item.substring(item.lastIndexOf('/') + 1)] = data
        })
        promises.push(promise)
      })
      Promise.allSettled(promises).then(() => {
        if (count === self.urls.length) {
          self.progress = 100
          setTimeout(() => {
            self.loading = false
            self.dialogVisible = false
            self.progress = 0
          }, 1000)
        }
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, '批量下载' + self.titleText + '.zip')
        })
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-batch-download/index.vue"
}
</vue-filename-injector>
