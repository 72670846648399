<template>
  <el-select class="td_select"
             v-model="values"
             :loading="loading"
             clearable
             :disabled="disabled"
             filterable
             remote
             :remote-method="remoteMethod">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item"
      :value="item"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>
// import { alluser } from '@/api/admin/user'
import { validatenull } from '@/tdcommon/validate'
import { getSettleName } from '@/api/tms/tmsCollectionFund'

export default {
  name: 'd2-container-settle',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    remoteMethod (val) {
      console.log('remoteMethod', val)
      this.loading = true
      let Str = validatenull(val) ? '' : val
      getSettleName(Object.assign({
        settleName: Str
      })).then(response => {
        console.log('getSettleName', response)
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      if (!validatenull(val)) {
        this.values = val
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-settleName/index.vue"
}
</vue-filename-injector>
