<template>
    <div id="myChainGroupChartPie" :style="{height:height, width: width}"></div>
</template>
<script>

export default {
  name: 'd2-container-echarts-pie',
  data () {
    return {
      totalVotes: [],
      legendData: [],
      seriesTotalQuantity: [],
      seriesTotalWeight: [],
      seriesTotalVolume: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    pieData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
  },
  methods: {
    initChart (title, quantity, weight, volume, totalAmount, profit, pieLegendData, seriesTotalQuantity, seriesTotalWeight, seriesTotalVolume, seriesTotalAmount, seriesTotalProfit) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入饼状图组件
      require('echarts/lib/chart/pie')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')
      // 清空上次数据
      this.legendData = []
      this.seriesTotalQuantity = []
      this.seriesTotalWeight = []
      this.seriesTotalVolume = []
      this.seriesTotalAmount = []
      this.seriesTotalProfit = []
      // 赋值
      this.legendData = pieLegendData
      this.seriesTotalQuantity = seriesTotalQuantity
      this.seriesTotalWeight = seriesTotalWeight
      this.seriesTotalVolume = seriesTotalVolume
      this.seriesTotalAmount = seriesTotalAmount
      this.seriesTotalProfit = seriesTotalProfit

      let myChart = echarts.init(document.getElementById('myChainGroupChartPie'))
      myChart.setOption({
        title: {
          text: title,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: this.legendData
        },
        series: [
          {
            name: quantity,
            type: 'pie',
            center: ['20%', '30%'],
            radius: '30%',
            data: this.seriesTotalQuantity
          },
          {
            name: weight,
            type: 'pie',
            center: ['50%', '30%'],
            radius: '30%',
            data: this.seriesTotalWeight
          },
          {
            name: volume,
            type: 'pie',
            center: ['80%', '30%'],
            radius: '30%',
            data: this.seriesTotalVolume
          },
          {
            name: totalAmount,
            type: 'pie',
            center: ['35%', '70%'],
            radius: '30%',
            data: this.seriesTotalAmount
          },
          {
            name: profit,
            type: 'pie',
            center: ['65%', '70%'],
            radius: '30%',
            data: this.seriesTotalProfit
          }
        ]
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-chaingroup-pie/index.vue"
}
</vue-filename-injector>
