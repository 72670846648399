import request from '@/plugin/axios'

export function fetchTree (query) {
  return request({
    url: '/admin/dept/tree',
    method: 'get',
    params: query
  })
}

export function addObj (obj) {
  return request({
    url: '/admin/dept/',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/admin/dept/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/admin/dept/' + id,
    method: 'delete'
  })
}

export function dingSync () {
  return request({
    url: '/admin/dept/deptSyc',
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/admin/dept/',
    method: 'put',
    data: obj
  })
}
/*
*部门名称 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/admin/dept/details/' + obj,
    method: 'get'
  })
}
/*
*获取只有业务(项目)部门 *
*/
export function getOperationDept (query) {
  return request({
    url: '/admin/dept/queryDeptsList',
    method: 'get',
    params: query
  })
}
/*
*根据部门id 获取子级部门 *
*/
export function getDispatchDept (query) {
  return request({
    url: '/admin/dept/queryDeptList',
    method: 'get',
    params: query
  })
}
/*
*获取所有大区的部门 *
*/
export function getAreaDept (query) {
  return request({
    url: '/admin/dept/queryDeptsRegionList',
    method: 'get',
    params: query
  })
}
/**
 * 根据大区ID 查询大区下部门的信息
 */
export function getRegionLeader (deptId) {
  return request({
    url: '/admin/dept/selectRegionLeadership/' + deptId,
    method: 'get'
  })
}
/**
 * 根据多个大区ID 查询大区下多个部门的信息
 */
export function getRegionLeaderMore (deptIds) {
  return request({
    url: '/admin/dept/selectDepartmentsByRegion',
    method: 'get',
    params: deptIds
  })
}
/**
 * 查询运力部经理信息
 */
export function getCapacityManager () {
  return request({
    url: '/admin/dept/selectCapacityDepartment',
    method: 'get'
  })
}
/*
*只查询运营部门+结算平台 *
*/
export function getOperationSettleDept (query) {
  return request({
    url: '/admin/dept/onlyReturnSomeTree',
    method: 'get',
    params: query
  })
}
