<template>
  <el-select class="td_select"
             v-model="values"
             size="mini"
             style="width: 100%"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :remote-method="remoteMethod"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.itemName"
      :value="item.itemName"
      @click.native="handleSelect(item)">
      <span style="float: left;"><i class="fa fa-gg-circle" :style="{'color':item.sourceType==='cus'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.itemName }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px;">{{ item.itemId }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
import { getSubjectObjs } from '@/api/tms/financesubject'

export default {
  name: 'd2-container-mdmselect',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    sourcetype: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      var Searchquery = validatenull(query) ? '' : query
      getSubjectObjs(Searchquery).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', validatenull(val) ? '' : val.toString())
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-subject-edit/index.vue"
}
</vue-filename-injector>
