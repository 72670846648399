import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/hremployee/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/hremployee',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/hremployee/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/hremployee/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/hremployee',
    method: 'put',
    data: obj
  })
}
/*
*用户 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/tms/hremployee/details/' + obj,
    method: 'get'
  })
}
/*
*用户管理  获取员工档案的用户*
*/
export function fetchUserId () {
  return request({
    url: '/tms/hremployee/list',
    method: 'get'
  })
}

/*
*用户管理  获取员工档案的用户*
*/
export function fetchSceneUserList () {
  return request({
    url: '/tms/hremployee/scene',
    method: 'get'
  })
}
/*
*员工档案  新增保存判断姓名是否重复*
*/
export function judgeName (obj) {
  return request({
    url: '/tms/hremployee/judgmentRepeatUserName',
    method: 'post',
    params: obj
  })
}
/**
 * 根据员工姓名查询项目详情
 */
export function selectProjectDetails (obj) {
  return request({
    url: '/basic/dtcustomer/accordingEmployeeInquiries',
    method: 'POST',
    params: obj
  })
}
