<template>
  <div class="myTable">
    <div class="input searchInfo" >
      <span><span>ICCID：</span><input v-model="iccid"  placeholder="请输入"/></span>
      <el-button type="primary" @click="findPosition" style="float: right; position: relative;left: -20px;" >搜索</el-button>
    </div>
    <div class="amap-page-container">
      <el-amap vid="amapDemo" :zoom="zoom" :center="center" class="amap-demo">
<!--        <el-amap-marker v-for="(marker, index) in markers" :position="marker.position" :content="marker.content" :angle="marker.angle" :events="marker.events" :title="marker.title" :visible="marker.visible" :draggable="marker.draggable" :vid="index"/>-->
      </el-amap>
    </div>
  </div>
</template>
<script>

import { getGpsPosition } from '@/api/basic/carinfo'
export default {
  name: 'd2-container-golde-location-2',
  data () {
    // const self = this
    return {
      arriveCoor: [108.947025, 34.2613255], // 坐标点
      arrive: '' // 位置信息
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    getCarPositionMsg (carno) {
      getGpsPosition('豫FB5520').then(response => {
        if (response.code === 0) {
          let resObj = JSON.parse(response.data)
          this.lng = Number(resObj.lon) / 600000
          this.lat = Number(resObj.lat) / 600000
          console.log(this.lng)
          console.log(this.lat)
          this.center = [this.lng, this.lat]
          this.loaded = true
          this.$nextTick()
        } else {
          this.$message.error('未找到此车牌号的定位信息！')
        }
      })
    },
    findPosition () {
    },
    myMarkers (val, dir) {
      let marker = {
        position: val,
        events: {
          click: () => {
            console.log('获取信息失败')
          }
        },
        content: '<img src="http://test.blackview4g.com:8280/images/car.png" />',
        angle: dir
        // title: title
      }
      this.markers.push(marker)
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-golde-location-2/index.vue"
}
</vue-filename-injector>
