<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             :disabled="disabled"
             :clearable="clearable"
             placeholder="请输入关键词"
             :remote-method="remoteMethod"
             :size="size"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.lable"
      :value="item.lable"
      @click.native="handleSelect(item)">
      <span style="float: left; color: #8492a6; font-size: 13px;">{{ item.lable }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { qkation } from '@/api/basic/comlist'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-cussup',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false,
      size: this.sizes
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    sizes: {
      type: String,
      default: () => 'mini'
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      if (validatenull(query)) {
        this.loading = true
        return
      }
      qkation(query).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      if (!validatenull(val)) {
        this.values = val
      } else {
        this.value = ''
      }
      this.remoteMethod(val)
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-cussup/index.vue"
}
</vue-filename-injector>
