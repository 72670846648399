import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/salesIncome/page',
    method: 'post',
    data: query
  })
}

export function getObj (id) {
  return request({
    url: '/tms/salesIncome/querySalesIncome/' + id,
    method: 'get'
  })
}

/**
 * 保存
 */
export function addSave (obj) {
  return request({
    url: '/tms/salesIncome/insertSalesIncome',
    method: 'post',
    data: obj
  })
}

export function editSave (obj) {
  return request({
    url: '/tms/salesIncome/updateSalesIncome',
    method: 'post',
    data: obj
  })
}

export function deleteIds (ids) {
  return request({
    url: '/tms/salesIncome/deleteSalesIncome',
    method: 'post',
    params: ids
  })
}
/**
   * 通过ids进行市场审单
   */
export function auditMarket (ids) {
  return request({
    url: '/tms/salesIncome/auditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行市场反审单
 */
export function unAuditMarket (ids) {
  return request({
    url: '/tms/salesIncome/unAuditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力审核
 */
export function auditPersonnel (ids) {
  return request({
    url: '/tms/salesIncome/auditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力反审核
 */
export function unAuditPersonnel (ids) {
  return request({
    url: '/tms/salesIncome/unAuditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经办审核
 */
export function auditManager (ids) {
  return request({
    url: '/tms/salesIncome/auditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经办反审核
 */
export function unAuditManager (ids) {
  return request({
    url: '/tms/salesIncome/unAuditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 根据项目名称查询数据
 */
export function getCustomerMsg (customerName) {
  return request({
    url: '/basic/dtcustomer/queryDtCustomerData',
    method: 'post',
    params: customerName
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliSalesRevenue (obj) {
  return request({
    url: '/tms/ossobject/appendixUploadSalesIncome',
    method: 'post',
    data: obj
  })
}
