




























































































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-income-expenditure-search-sg/index.vue"
}
