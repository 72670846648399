<template>
  <!--调用员工档案的用户-->
  <el-select class="td_select"
             v-model="values"
             :loading="loading"
             clearable
             filterable
             allow-create>
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.name"
      @click.native="handleSelect(item)">
      <span style="float: left">{{ item.username }}</span>
      <span style="float: left; margin-left: 20px; color: #8492a6; font-size: 13px">{{ item.name }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { fetchUserId } from '@/api/tms/tmsemployee'
// 调度员调取员工档案（取值姓名）
export default {
  name: 'd2-container-employee',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod () {
      this.loading = true
      fetchUserId().then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.values = val
    }
  },
  mounted () {
    this.remoteMethod()
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-employee-dispatch/index.vue"
}
</vue-filename-injector>
