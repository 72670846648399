<template>
    <div class="chainGroupChartBar" id="myChainGroupChartBar" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-chainGroup-bar',
  data () {
    return {
      type: this.modetype,
      xAxisData: [],
      seriesTotalQuantity: [],
      seriesTotalWeight: [],
      seriesTotalVolume: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => '550px'
    },
    width: {
      type: String,
      default: () => '950px'
    },
    barData: {
      type: Array,
      default: () => []
    }
  },
  create () {
    // this.initChart()
  },
  mounted () {
    // this.initChart()
  },
  methods: {
    initChart (name1, name2, name3, name4, name5, name6, name7, xAxisData, totalIncome, totalIncomeFee, totalAmount, totalAmountFee, totalCost, totalCostFee, realProfit) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入柱状图组件
      require('echarts/lib/chart/bar')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上次数据
      this.xAxisData = []
      this.totalIncome = []
      this.totalIncomeFee = []
      this.totalAmount = []
      this.totalAmountFee = []
      this.totalCost = []
      this.totalCostFee = []
      this.realProfit = []
      // 赋值
      this.xAxisData = xAxisData
      this.totalIncome = totalIncome
      this.totalIncomeFee = totalIncomeFee
      this.totalAmount = totalAmount
      this.totalAmountFee = totalAmountFee
      this.totalCost = totalCost
      this.totalCostFee = totalCostFee
      this.realProfit = realProfit

      var myChart = echarts.init(document.getElementById('myChainGroupChartBar'))
      // var colors = ['#55A8FD', 'rgba(50, 197, 233, 1)', 'rgba(159, 230, 184, 1)']
      var options = {
        // color: colors,
        title: { text: '链群营收图' },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: true },
            magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [name1, name2, name3, name4, name5, name6, name7],
          selected: {
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        },
        grid: {
          left: 0,
          right: '4%',
          bottom: '10%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#969696'
            }
          },
          axisLabel: {
            // formatter: function (value) {
            //   return value.split('').join('\n')
            // }
          }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#E8E8E9'
              }
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#969696'
              }
            }
          }
        ],
        series: [
          {
            name: name1,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalIncome,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name2,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalIncomeFee,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name3,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalAmount,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name4,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalAmountFee,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name5,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalCost,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name6,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.totalCostFee,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: name7,
            type: 'bar',
            zlevel: 2,
            barWidth: '20%',
            barGap: '100%',
            data: this.realProfit,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }]
      }
      myChart.setOption(options, true)

      myChart.on('legendselectchanged', function (obj) {
        var name = obj.name
        var selected = obj.selected
        if (name === '总收入(含税)') {
          selected = {
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        } else if (name === '总收入(不含税)') {
          selected = {
            '总收入(含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        } else if (name === '总运费(含税)') {
          selected = {
            '总收入(含税)': false,
            '总收入(不含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        } else if (name === '总运费(不含税)') {
          selected = {
            '总收入(含税)': false,
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        } else if (name === '总成本(含税)') {
          selected = {
            '总收入(含税)': false,
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(不含税)': false,
            '总毛利': false
          }
        } else if (name === '总成本(不含税)') {
          selected = {
            '总收入(含税)': false,
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总毛利': false
          }
        } else if (name === '总毛利') {
          selected = {
            '总收入(含税)': false,
            '总收入(不含税)': false,
            '总运费(含税)': false,
            '总运费(不含税)': false,
            '总成本(含税)': false,
            '总成本(不含税)': false
          }
        }
        options.legend.selected = selected
        myChart.setOption(options, true)
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-chaingroup-bar/index.vue"
}
</vue-filename-injector>
