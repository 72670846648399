import request from '@/plugin/axios'
import { validatenull } from '@/tdcommon/validate'
// 车辆信息分页查询
export function fetchList (query) {
  return request({
    url: '/convoy/convoyVehicle/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObj (obj) {
  return request({
    url: '/convoy/convoyVehicle/insertVehicle',
    method: 'post',
    data: obj
  })
}
// 修改保存
export function putObj (obj) {
  return request({
    url: '/convoy/convoyVehicle/updateVehicle',
    method: 'post',
    data: obj
  })
}

/**
 * 通过ids批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/convoy/convoyVehicle/deleteTmsVehicleData',
    method: 'post',
    params: ids
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/convoy/convoyVehicle/queryMessage/' + id,
    method: 'get'
  })
}
/*
* 申请用车、归还用车 车牌号*
*/
export function selectConvoyApplyCarNo (carNo) {
  return request({
    url: '/convoy/convoyVehicle/selectConvoyApplyCarNo',
    method: 'post',
    params: { carNo: validatenull(carNo) ? '' : carNo }
  })
}
// 挂车信息分页查询
export function fetchListTrailer (query) {
  return request({
    url: '/convoy/convoyTrailer/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObjTrailer (obj) {
  return request({
    url: '/convoy/convoyTrailer/insertTrailer',
    method: 'post',
    data: obj
  })
}
// 修改保存
export function putObjTrailer (obj) {
  return request({
    url: '/convoy/convoyTrailer/updateTrailer',
    method: 'post',
    data: obj
  })
}

/**
 * 通过ids批量删除
 */
export function delObjsTrailer (ids) {
  return request({
    url: '/convoy/convoyTrailer/deleteTrailer',
    method: 'post',
    params: ids
  })
}

/*
* 通过id查询*
*/
export function treditTrailer (id) {
  return request({
    url: '/convoy/convoyTrailer/queryById/' + id,
    method: 'get'
  })
}
/**
 * 通过输入远程搜索挂车车牌号
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getTrailerNo (trailerNo) {
  return request({
    url: '/convoy/convoyTrailer/list',
    method: 'post',
    params: { carNo: trailerNo }
  })
}
