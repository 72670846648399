<template>
  <el-select class="td_select"
             v-model="values"
             :loading="loading"
             clearable
             :disabled="disabled"
             filterable>
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.name"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>
import { alluser } from '@/api/admin/user'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-username',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    remoteMethod () {
      this.loading = true
      alluser().then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      if (!validatenull(val)) {
        this.values = val
      }
    }
  },
  mounted () {
    this.remoteMethod()
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-username/index.vue"
}
</vue-filename-injector>
