<template>
  <el-autocomplete
    popper-class="td-autocomplete"
    v-model="state"
    style="width: 100%"
    :fetch-suggestions="querySearchAsync"
    placeholder='请输入内容'
    :clearable="clearable"
    :disabled="disabled"
    @select="handleSelect"
    @blur="handleBlur"
    @clear="handleClear">
    <template slot-scope="{ item }">
      <div class="name">{{ item.name }}</div>
      <span class="addr">{{ item.address.length === 0 ? '' : item.address }}</span>
    </template>
  </el-autocomplete>
</template>
<script>
import { IpLocation, InputTips } from '@/api/sys.golde'
export default {
  name: 'd2-container-address',
  data () {
    return {
      // 用户登录IP所在地
      LocationName: '上海',
      // 查询结果
      restaurants: [],
      state: this.value,
      timeout: null
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    loadAll () {
      IpLocation().then(response => {
        if (response.info === 'OK') {
          this.LocationName = response.city
          InputTips(this.LocationName).then(response => {
            if (response.info === 'OK') {
              this.restaurants = response.tips
            } else {
              this.restaurants = []
            }
          }).catch(() => {
            this.restaurants = []
          })
        } else {
          this.LocationName = '上海'
        }
      }).catch(() => {
        this.LocationName = '上海'
      })
    },
    querySearchAsync (queryString, cb) {
      if (queryString) {
        InputTips(queryString).then(response => {
          if (response.info === 'OK') {
            this.restaurants = response.tips
            cb(this.restaurants)
          }
        })
      } else {
        cb(this.restaurants)
      }
    },
    handleSelect (item) {
      console.log('handleSelect', item)
      this.state = item.district + item.address
      this.$emit('change', this.state)
    },
    handleBlur () {
      this.$emit('blur', this.state)
    },
    handleClear () {
      this.$emit('clear', '')
    }
  },
  watch: {
    state: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.state = val
    }
  },
  mounted () {
    this.loadAll()
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-address/index.vue"
}
</vue-filename-injector>
