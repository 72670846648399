import request from '@/plugin/axios'
import { exportjsontoexcel } from './Export2Excel.js'
import { validatenull } from '@/tdcommon/validate'
export const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

// 下载文件
export function handleDown (filename, bucket) {
  return request({
    url: '/admin/sys-file/' + bucket + '-' + filename,
    method: 'get',
    responseType: 'blob'
  }).then((response) => { // 处理返回的文件流
    const blob = response
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function () {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

export function excelExportFile (allColumns, tableData, excelName) {
  var columnNames = []
  var columnValues = []
  var columnDataDis = []
  for (var i = 0; i < allColumns.length; i++) {
    // 判断是否显示在表格
    if (allColumns[i].isShowTable === '0') {
      columnNames[i] = allColumns[i].label
      columnValues[i] = allColumns[i].prop
      if (validatenull(allColumns[i].editOptions)) {
        columnDataDis[i] = []
      } else {
        columnDataDis[i] = allColumns[i].editOptions
      }
    } else {
      columnNames[i] = ''
      columnValues[i] = ''
      columnDataDis[i] = ''
    }
  }
  require.ensure([], () => {
    const tHeader = columnNames
    const filterVal = columnValues
    const columnDis = columnDataDis

    const list = tableData
    const data = formatJson(filterVal, list)
    // 导出自定义内容转换
    // data.forEach(item => { // 先循环得到的数据
    //   for (var i = 0; i < item.length; i++) { // 循环数据里面的每一个小数组
    //     for (var j = 0; j < columnDis.length; j++) { // 循环列里面的数据
    //       if (j === i) { // 如果下标相等时
    //         if (item[i] !== '' && item[i] !== null && item[i] !== undefined && columnDis[j] !== undefined && columnDis[j] !== '') { // 判断item里的值不为空、不为null、不为undefined; 判断循环列里面的值不为空、不为undefined
    //           columnDis[j].forEach(dis => { // 循环有数据的列里面的值
    //             if (dis.value === item[i]) { // 如果循环列的value等于item的值
    //               item[i] = dis.label // 让item等于循环列(dis)里面对应的label
    //             }
    //           })
    //         }
    //       }
    //     }
    //   }
    // })
    for (var t = 0; t < data.length; t++) {
      for (var i = 0; i < data[t].length; i++) { // 循环数据里面的每一个小数组
        for (var j = 0; j < columnDis.length; j++) { // 循环列里面的数据
          if (j === i) { // 如果下标相等时
            if (data[t][i] !== '' && data[t][i] !== null && data[t][i] !== undefined && columnDis[j] !== undefined && columnDis[j] !== '') { // 判断data[t]里的值不为空、不为null、不为undefined; 判断循环列里面的值不为空、不为undefined
              if (typeof columnDis[j] === 'string') { // 如果是JSON类型转换成数组
                JSON.parse(columnDis[j]).forEach(dis => { // 循环有数据的列里面的值
                  if (dis.value === data[t][i]) { // 如果循环列的value等于item的值
                    data[t][i] = dis.label // 让item等于循环列(dis)里面对应的label
                  }
                })
              } else {
                columnDis[j].forEach(dis => { // 循环有数据的列里面的值
                  if (dis.value === data[t][i]) { // 如果循环列的value等于item的值
                    data[t][i] = dis.label // 让item等于循环列(dis)里面对应的label
                  }
                })
              }
            }
          }
        }
      }
    }
    exportjsontoexcel(tHeader, data, excelName)
  })
}
// 导入结果 的导出方法
export function excelExportResult (allColumns, tableData, excelName) {
  var columnNames = []
  var columnValues = []
  for (var i = 0; i < allColumns.length; i++) {
    columnNames[i] = allColumns[i].label
    columnValues[i] = allColumns[i].property
  }
  require.ensure([], () => {
    const tHeader = columnNames
    const filterVal = columnValues

    const list = tableData
    const data = formatJson(filterVal, list)
    // 导出自定义内容转换
    data.forEach(item => {
      if (item[1] === 1) {
        item[1] = '成功'
      } else {
        item[1] = '失败'
      }
    })
    exportjsontoexcel(tHeader, data, excelName)
  })
}
export function formatJson (filterVal, jsonData) {
  return jsonData.map(v => filterVal.map(j => v[j]))
}
// 下载文件方法
export function downloadDoc (content, filename) {
  var eleLink = document.createElement('a')
  eleLink.download = filename + '.xlsx'
  eleLink.style.display = 'none'
  // 字符内容转变成blob地址
  var blob = new Blob([content], { type: 'text/plain;charset=UTF-8' })
  eleLink.href = URL.createObjectURL(blob)
  // 自动触发点击
  document.body.appendChild(eleLink)
  eleLink.click()
  // 然后移除
  document.body.removeChild(eleLink) // 测试commit
}
