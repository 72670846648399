<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             placeholder="请选择调度部门"
             :clearable="clearable"
             :disabled="disabled"
             :multiple="multiple"
             @clear="clearAction"
             :loading="loading">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.label"
      :value="item.value.toString()"
      @click.native="handleSelect(item)">
      <span style="float: left;"><i class="fa fa-modx" :style="{'color': '#66b1ff','margin-right': '5px'}"></i>{{ item.label }}</span>
    </el-option>
  </el-select>
</template>
<script>

// import { getDictUrl } from '@/api/sys.ui'
import { fetchTree } from '@/api/admin/dept'
import { validatenull } from '@/tdcommon/validate'
// import {downValues} from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-dept',
  data () {
    return {
      values: this.value,
      tableOptions: [],
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    findData () {
      // this.loading = true
      // getDictUrl(this.url).then(response => {
      //   console.log('调度部门', response.data)
      //   this.loading = false
      //   if (response.code === 0) {
      //     this.options = response.data
      //   }
      // }).catch(() => {
      //   this.loading = false
      // })
      this.loading = true
      fetchTree().then(response => {
        console.log('调度部门', response.data)
        this.loading = false
        if (response.code === 0) {
          this.tableOptions = this.downValues(response.data)
          this.tableOptions.forEach(item => {
            if (item.parentId === 114) {
              this.options.push(item)
            }
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    downValues (downValues) {
      let list = []
      list = this.treeToList([], downValues)
      return list
    },
    treeToList (list, dataSource) {
      dataSource.forEach((item) => {
        let items = {}
        items.label = item.name
        items.username = item.username
        items.value = item.id
        items.parentId = item.parentId
        list.push(items)
        if (item.children.length === 0) {
        } else {
          this.treeToList(list, item.children)
        }
      })
      return list
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.values = validatenull(val) ? '' : val.toString()
      this.$emit('change', val.toString())
    }
  },
  mounted () {
    this.findData()
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-dept-dispatch/index.vue"
}
</vue-filename-injector>
