<template>
    <div id="myChartPie" :style="{height:height, width: width}"></div>
</template>
<script>

export default {
  name: 'd2-container-echarts-pie',
  data () {
    return {
      totalVotes: [],
      legendData: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    pieData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
  },
  methods: {
    initChart (title, seriesname1, pieLegendData, pieTotalVotes) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入饼状图组件
      require('echarts/lib/chart/pie')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')
      // 赋值
      this.legendData = pieLegendData
      this.totalVotes = pieTotalVotes

      let myChart = echarts.init(document.getElementById('myChartPie'))
      myChart.setOption({
        title: {
          text: title,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: this.legendData
        },
        series: {
          name: seriesname1,
          type: 'pie',
          center: ['50%', '50%'],
          radius: '65%',
          data: this.totalVotes
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-pie/index.vue"
}
</vue-filename-injector>
