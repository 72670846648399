<template>
  <el-select class="td_select"
             v-model="values"
             :loading="loading"
             clearable
             :disabled="disabled"
             @clear="clearAction"
             @focus="focus_events"
             @visible-change="visible_change"
             filterable>
    <el-option
      class="td_option"
      v-for="item in options"
      :key="item.id"
      :label="item.metering"
      :value="item.id"
      @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
// import { getItemsName } from '@/api/tms/inventoryset'
import { getItemId } from '@/api/tms/measureunit'

export default {
  name: 'd2-container-metering',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: Number,
      default: () => 1
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    remoteMethod (value) {
      this.loading = true
      if (validatenull(value)) {
        this.loading = true
        return
      }
      getItemId(value).then(response => {
        if (response.code === 0) {
          this.options = response.data
          console.log('计量单位response.data:', response.data)
        }
      }).catch(() => {
        this.loading = false
      })
      // getItemsName().then(response => {
      //   this.loading = false
      //   if (response.code === 0) {
      //     this.options = response.data
      //   }
      // }).catch(() => {
      //   this.loading = false
      // })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    },
    focus_events (val) {
      this.$emit('focus', val)
    },
    visible_change (val) {
      this.$emit('visible_change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      // if (!validatenull(val)) {
      //   this.values = val
      // }
      if (!validatenull(val)) {
        this.values = val
      } else {
        this.value = ''
      }
      this.remoteMethod(val)
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-metering/index.vue"
}
</vue-filename-injector>
