<template>
    <el-button v-if="!searchShow" class="d2-mr btn-text can-hover" type="text" @click="handleClick">
      <el-tooltip
          effect="dark"
          content="搜索页面"
          placement="bottom">
        <d2-icon name="search" style="font-size: 18px;"/>
      </el-tooltip>
    </el-button>
    <el-autocomplete
        v-else
        size="small"
        class="panel-search__autocomplete d2-mr"
        ref="input"
        v-model="searchText"
        prefix-icon="el-icon-search"
        placeholder="搜索页面"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        :clearable="false"
        @keydown.esc.native="handleEsc"
        @select="handleSelect">
      <d2-panel-search-item
          slot-scope="{ item }"
          :item="item"/>
    </el-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import Fuse from 'fuse.js'
import mixin from '../mixin/menu'

export default {
  mixins: [
    mixin
  ],
  components: {
    'd2-panel-search-item': () => import('../panel-search/components/panel-search-item/index.vue')
  },
  data () {
    return {
      searchText: '',
      searchShow: false,
      results: []
    }
  },
  computed: {
    ...mapState('d2admin', {
      searchActive: state => state.search.active,
      searchHotkey: state => state.search.hotkey
    }),
    ...mapState('d2admin/search', [
      'hotkey',
      'pool'
    ])
  },
  methods: {
    handleClick () {
      // this.$emit('click')
      this.searchShow = !this.searchShow
      if (this.searchShow) {
        setTimeout(() => {
          if (this.$refs.input) {
            this.$refs.input.focus()
          }
          // 还原
          this.searchText = ''
          this.results = []
        }, 500)
      }
    },
    /**
     * @description 过滤选项 这个方法在每次输入框的值发生变化时会触发
     */
    querySearch (queryString, callback) {
      console.log('queryString', queryString)
      var pool = this.pool
      const results = this.query(queryString ? pool : [], queryString)
      this.results = results
      callback(results)
    },
    /**
     * @description 指定的数据源中根据指定的查询字符串过滤数据
     * @param {Object} pool 需要过滤的数据
     * @param {String} queryString 查询字符串
     */
    query (pool, queryString) {
      return new Fuse(pool, {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          'fullTitle',
          'path'
        ]
      })
        .search(queryString)
        .map(e => ({
          value: e.fullTitle,
          ...e
        }))
    },
    /**
     * @description 接收用户在下拉菜单中选中事件
     */
    handleSelect ({ path }) {
      // 如果用户选择的就是当前页面 就直接关闭搜索面板
      if (path === this.$route.path) {
        this.handleEsc()
        return
      }
      // 用户选择的是其它页面
      this.$nextTick(() => {
        this.handleMenuSelect(path)
        this.searchShow = false
        // 还原
        this.searchText = ''
        this.results = []
      })
    },
    handleBlur () {
      if (this.searchShow) {
        this.searchShow = false
        // 还原
        this.searchText = ''
        this.results = []
      }
    },
    /**
     * @augments 关闭输入框的下拉菜单
     */
    closeSuggestion () {
      if (this.$refs.input.activated) {
        this.$refs.input.suggestions = []
        this.$refs.input.activated = false
      }
    },
    /**
     * @augments 接收用户点击空白区域的关闭
     */
    handlePanelClick () {
      this.handleEsc()
    },
    /**
     * @augments 接收用户触发的关闭
     */
    handleEsc () {
      console.log('aaaaaa')
      this.closeSuggestion()
      // this.$nextTick(() => {
      //   this.$emit('close')
      // })
      if (this.searchShow) {
        this.searchShow = false
        // 还原
        this.searchText = ''
        this.results = []
      }
    }
  }
}
</script>
<style lang="scss">
.panel-search__autocomplete {
  .el-input__inner {
    border-radius: 16px;
  }
  .el-autocomplete-suggestion__wrap {
    max-height: 350px;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-search/index.vue"
}
</vue-filename-injector>
