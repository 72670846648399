<template>
  <!--编辑框禁用的突出颜色变化-->
  <div class="input_change_color">
    <el-input-number
      v-model="values"
      :disabled="disabled"
      :precision="2"
      :controls="false">
    </el-input-number>
  </div>
</template>
<script>

export default {
  name: 'd2-container-input',
  data () {
    return {
      values: this.value
    }
  },
  props: {
    value: {
      type: Number,
      default: () => 0
    },
    disabled: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss">
  .input_change_color .el-input.is-disabled .el-input__inner {
    color: #2f74ff;
    /*font-weight: bold;*/
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-input/index.vue"
}
</vue-filename-injector>
