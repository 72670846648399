<template>
  <el-select class="td_select"
             v-model="values"
             value-key="value"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :filter-method="filterMethod"
             @clear="clearAction"
             :loading="loading"
             @visible-change="visible_change"
             @change="handleChange">
    <el-option
        class="td_option"
        v-for="(item, index) in options"
        :key="index"
        :label="item.arap+ '  ' +item.arapNo"
        :value="{ value: item.arap, label: item.arapNo }"
        :disabled="item.disabled">
      <span style="float: left;"><i class="fa fa-gg-circle" :style="{'color':item.arapType==='收入'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.arap }}</span>
      <span style="float: left; margin-left: 20px; color: #8492a6; font-size: 13px;">{{ item.arapNo }}</span>
    </el-option>
  </el-select>
</template>
<script>
// 事故单 收支类型 编辑界面 码+名称
import { validatenull } from '@/tdcommon/validate'
import { getFeeType } from '@/api/tms/tmstransaction'

export default {
  name: 'd2-income-expenditure-edit-sg',
  data () {
    return {
      values: {},
      options: [],
      copyOptions: [],
      loading: false,
      mdmStrMark: ''
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    sourcetype: {
      type: String,
      default: () => ''
    },
    mdmstr: {
      type: String,
      default: () => ''
    },
    typestr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    visible_change (visible) {
      if (visible) {
        let type = this.$parent.elForm.model[this.typestr] // 单位类型（cus：客户、sup：供应商）
        this.loading = true
        getFeeType().then(response => {
          if (response.code === 0) {
            if (!validatenull(type)) {
              if (type === 'cus') {
                response.data.forEach(item => {
                  if (item.arapType === '收入') {
                    item.disabled = false
                  } else {
                    item.disabled = true
                  }
                })
                this.loading = false
                this.options = response.data.filter(item => item.arap !== 'CB12')
                this.copyOptions = response.data.filter(item => item.arap !== 'CB12')
              } else {
                response.data.forEach(item => {
                  if (item.arapType === '收入') {
                    item.disabled = true
                  } else {
                    item.disabled = false
                  }
                })
                this.loading = false
                this.options = response.data.filter(item => item.arap !== 'CB12')
                this.copyOptions = response.data.filter(item => item.arap !== 'CB12')
                // this.options = response.data
                // this.copyOptions = response.data
              }
            } else {
              this.loading = false
              this.options = response.data.filter(item => item.arap !== 'CB12')
              this.copyOptions = response.data.filter(item => item.arap !== 'CB12')
            }
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    // 自定义搜索
    async filterMethod (val) {
      this.options = []
      if (!validatenull(val)) {
        this.copyOptions.filter(item => {
          if (String(item.arapNo).includes(val) || String(item.arap.toLowerCase()).includes(val) || String(item.arap.toUpperCase()).includes(val)) {
            this.options.push(item)
          }
        })
      } else {
        this.options = this.copyOptions
      }
    },
    clearAction () {
      this.options = []
      this.options = this.copyOptions
    },
    handleChange (val) {
      this.values = { value: val.value, label: val.label }
      this.$emit('change', this.options.filter(item => item.arap === val.value))
    },
    getStartValues (val) {
      if (!validatenull(this.mdmstr)) {
        let model = this.$parent.elForm.model[this.mdmstr]
        if (!validatenull(model)) {
          this.values = Object.assign({ value: model, label: val })
        } else {
          this.values = Object.assign({ value: '', label: val })
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      // 编辑界面值是 val.label
      this.$emit('input', validatenull(val) ? '' : val.label)
    },
    value: function (val, oldVal) {
      if (!validatenull(this.options) && val !== oldVal) {
        this.getStartValues(val)
      }
    }
  },
  mounted () {
    this.visible_change(true)
    this.getStartValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-income-expenditure-edit-sg/index.vue"
}
</vue-filename-injector>
