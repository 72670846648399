<template>
    <el-select class="td_select"
               v-model="values"
               default-first-option
               filterable
               remote
               reserve-keyword
               :allow-create="allowCreate"
               :placeholder="placeholder"
               :clearable="clearable"
               :disabled="disabled"
               :remote-method="remoteMethod"
               @clear="clearAction"
               :loading="loading">
        <el-option
                class="td_option"
                v-for="item in options"
                :key="item.id"
                :label="item.customerName"
                :value="item.customerName"
                @click.native="handleSelect(item)">
        </el-option>
    </el-select>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
import { getCustomerProfile } from '@/api/basic/archives'
// 营销中心模块的 客户漏斗的客户名称 取客户档案
export default {
  name: 'd2-customer-profile',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    allowCreate: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      var searchStr = validatenull(query) ? '' : query
      getCustomerProfile(searchStr).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
      // this.$emit('change', '')
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-customer-profile/index.vue"
}
</vue-filename-injector>
