<template>
  <el-row :gutter="10">
    <el-col :span="12">
      <el-card style="height: 500px;overflow-y: auto;" shadow="hover">
        <div slot="header" class="clearfix">
          <i class="el-icon-s-custom"></i>
          <span style="margin-left: 5px;">认证信息</span>
        </div>
        <el-form size="mini" label-width="100px" :model="uploadData" :rules="rules" ref="uploadData">
          <el-row>
            <el-col :span="12">
              <el-form-item label="司机" prop="driverName">
                <label slot="label">司&nbsp;&nbsp;&nbsp;&nbsp;机</label>
                <el-input v-model="uploadData.driverName" :disabled="isDisabled" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车牌号" prop="carNo">
                <el-input v-model="uploadData.carNo" :disabled="isDisabled" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机" prop="phone">
                <label slot="label">手&nbsp;&nbsp;&nbsp;&nbsp;机</label>
                <el-input v-model="uploadData.phone" :disabled="isDisabled" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车长/型">
                <el-col :span="10"><el-select v-model="uploadData.carLen" clearable>
                  <el-option v-for="item in carLength_options" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select></el-col>
                <el-col :span="14"><el-select v-model="uploadData.carType" clearable>
                  <el-option v-for="item in carType_options" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select></el-col>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="行驶证">
                <el-input v-model="uploadData.drivingNo" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="驾驶证">
                <el-input v-model="uploadData.driverNo" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="卡号">
                <label slot="label">卡&nbsp;&nbsp;&nbsp;&nbsp;号</label>
                <el-input v-model="uploadData.bankAccount" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="户名">
                <label slot="label">户&nbsp;&nbsp;&nbsp;&nbsp;名</label>
                <el-input v-model="uploadData.accountName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="开户行">
                <el-input v-model="uploadData.bankName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-button v-if="this.updateFlag !== '1'" type="primary" size="mini" icon="el-icon-view" @click="approveClick">认证</el-button>
<!--                <el-button type="primary" size="mini" icon="el-icon-view" @click="approveClick" v-if="approveloading">认证</el-button>-->
<!--                <el-button type="success" size="mini" icon="el-icon-camera">拍照</el-button>-->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <img v-if="this.updateFlag === '1'" src="./authenticated.png"/>
                <img v-else src="./unauthorized.png"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card shadow="hover" style="height: 500px;overflow-y: auto;">
        <div slot="header" class="clearfix">
          <i class="el-icon-picture"></i>
          <span style="margin-left: 5px;">证件信息</span>
<!--          <el-button style="float: right;" type="primary" icon="el-icon-upload" size="mini" @click="uploadImageEvent">上传</el-button>-->
        </div>
        <el-row>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="cid1ImageUrl !== ''"
              action
              ref="cid1"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="cid1PicUpload"
              :on-success="handleCid1AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="cid1ImageUrl !== ''" :src="cid1ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="cid1ImageUrl === '' ? 'display: none;' : 'display: block;'" class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview('cid1')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove('cid1','身份证(正)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="cid1DialogVisible" append-to-body>
              <el-image width="100%" :src="cid1Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="">身份证(正)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="cid2ImageUrl !== ''"
              action
              ref="cid2"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="cid2PicUpload"
              :on-success="handleCid2AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="cid2ImageUrl" :src="cid2ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="cid2ImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('cid2')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('cid2','身份证(反)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="cid2DialogVisible" append-to-body>
              <el-image width="100%" :src="cid2Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini">身份证(反)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="driver1ImageUrl !== ''"
              action
              ref="driver"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="driverPicUpload"
              :on-success="handleDriver1AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="driver1ImageUrl" :src="driver1ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="driver1ImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('driver')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('driver','驾驶证(正)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="driver1DialogVisible" append-to-body>
              <el-image width="100%" :src="driver1Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="success">驾驶证(正)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="driver2ImageUrl !== ''"
              action
              ref="driver2"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="driver2PicUpload"
              :on-success="handleDriver2AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="driver2ImageUrl" :src="driver2ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="driver2ImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('driver2')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('driver2','驾驶证(副)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="driver2DialogVisible" append-to-body>
              <el-image width="100%" :src="driver2Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="success">驾驶证(副)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="driving1ImageUrl !== ''"
              action
              ref="driving1"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="driving1PicUpload"
              :on-success="handleDriving1AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="driving1ImageUrl" :src="driving1ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="driving1ImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('driving1')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('driving1','行驶证(正)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="driving1DialogVisible" append-to-body>
              <el-image width="100%" :src="driving1Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="warning">行驶证(正)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="driving2ImageUrl !== ''"
              action
              ref="driving2"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="driving2PicUpload"
              :on-success="handleDriving2AvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="driving2ImageUrl" :src="driving2ImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="driving2ImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('driving2')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('driving2','行驶证(副)')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="driving2DialogVisible" append-to-body>
              <el-image width="100%" :src="driving2Url" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="warning">行驶证(副)</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="carimageImageUrl !== ''"
              action
              ref="carimage"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="carimagePicUpload"
              :on-success="handleCarimageAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="carimageImageUrl" :src="carimageImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="carimageImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('carimage')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('carimage','车辆照')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="carimageDialogVisible" append-to-body>
              <el-image width="100%" :src="carimageUrl" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="info">车辆照</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="bankcardImageUrl !== ''"
              action
              ref="bankcard"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="bankcardPicUpload"
              :on-success="handleBankcardAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="bankcardImageUrl" :src="bankcardImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="bankcardImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('bankcard')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('bankcard','银行卡')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="bankcardDialogVisible" append-to-body>
              <el-image width="100%" :src="bankcardUrl" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="info">银行卡</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="carsyxImageUrl !== ''"
              action
              ref="carsyx"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="carsyxPicUpload"
              :on-success="handleCarsyxAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="carsyxImageUrl" :src="carsyxImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="carsyxImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('carsyx')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('carsyx','商业险')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="carsyxDialogVisible" append-to-body>
              <el-image width="100%" :src="carsyxUrl" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="danger">商业险</el-tag>
          </el-col>
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
              class="upload-car-certification"
              :disabled="carqzxImageUrl !== ''"
              action
              ref="carqzx"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :http-request="carqzxPicUpload"
              :on-success="handleCarqzxAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="carqzxImageUrl" :src="carqzxImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="carqzxImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('carqzx')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('carqzx','强制险')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="carqzxDialogVisible" append-to-body>
              <el-image width="100%" :src="carqzxUrl" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="danger">强制险</el-tag>
          </el-col>
          <!--道路许可证-->
          <el-col :span="6" class="avatar-uploader-span">
            <el-upload
                class="upload-car-certification"
                :disabled="roadPermitImageUrl !== ''"
                action
                ref="roadPermit"
                :show-file-list="false"
                accept="image/jpeg,image/gif,image/png"
                :http-request="roadPermitPicUpload"
                :on-success="handleRoadPermitAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <!--图片-->
              <img v-if="roadPermitImageUrl" :src="roadPermitImageUrl" class="avatar">
              <!--加号-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--预览、删除按钮-->
              <div :style="roadPermitImageUrl === '' ? 'display: none;' : 'display: block;'"
                   class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview('roadPermit')">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-preview" @click="handlePictureCardRemove
('roadPermit','道路许可证')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </el-upload>
            <!--预览图片弹出框-->
            <el-dialog :visible.sync="roadPermitDialogVisible" append-to-body>
              <el-image width="100%" :src="roadPermitUrl" alt=""></el-image>
            </el-dialog>
            <el-tag size="mini" type="danger">道路许可证</el-tag>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import store from '@/store/index'
import axios from 'axios'
import { getdict } from '@api/admin/dict'
import { carAuthApprove } from '@/api/tms/tmshy'
import { getDriverMsg } from '@/api/basic/carinfo'
import { validatenull } from '@/tdcommon/validate'
// import { yddelObjs } from '@/api/tms/tmsyd'
// import { getListId } from '@/tdcommon/beeutil'
// 车辆档案：车辆认证

export default {
  name: 'd2-container-car-registration',
  data () {
    return {
      fileList: [],
      limitUpload: 10,
      filelistdata: {},
      uploadData: {},
      rules: {
        driverName: [{ required: true, message: '请输入司机姓名', trigger: 'blur' }],
        carNo: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        phone: [{ required: true, validator: this.validatePhone, trigger: 'change' }]
      },
      uploadPhone: '',
      updateFlag: '',
      // headers: {
      //   'Authorization': 'Bearer ' + store.getters.access_token,
      //   'TENANT-ID': 1
      // },
      uploadForm: [],
      activeName: '1',
      // srcList: [
      //   'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
      //   'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      // ],
      srcList: [],
      updateNo: '',
      saveAliType: '',
      dialogVisible: false,
      // imageUrl: [
      //   { fileDesc: '身份证(正)', objectNo: '', ossfileName: '', ossitemType: 'cid1', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '身份证(反)', objectNo: '', ossfileName: '', ossitemType: 'cid2', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '驾驶证(正)', objectNo: '', ossfileName: '', ossitemType: 'driver', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '驾驶证(副)', objectNo: '', ossfileName: '', ossitemType: 'driver2', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '行驶证(正)', objectNo: '', ossfileName: '', ossitemType: 'driving1', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '行驶证(副)', objectNo: '', ossfileName: '', ossitemType: 'driving2', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '银行卡', objectNo: '', ossfileName: '', ossitemType: 'bankcard', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '商业险', objectNo: '', ossfileName: '', ossitemType: 'carsyx', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '强制险', objectNo: '', ossfileName: '', ossitemType: 'carqzx', osstype: '', uploadUser: '', url: '' },
      //   { fileDesc: '车辆照', objectNo: '', ossfileName: '', ossitemType: 'carimage', osstype: '', uploadUser: '', url: '' }
      // ],
      imageDataUrl: [],
      carLength_options: [], // 车长字典项
      carType_options: [], // 车型字典项
      imageDialogVisible: false,
      cid1ImageUrl: '',
      cid1Url: '',
      cid1DialogVisible: false,
      cid1Type: '',

      cid2ImageUrl: '',
      cid2Url: '',
      cid2DialogVisible: false,
      cid2Type: '',

      driver1ImageUrl: '',
      driver1Url: '',
      driver1DialogVisible: false,
      driver1Type: '',

      driver2ImageUrl: '',
      driver2Url: '',
      driver2DialogVisible: false,
      driver2Type: '',

      driving1ImageUrl: '',
      driving1Url: '',
      driving1DialogVisible: false,
      driving1Type: '',

      driving2ImageUrl: '',
      driving2Url: '',
      driving2DialogVisible: false,
      driving2Type: '',

      carimageImageUrl: '',
      carimageUrl: '',
      carimageDialogVisible: false,
      carimageType: '',

      bankcardImageUrl: '',
      bankcardUrl: '',
      bankcardDialogVisible: false,
      bankcardType: '',

      carsyxImageUrl: '',
      carsyxUrl: '',
      carsyxDialogVisible: false,
      carsyxType: '',

      carqzxImageUrl: '',
      carqzxUrl: '',
      carqzxDialogVisible: false,
      carqzxType: '',

      roadPermitImageUrl: '',
      roadPermitUrl: '',
      roadPermitDialogVisible: false,
      roadPermitType: '',

      approveloading: true, // 认证按钮默认显示状态,
      isDisabled: true,
      editStatus: ''
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    uploadVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    // 修改手机号码判断验证
    validatePhone (rule, value, callback) {
      if (validatenull(value)) {
        callback(new Error('请输入手机号码'))
      } else if (!(/^1[0-9]{10}$/.test(value))) {
        callback(new Error('手机号不符合规则'))
      } else {
        getDriverMsg(value).then(response => {
          if (response.code === 0) {
            if (!validatenull(response.data)) {
              callback(new Error('手机号已经存在'))
            } else {
              callback()
            }
          }
        })
      }
    },
    init () {
      // 获取 车长 下拉加载项
      getdict('car_size').then(response => {
        let listItem = []
        response.data.forEach(item => {
          let option = {}
          option.label = item.label
          option.value = item.value
          listItem.push(option)
        })
        this.carLength_options = listItem
      })
      // 获取 车型 下拉加载项
      getdict('vehicle_modle').then(response => {
        let listItem = []
        response.data.forEach(item => {
          let option = {}
          option.label = item.label
          option.value = item.value
          listItem.push(option)
        })
        this.carType_options = listItem
      })
    },
    // 认证
    approveClick () {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            if (this.cid1ImageUrl === '' || this.cid2ImageUrl === '' || this.driver1ImageUrl === '' || this.driver2ImageUrl === '' || this.driving1ImageUrl === '' || this.driving2ImageUrl === '' || this.bankcardImageUrl === '') { // 身份证（正）
              this.$alert('"身份证(正/反)、驾驶证(正/副)、行驶证(正/副)、银行卡"必须上传！才能进行认证！', '提示', {
                confirmButtonText: '确定',
                type: 'warning'
              })
            } else {
              let uploadData = {
                accountName: this.uploadData.accountName,
                bankAccount: this.uploadData.bankAccount,
                bankName: this.uploadData.bankName,
                carLen: this.uploadData.carLen,
                carNo: this.uploadData.carNo,
                carType: this.uploadData.carType,
                driverName: this.uploadData.driverName,
                driverNo: this.uploadData.driverNo,
                drivingNo: this.uploadData.drivingNo,
                flagAuth: '0',
                images: [],
                phone: this.uploadData.phone
              }
              carAuthApprove(uploadData).then(response => {
                if (response.code === 0) {
                  this.approveloading = false
                  this.updateFlag = '1'
                  this.$alert('认证成功', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: action => {
                    }
                  })
                } else {
                  this.$alert(response.msg, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: action => {
                    }
                  })
                }
              })
            }
          }
        })
      } else {
        if (this.updateFlag === '0') {
          if (this.cid1ImageUrl === '' || this.cid2ImageUrl === '' || this.driver1ImageUrl === '' || this.driver2ImageUrl === '' || this.driving1ImageUrl === '' || this.driving2ImageUrl === '' || this.bankcardImageUrl === '') { // 身份证（正）
            this.$alert('"身份证(正/反)、驾驶证(正/副)、行驶证(正/副)、银行卡"必须上传！才能进行认证！', '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            })
          } else {
            carAuthApprove(this.uploadData).then(response => {
              if (response.code === 0) {
                this.approveloading = false
                this.updateFlag = '1'
                this.$alert('认证成功', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  callback: action => {
                  }
                })
              } else {
                this.$alert(response.msg, '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  callback: action => {
                  }
                })
              }
            })
          }
        } else {
          this.$alert('已认证，不能重复认证！', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
            }
          })
        }
      }
    },
    // 获取认证信息
    getDriverDetails (hyNo) {
      getDriverMsg(hyNo).then(response => {
        if (response.code === 0) {
          this.uploadData = response.data
          this.uploadPhone = response.data.phone
          this.updateFlag = response.data.flagAuth
          this.getImageEvent(response.data.images) // 调取获取图片事件
        } else {
          this.$message.error(response.msg)
        }
      })
    },
    // 获取图片事件
    getImageEvent (images) {
      images.forEach(res => {
        if (res.ossitemType === 'cid1') { // 身份证（正）
          this.cid1ImageUrl = res.url
          this.cid1Url = this.cid1ImageUrl
          this.cid1Type = res.ossitemType
          // this.cid1DialogVisible = true
        } else if (res.ossitemType === 'cid2') { // 身份证（反）
          this.cid2ImageUrl = res.url
          this.cid2Url = this.cid2ImageUrl
          // this.cid2DialogVisible = true
        } else if (res.ossitemType === 'driver') { // 驾驶证（正）
          this.driver1ImageUrl = res.url
          this.driver1Url = this.driver1ImageUrl
          // this.driver1DialogVisible = true
        } else if (res.ossitemType === 'driver2') { // 驾驶证（副）
          this.driver2ImageUrl = res.url
          this.driver2Url = this.driver2ImageUrl
          // this.driver2DialogVisible = true
        } else if (res.ossitemType === 'driving1') { // 行驶证（正）
          this.driving1ImageUrl = res.url
          this.driving1Url = this.driving1ImageUrl
          // this.driving1DialogVisible = true
        } else if (res.ossitemType === 'driving2') { // 行驶证（副）
          this.driving2ImageUrl = res.url
          this.driving2Url = this.driving2ImageUrl
          // this.driving2DialogVisible = true
        } else if (res.ossitemType === 'carimage') { // 车辆照
          this.carimageImageUrl = res.url
          this.carimageUrl = this.carimageImageUrl
          // this.carimageDialogVisible = true
        } else if (res.ossitemType === 'bankcard') { // 银行卡
          this.bankcardImageUrl = res.url
          this.bankcardUrl = this.bankcardImageUrl
          // this.bankcardDialogVisible = true
        } else if (res.ossitemType === 'carsyx') { // 商业险
          this.carsyxImageUrl = res.url
          this.carsyxUrl = this.carsyxImageUrl
          // this.carsyxDialogVisible = true
        } else if (res.ossitemType === 'carqzx') { // 强制险
          this.carqzxImageUrl = res.url
          this.carqzxUrl = this.carqzxImageUrl
          // this.carqzxDialogVisible = true
        } else if (res.ossitemType === 'roadPermit') { // 道路许可证
          this.roadPermitImageUrl = res.url
          this.roadPermitUrl = this.roadPermitImageUrl
          // this.roadPermitDialogVisible = true
        }
      })
    },
    // 身份证（正）上传
    async cid1PicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'cid1')
            formdata.append('desc', '身份证（正）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.cid1ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.cid1ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'cid1')
        formdata.append('desc', '身份证（正）')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.cid1ImageUrl = ''
        })
      }
    },
    async cid2PicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'cid2')
            formdata.append('desc', '身份证（反）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.cid2ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.cid2ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadData.phone)
        formdata.append('imageType', 'cid2')
        formdata.append('desc', '身份证（反）')
        formdata.append('refNo', this.uploadData.phone)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
            this.isDisabled = true
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.cid2ImageUrl = ''
        })
      }
    },
    async driverPicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'driver')
            formdata.append('desc', '驾驶证（正）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.driver1ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.driver1ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'driver')
        formdata.append('desc', '驾驶证（正）')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.driver1ImageUrl = ''
        })
      }
    },
    async driver2PicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'driver2')
            formdata.append('desc', '驾驶证（副）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.driver2ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.driver2ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'driver2')
        formdata.append('desc', '驾驶证（副）')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
            this.isDisabled = true
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.driver2ImageUrl = ''
        })
      }
    },
    async driving1PicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'driving1')
            formdata.append('desc', '行驶证（正）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.driving1ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.driving1ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'driving1')
        formdata.append('desc', '行驶证（正）')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.driving1ImageUrl = ''
        })
      }
    },
    async driving2PicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'driving2')
            formdata.append('desc', '行驶证（副）')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.driving2ImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.driving2ImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'driving2')
        formdata.append('desc', '行驶证（副）')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.driving2ImageUrl = ''
        })
      }
    },
    async carimagePicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'carimage')
            formdata.append('desc', '车辆照')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.carimageImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.carimageImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'carimage')
        formdata.append('desc', '车辆照')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.carimageImageUrl = ''
        })
      }
    },
    async bankcardPicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'bankcard')
            formdata.append('desc', '银行卡')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.bankcardImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.bankcardImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'bankcard')
        formdata.append('desc', '银行卡')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.bankcardImageUrl = ''
        })
      }
    },
    async carsyxPicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'carsyx')
            formdata.append('desc', '商业险')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.carsyxImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.carsyxImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'carsyx')
        formdata.append('desc', '商业险')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.carsyxImageUrl = ''
        })
      }
    },
    async carqzxPicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'carqzx')
            formdata.append('desc', '强制险')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.carqzxImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.carqzxImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'carqzx')
        formdata.append('desc', '强制险')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.carqzxImageUrl = ''
        })
      }
    },
    async roadPermitPicUpload (file) {
      if (this.editStatus === 'add') {
        this.$refs.uploadData.validate((valid) => {
          if (valid) {
            var formdata = new FormData()
            formdata.append('file', file.file)
            formdata.append('phone', this.uploadData.phone)
            formdata.append('imageType', 'roadPermit')
            formdata.append('desc', '道路许可证')
            formdata.append('refNo', this.uploadData.phone)
            axios.post('/tms/auth/uploadImage', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + store.getters.access_token,
                'TENANT-ID': 1
              }
            }).then(res => {
              if (res.data.data.code === 0) {
                this.$message.success('上传成功！')
                this.isDisabled = true
              } else {
                this.$message.error(res.data.data.msg)
              }
            }).catch(() => { // 上传失败清空图片
              this.roadPermitImageUrl = ''
            })
          } else {
            this.$alert('验证不通过,请检查司机、车牌号、手机号！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.roadPermitImageUrl = ''
              }
            })
          }
        })
      } else {
        var formdata = new FormData()
        formdata.append('file', file.file)
        formdata.append('phone', this.uploadPhone)
        formdata.append('imageType', 'roadPermit')
        formdata.append('desc', '道路许可证')
        formdata.append('refNo', this.updateNo)
        axios.post('/tms/auth/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.getters.access_token,
            'TENANT-ID': 1
          }
        }).then(res => {
          if (res.data.data.code === 0) {
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.data.data.msg)
          }
        }).catch(() => { // 上传失败清空图片
          this.roadPermitImageUrl = ''
        })
      }
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    handleCid1AvatarSuccess (res, file) { // 身份证（正） cid1
      this.cid1ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'cid1'
    },
    handleCid2AvatarSuccess (res, file) { // 身份证（反） cid2
      this.cid2ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'cid2'
    },
    handleDriver1AvatarSuccess (res, file) { // 驾驶证（正） driver1
      this.driver1ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'driver1'
    },
    handleDriver2AvatarSuccess (res, file) { // 驾驶证（副） driver2
      this.driver2ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'driver2'
    },
    handleDriving1AvatarSuccess (res, file) { // 行驶证（正） driving1
      this.driving1ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'driving1'
    },
    handleDriving2AvatarSuccess (res, file) { // 行驶证（反） driving2
      this.driving2ImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'driving2'
    },
    handleCarimageAvatarSuccess (res, file) { // 车辆照 carimage
      this.carimageImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'carimage'
    },
    handleBankcardAvatarSuccess (res, file) { // 银行卡 bankcard
      this.bankcardImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'bankcard'
    },
    handleCarsyxAvatarSuccess (res, file) { // 商业险 carsyx
      this.carsyxImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'carsyx'
    },
    handleCarqzxAvatarSuccess (res, file) { // 强制险 carqzx
      this.carqzxImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'carqzx'
    },
    handleRoadPermitAvatarSuccess (res, file) { // 道路许可证 roadPermit
      this.roadPermitImageUrl = URL.createObjectURL(file.raw)
      this.saveAliType = 'roadPermit'
    },
    // 根据图片类型 预览图片
    handlePictureCardPreview (type, url) {
      this.imageDialogVisible = true
      if (type === 'cid1') { // 身份证（正）
        this.cid1Url = this.cid1ImageUrl
        this.cid1DialogVisible = true
      } else if (type === 'cid2') { // 身份证（反）
        this.cid2Url = this.cid2ImageUrl
        this.cid2DialogVisible = true
      } else if (type === 'driver') { // 驾驶证（正）
        this.driver1Url = this.driver1ImageUrl
        this.driver1DialogVisible = true
      } else if (type === 'driver2') { // 驾驶证（副）
        this.driver2Url = this.driver2ImageUrl
        this.driver2DialogVisible = true
      } else if (type === 'driving1') { // 行驶证（正）
        this.driving1Url = this.driving1ImageUrl
        this.driving1DialogVisible = true
      } else if (type === 'driving2') { // 行驶证（副）
        this.driving2Url = this.driving2ImageUrl
        this.driving2DialogVisible = true
      } else if (type === 'carimage') { // 车辆照
        this.carimageUrl = this.carimageImageUrl
        this.carimageDialogVisible = true
      } else if (type === 'bankcard') { // 银行卡
        this.bankcardUrl = this.bankcardImageUrl
        this.bankcardDialogVisible = true
      } else if (type === 'carsyx') { // 商业险
        this.carsyxUrl = this.carsyxImageUrl
        this.carsyxDialogVisible = true
      } else if (type === 'carqzx') { // 强制险
        this.carqzxUrl = this.carqzxImageUrl
        this.carqzxDialogVisible = true
      } else if (type === 'roadPermit') { // 道路许可证
        this.roadPermitUrl = this.roadPermitImageUrl
        this.roadPermitDialogVisible = true
      }
    },
    // 根据图片类型 删除图片
    handlePictureCardRemove (type, typeinfo) {
      if (this.updateFlag === '0') {
        this.$confirm(`是否确认移除" ${typeinfo} "图片？如需覆盖请上传新图片`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (type === 'cid1') { // 身份证（正）
            this.cid1ImageUrl = ''
          } else if (type === 'cid2') { // 身份证（反）
            this.cid2ImageUrl = ''
          } else if (type === 'driver') { // 驾驶证（正）
            this.driver1ImageUrl = ''
          } else if (type === 'driver2') { // 驾驶证（副）
            this.driver2ImageUrl = ''
          } else if (type === 'driving1') { // 行驶证（正）
            this.driving1ImageUrl = ''
          } else if (type === 'driving2') { // 行驶证（副）
            this.driving2ImageUrl = ''
          } else if (type === 'carimage') { // 车辆照
            this.carimageImageUrl = ''
          } else if (type === 'bankcard') { // 银行卡
            this.bankcardImageUrl = ''
          } else if (type === 'carsyx') { // 商业险
            this.carsyxImageUrl = ''
          } else if (type === 'carqzx') { // 强制险
            this.carqzxImageUrl = ''
          } else if (type === 'roadPermit') { // 道路许可证
            this.roadPermitImageUrl = ''
          }
        }).then(() => {
          this.$message({
            showClose: true,
            message: '移除成功！请上传新图片',
            type: 'success'
          })
        })
      } else {
        this.$alert('已认证，不能再删除已上传的图片！', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
          }
        })
      }
    },
    // 判断导入的格式是不是xls、xlsx，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/gif' || 'image/png'
      // var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const extension = testmsg === 'xls'
      // const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 2.8
      // if (!extension && !extension2) {
      //   this.$message.error('导入文件只支持 xls 或者 xlsx 格式！')
      //   return false
      // }
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2.8MB!')
        // this.$alert('上传图片大小不能超过 2.8MB！请重新上传图片！', '提示', {
        //   confirmButtonText: '确定'
        // })
      }
      // return (extension || extension2) && isLt2M
      return isIMAGE && isLt2M
    },
    // 获取新增状态
    getAddStatus () {
      this.isDisabled = false
      this.editStatus = 'add'
      this.uploadData = {}
      this.updateFlag = '0'
    },
    // 获取编辑状态
    getEditStatus () {
      this.isDisabled = true
      this.editStatus = 'edit'
    },
    // 获取客户编号
    getupdateNo (cusNo) {
      this.updateNo = cusNo
      this.$set(this.uploadData, 'objectNo', cusNo)
    },
    // 关闭时清空
    clearSrcList () {
      if (this.cid1ImageUrl === '' || this.cid2ImageUrl === '' || this.driver1ImageUrl === '' || this.driver2ImageUrl === '' || this.driving1ImageUrl === '' || this.driving2ImageUrl === '' || this.bankcardImageUrl === '') { // 身份证（正）
        this.$confirm('"身份证(正/反)、驾驶证(正/副)、行驶证(正/副)、银行卡"为必须上传项！是否确认不上传并关闭此页面？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('getuploadVisible')
          this.cid1ImageUrl = ''
          this.cid2ImageUrl = ''
          this.driver1ImageUrl = ''
          this.driver2ImageUrl = ''
          this.driving1ImageUrl = ''
          this.driving2ImageUrl = ''
          this.carimageImageUrl = ''
          this.bankcardImageUrl = ''
          this.carsyxImageUrl = ''
          this.carqzxImageUrl = ''
          this.roadPermitImageUrl = ''
        })
      } else {
        this.$emit('getuploadVisible')
        this.cid1ImageUrl = ''
        this.cid2ImageUrl = ''
        this.driver1ImageUrl = ''
        this.driver2ImageUrl = ''
        this.driving1ImageUrl = ''
        this.driving2ImageUrl = ''
        this.carimageImageUrl = ''
        this.bankcardImageUrl = ''
        this.carsyxImageUrl = ''
        this.carqzxImageUrl = ''
        this.roadPermitImageUrl = ''
      }
    }
  },
  watch: {
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss">
  .upload-car-certification .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .el-upload-list__item-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0,0,0,.5);
      transition: opacity .3s;
      line-height: 100px;
    }
  }
  /*鼠标进入边框变色、鼠标变手指、背景色加深*/
  .upload-car-certification .el-upload:hover {
    border-color: #409EFF;
    .el-upload-list__item-actions {
      cursor: pointer;
      opacity: 1;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .avatar-uploader-span {
    text-align: center;
    margin-bottom: 8px;
  }
  .el-upload-list__item-preview {
    margin: 0 10px 0 10px;
  }
  /*.hide .el-upload--picture-card {*/
  /*  display: none;*/
  /*}*/
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-car-registration/index.vue"
}
</vue-filename-injector>
