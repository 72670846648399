const getters = {
  userInfo: state => state.d2admin.account.userInfo,
  theme: state => state.d2admin.common.theme,
  themeName: state => state.d2admin.common.themeName,
  isShade: state => state.d2admin.common.isShade,
  isCollapse: state => state.d2admin.common.isCollapse,
  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : false,
  screen: state => state.d2admin.common.screen,
  isLock: state => state.d2admin.common.isLock,
  isFullScren: state => state.d2admin.common.isFullScren,
  lockPasswd: state => state.d2admin.common.lockPasswd,
  access_token: state => state.d2admin.account.access_token,
  refresh_token: state => state.d2admin.account.refresh_token,
  expires_in: state => state.d2admin.account.expires_in,
  roles: state => state.d2admin.account.roles,
  permissions: state => state.d2admin.account.permissions,
  menu: state => state.d2admin.account.menu,
  menuAll: state => state.d2admin.account.menuAll,
  keepAlive: state => state.d2admin.page.keepAlive,
  grayActive: state => state.d2admin.gray.active,
  transitionActive: state => state.d2admin.transition.active,
  asideCollapse: state => state.d2admin.menu.asideCollapse,
  userRoutes: state => state.d2admin.account.userRoutes
}
export default getters
