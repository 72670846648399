import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsSurvey/page',
    method: 'post',
    data: query
  })
}

/**
 * 根据id查询立项报告信息
 */
export function getObj (id) {
  return request({
    url: '/tms/tmsSurvey/selectSurveyMessage/' + id,
    method: 'get'
  })
}
/**
 * 新增保存
 */
export function addObj (obj) {
  return request({
    url: '/tms/tmsSurvey/insertTmsSurvey',
    method: 'post',
    data: obj
  })
}
/**
 * 修改保存
 */
export function putObj (obj) {
  return request({
    url: '/tms/tmsSurvey/updateTmsSurvey',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsSurvey/deleteTmsSurvey',
    method: 'delete',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function cusExamine (ids) {
  return request({
    url: '/tms/tmsSurvey/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function cusUnExamine (ids) {
  return request({
    url: '/tms/tmsSurvey/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行拒审
 */
export function refuseExamine (ids) {
  return request({
    url: '/tms/tmsSurvey/unExamineFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 调研报告上传产品图片
 */
export function uploadProductPicture (obj) {
  return request({
    url: '/tms/ossobject/uploadProductPicture',
    method: 'post',
    data: obj
  })
}
/**
 * 调研报告上传装车封面图片
 */
export function uploadCoverPicture (obj) {
  return request({
    url: '/tms/ossobject/uploadCoverPicture',
    method: 'post',
    data: obj
  })
}
/**
 * 调研报告上传招标文件
 */
export function uploadBiddingDocuments (obj) {
  return request({
    url: '/tms/ossobject/uploadTenderDocument',
    method: 'post',
    data: obj
  })
}
/**
 * 客户漏斗通过点击调研报告单号直接跳转查看对应调研报告
 */
export function toResearch (obj) {
  return request({
    url: '/tms/tmsSurvey/selectWhetherTmsSurvey',
    method: 'post',
    params: obj
  })
}
/**
 * 客户漏斗通过调研报告单号查询调研报告是否审核
 */
export function selectWhetherGenerateReport (obj) {
  return request({
    url: '/tms/tmsSurvey/selectWhetherGenerateReport',
    method: 'post',
    params: obj
  })
}
