// Element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// flex 布局库
import 'flex.css'
// 组件
import '@/components'
// svg 图标
import '@/assets/svg-icons'
// 国际化
import i18n from '@/i18n.js'

// 功能插件
import pluginError from '@/plugin/error'
import pluginLog from '@/plugin/log'
import pluginOpen from '@/plugin/open'

export default {
  async install (Vue, options) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    // https://cn.vuejs.org/v2/api/#productionTip
    Vue.config.productionTip = false
    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV
    // 当前的 baseUrl
    Vue.prototype.$baseUrl = process.env.BASE_URL
    // 当前版本
    Vue.prototype.$version = process.env.VUE_APP_VERSION
    // 构建时间
    Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME
    // Element
    Vue.use(ElementUI, {
      i18n: (key, value) => i18n.t(key, value)
    })
    // 插件
    Vue.use(pluginError)
    Vue.use(pluginLog)
    Vue.use(pluginOpen)
    // 数字自增
    Vue.directive('nums-increment', {
      bind (el, binding, vnode, oldVnode) {
        const when = el.getAttribute('time') || 300 // 执行总时间 默认300
        const their = el.getAttribute('their') || 'figure' // 类型
        let code = 0 // 默认值
        let come = Math.round(binding.value / when) // 自增值

        if (come <= 0 && binding.value > 0) come = 1

        const Time = setInterval(() => {
          let sub = code
          if (code > binding.value) {
            sub = binding.value
            clearInterval(Time) // 关闭当前定时器
          }
          // mone(金额类型) figure(默认数字类型)
          switch (their) {
            case 'money':
              el.innerHTML = String(sub).replace(/([0-9])(?=([0-9]{3})+$)/g, '$1,')
              break
            case 'figure':
              el.innerHTML = sub
              break
          }
          code += come
        }, 10)
      }
    })
    // v-loadmore: el-table无限流动加载
    Vue.directive('loadMore', {
      bind (el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector('.el-table__body-wrapper')
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /*
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          let sign = 100 // 定义默认的向上滚向下滚的边界
          const CONDITION = ((this.scrollHeight - this.scrollTop === this.clientHeight) &&
            this.scrollTop > sign)// 注意: && this.scrollTop

          if (this.scrollTop > sign) {
            sign = this.scrollTop
          }
          if (this.scrollTop < sign) {
            sign = this.scrollTop
          }
          if (CONDITION) {
            binding.value()
          }
        })
      }
    })
  }
}
