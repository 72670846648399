<template>
  <div>
    <el-tooltip
        effect="dark"
        content="操作手册"
        placement="bottom">
      <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
        <d2-icon name="file-text-o" style="font-size: 18px;"/>
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    handleClick () {
      // this.$emit('click')
      window.open('http://doc.56tms.com/web/#/5/23')
    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-document-route/index.vue"
}
</vue-filename-injector>
