<template>
  <el-row :gutter="10">
    <el-col :span="10">
      <el-card style="height: 500px;overflow-y: auto;" shadow="hover">
        <el-form size="mini" label-width="100px">
          <el-form-item label="单据类型">
            <el-input v-model="uploadData.module" disabled></el-input>
          </el-form-item>
          <el-form-item label="编号">
            <label slot="label">编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</label>
            <el-input v-model="uploadData.objectNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="文件说明">
            <el-input v-model="uploadData.fileDesc"></el-input>
          </el-form-item>
          <el-form-item label-width="80px">
            <el-upload
                class="upload-ali-demo"
                ref="refUploadAli"
                action
                :data="uploadData"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :auto-upload="false"
                accept=".jpg,.jpeg,.png,.pdf,.gif,.xls,.xlsx,.doc,.docx,.avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4,.mp3,.wma,.flac,.ape,.wav,.aac,.m4a"
                :before-upload="beforeAvatarUpload"
                multiple
                :on-exceed="handleExceed"
                :on-change="handleChange"
                :on-success="onSuccess"
                :on-error="onError"
                list-type="picture"
                :file-list="fileList">
              <!--:http-request="picUpload"-->
              <el-button slot="trigger" size="mini" type="primary" :style="editStatus ? 'display:none' : ''" icon="el-icon-folder-opened">选择文件</el-button>
              <el-button :style="editStatus ? 'display:none;' : 'margin-left: 10px;'" size="mini" type="success" @click="submitUpload" :loading="loading">开始上传</el-button>
              <el-button :style="updateType === 'researchReport' ? 'margin-left: 10px;' : 'display:none;'" size="mini" type="info" @click="bsybgDownload" :loading="loading">下载模板</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <template v-if="editStatus">
          <video id="videoMark" width="400px" height="250px" autoplay="autoplay"></video>
          <canvas id="canvasMark" style="display:none" width="3742" height="2806"></canvas>
        </template>
      </el-card>
    </el-col>
    <el-col :span="14">
      <el-card shadow="hover" style="height: 500px;overflow-y: auto;" class="uploadali-el-card-style">
        <div slot="header" class="clearfix">
            <el-button style="float: right;padding: 3px 0;font-size: 13px;" type="text" @click="batch_download">批量下载</el-button>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="picture">
            <template slot="title">
              图片 Picture<i class="header-icon el-icon-info"></i>
            </template>
            <div class="uploadImage" v-for="(item, index) in srcList" :key="item.url">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="item.url"
                  :preview-src-list="listString(srcList)">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <!-- 遮罩层 -->
              <div class="optionMark">
                <div class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview">
                    <i class="el-icon-delete" @click="val => handlePictureCardRemove(item, index, val)"></i>
                  </span>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="file">
            <template slot="title">
              文件 File<i class="header-icon el-icon-info"></i>
            </template>
            <div v-for="(item, index) in fileSrcList" :key="item.url" class="file_download">
              <el-col :span="18">
                <div style="align-items: center;">
                  <img src="./file.png" alt="" style="width: 28px;">
                  <span>{{item.fileName}}</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div style="margin-top: 18px; margin-right: 0px;">
                  <el-button class="file_download_delete" style="margin-right: 10px;" type="success" circle size="mini" icon="el-icon-document" title="预览" @click="val => handleFilePreview(item, index, val)"></el-button>
                  <el-button class="file_download_delete" style="margin-right: 10px;" type="primary" circle size="mini" icon="el-icon-download" title="下载" @click="val => handleFileDownload(item, index, val)"></el-button>
                  <el-button class="file_download_delete" type="danger" circle size="mini" icon="el-icon-delete" title="删除" @click="val => handleFileDelete(item, index, val)"></el-button>
                </div>
              </el-col>
            </div>
          </el-collapse-item>
          <el-collapse-item name="video">
            <template slot="title">
              音频 Music/视频 Video<i class="header-icon el-icon-info"></i>
            </template>
            <div v-for="(item, index) in videoSrcList" :key="item.url" class="file_download">
              <div v-if="item.fileType === 'video'">
                <el-col :span="20">
                  <img src="./video.png" alt="" style="width: 30px;">
                  <span>{{item.fileName}}</span>
                </el-col>
                <el-col :span="4">
                  <div style="margin-top: 18px; margin-right: 0px;">
                    <el-button class="file_download_delete" style="margin-right: 10px;" type="primary" circle size="mini" icon="el-icon-download" title="下载" @click="val => handleVideoDownload(item, index, val)"></el-button>
                    <el-button class="file_download_delete" type="danger" circle size="mini" icon="el-icon-delete" title="删除" @click="val => handleVideoDelete(item, index, val)"></el-button>
                  </div>
                </el-col>
              </div>
              <div v-if="item.fileType === 'music'">
                <el-col :span="20">
                  <img src="./music.png" alt="" style="width: 27px;margin-left: 2px;">
                  <span>{{item.fileName}}</span>
                </el-col>
                <el-col :span="4">
                  <div style="margin-top: 18px; margin-right: 0px;">
                    <el-button class="file_download_delete" style="margin-right: 10px;" type="primary" circle size="mini" icon="el-icon-download" title="下载" @click="val => handleVideoDownload(item, index, val)"></el-button>
                    <el-button class="file_download_delete" type="danger" circle size="mini" icon="el-icon-delete" title="删除" @click="val => handleVideoDelete(item, index, val)"></el-button>
                  </div>
                </el-col>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import store from '@/store/index'
import {
  uploadAli,
  delOssData,
  getOssObjectList
} from '@/api/basic/customer'
import { aliBatchSalesDownload } from '@api/tms/accfp'
import { uploadAliResearch, wordToPDF } from '@api/tms/tmsquotation'
import axios from 'axios'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-uploadAli',
  data () {
    return {
      loading: false,
      fileList: [],
      fileListMsg: [],
      activeNames: ['1'],
      formData: [],
      resultData: [],
      totalNumbers: 0,
      importNumbers: 0,
      filelistdata: {},
      uploadData: { module: this.customerType },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      uploadForm: [],
      activeName: 'file',
      srcList: [],
      fileSrcList: [],
      videoSrcList: [],
      delOssId: [],
      updateNo: '',
      updateType: '',
      uploadStatus: false,
      editStatus: false, // 当前状态
      auditStatus: '', // 上传审核状态
      uploadClassify: '' // 上传分类
    }
  },
  props: {
    importVisible: {
      type: Boolean,
      default: () => false
    },
    uploadVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    customerType: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    customerType: function (val, oldVal) {
      this.$set(this.uploadData, 'module', val)
    }
  },
  methods: {
    // 下载模板
    bsybgDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%B0%83%E7%A0%94%E6%8A%A5%E5%91%8A-%E6%9C%80%E7%BB%88%E7%89%881008.docx'
    },
    // 上传
    submitUpload (importType) {
      this.loading = true
      if (!validatenull(this.fileListMsg)) { // 判断文件是否为空
        if (this.auditStatus === '1') { // 判断上传是否已审核
          this.$message.error('上传已审核，不能更改！上传文件请先反审核！')
          this.loading = false
        } else {
          this.fileListMsg.forEach(item => {
            this.picUpload(item)
          })
          setTimeout(() => {
            this.getOssReturnData() // 调用返回数据接口
            if (this.uploadStatus) {
              this.$message.success('上传成功')
            } else {
              this.$message.error('上传失败')
            }
          }, 1500)
        }
      } else {
        this.$message.error('请选择上传文件！')
        this.loading = false
      }
    },
    async picUpload (file) {
      uploadAli().then(response => {
        console.log('uploadAli response:', response)
        var data = response.data
        var ossData = new FormData()
        // var imgType = file.raw.name.split('.')[1]
        let fileNamePoint = file.raw.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
        let fileNameLength = file.raw.name.length // 取到文件名长度
        let imgType = file.raw.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
        // console.log('imgType', imgType)
        // console.log('fileName', fileNamePoint + 1)
        // console.log('fileNameLength', fileNameLength)
        // console.log('imgType', imgType)
        // var fileName = this.updateNo + '_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.' + imgType // 上传阿里保存 文件名=单位编码+日期（年月日时分秒+毫秒3位数）+4位随机码+文件类型
        var fileName = this.updateNo + Math.random().toString().substr(2, 3) + file.raw.name // 上传阿里保存 文件名=单位编码+3位随机码+原有文件名
        ossData.append('name', fileName)
        // key就代表文件层级和阿里云上的文件名
        // let filename = file.file.name + file.file.size
        // let keyValue = data.dir + '/' + filename + '.' + imgType
        let keyValue = data.dir + fileName
        ossData.append('key', keyValue)
        ossData.append('policy', data.policy)
        ossData.append('OSSAccessKeyId', data.accessid)
        ossData.append('success_action_status', 200)
        ossData.append('signature', data.signature)
        ossData.append('callback', data.callback)
        ossData.append('file', file.raw, file.raw.name)
        axios.post(data.host, ossData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          console.log('res', res)
          let fileType = ''
          if (imgType === 'jpg' || imgType === 'jpeg' || imgType === 'png' || imgType === 'gif') { // .jpg,.jpeg,.png,.gif
            fileType = 'picture'
          } else if (imgType === 'xls' || imgType === 'xlsx' || imgType === 'doc' || imgType === 'docx' || imgType === 'pdf') { // xls,.xlsx,.doc,.docx,.pdf
            fileType = 'file'
          } else if (imgType === 'avi' || imgType === 'wmv' || imgType === 'mpeg' || imgType === 'mov' || imgType === 'rm' || imgType === 'rmvb' || imgType === 'flv' || imgType === 'mkv' || imgType === 'mp4') { // .avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4
            fileType = 'video'
          } else if (imgType === 'mp3' || imgType === 'wma' || imgType === 'flac' || imgType === 'ape' || imgType === 'wav' || imgType === 'aac' || imgType === 'm4a') { // .mp3,.wma,.flac,.ape,.wav,.aac,.m4a
            fileType = 'music'
          }
          if (res.status === 200) {
            // let url = data.host // 上传阿里保存 传值URL
            if (this.updateType === 'researchReport') { // 承运单位 合同、资质上传
              uploadAliResearch(Object.assign({
                url: 'https://file.56tms.com/' + keyValue,
                fileName: fileName,
                objectNo: this.updateNo, // 单位编号
                source: 'tms', // 来源
                module: this.customerType, // 模块设置为单据类型
                itemType: this.uploadClassify, // 类型
                fileType: fileType, // 文件类型
                fileDesc: this.uploadData.fileDesc // 文件说明
              })).then(items => {
                if (items) {
                  this.srcList = []
                  this.fileSrcList = []
                  this.videoSrcList = []
                  this.$refs.refUploadAli.clearFiles() // 清空上传文件列表
                  this.fileListMsg = [] // 清空handlechange的文件缓存
                  this.loading = false
                  this.uploadStatus = true
                }
              })
            }
          }
        }).catch(() => {
        })
      })
    },
    handleChange (file, fileList) {
      this.fileListMsg = fileList
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    // 判断导入的格式是不是xls、xlsx、...，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      const isIMAGE = file.type === '.jpg' || '.jpeg' || '.png' || '.gif' // .jpg,.jpeg,.png,.gif
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isFile = testmsg === '.xls' || '.xlsx' || '.doc' || '.docx' || '.pdf'
      const isVideo = testmsg === '.avi' || '.wmv' || '.mpeg' || '.mov' || '.rm' || '.rmvb' || '.flv' || '.mkv' || '.mp4' // .avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4
      const isMusic = testmsg === '.mp3' || '.wma' || '.flac' || '.ape' || '.wav' || '.aac' || '.m4a' // .mp3,.wma,.flac,.ape,.wav,.aac,.m4a
      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isFile || !isVideo || !isMusic || !isIMAGE) {
        this.$message.error('上传文件格式不支持！')
        return false
      }
      // if (!isIMAGE) {
      //   this.$message.error('上传文件只能是图片格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 50MB!')
      }
      return (isIMAGE || isFile || isVideo || isMusic) && isLt2M
      // return isIMAGE
    },
    // 上传成功
    onSuccess (res, file, fileList) {
      // let val = ''
      // fileList.forEach(item => {
      //   if (val !== '') {
      //     val += ','
      //   }
      //   if (item.response) {
      //     val += item.response.data.downloadPath
      //   } else {
      //     val += item.url
      //   }
      // })
      // this.$emit('input', val)
      // this.uploadAliyun(res, file)
    },
    // 上传失败
    onError () {
    },
    // 获取客户编号
    getupdateNo (cusNo, type, auditStatus, classify) {
      console.log('classify', classify)
      this.updateNo = cusNo
      this.updateType = type
      this.auditStatus = auditStatus // 上传审核状态（'1'为已审核，'0'为未审核）
      this.uploadClassify = classify // 上传分类
      this.$set(this.uploadData, 'objectNo', cusNo)
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.srcList = []
      this.fileSrcList = []
      this.videoSrcList = []
      if (this.editStatus) {
        this.editStatus = false
      }
      this.$set(this.uploadData, 'objectNo', '') // 清空编号
      this.$set(this.uploadData, 'fileDesc', '') // 清空文件说明
      this.$refs.refUploadAli.clearFiles() // 清空上传文件列表
    },
    listString (imageList) {
      var imageArray = []
      imageList.forEach(item => {
        imageArray.push(item.url)
      })
      return imageArray
    },
    // 获取上传返回的数据
    getOssReturnData () {
      this.loading = true
      if (this.updateType === 'researchReport') { // 获取 返回值
        getOssObjectList(Object.assign({
          objectNo: this.updateNo,
          itemType: this.uploadClassify
        })).then(item => {
          this.loading = false
          if (item.code === 0) {
            item.data.forEach(urls => {
              let option = {}
              option.id = urls.id
              option.url = urls.url
              option.fileName = urls.fileName
              option.itemType = urls.itemType
              option.fileType = urls.fileType
              if (option.fileType === 'picture') {
                this.srcList.push(option)
                this.delOssId.push(option)
              } else if (option.fileType === 'file') {
                this.fileSrcList.push(option)
              } else if (option.fileType === 'video' || option.fileType === 'music') {
                this.videoSrcList.push(option)
              }
            })
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    // 预览返回图片
    handlePictureCardPreview (file) {
      // this.$refs.image_refs.clickHandler()
    },
    // 删除返回图片
    handlePictureCardRemove (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error('上传已审核，不能更改！删除图片请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.srcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    },
    // 批量下载
    batch_download () {
      const loading = this.$loading({
        lock: true,
        text: '正在下载中，请稍等。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.updateType === 'researchReport') {
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: this.uploadClassify
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      }
    },
    // 预览文件
    handleFilePreview (option, index, val) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载预览中，请稍等。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      wordToPDF(Object.assign({
        id: option.id
      })).then(response => {
        if (response.code === 0) {
          window.open(response.data)
          setTimeout(() => {
            loading.close()
          }, 200)
        } else {
          this.$message.error(response.msg)
        }
      })
    },
    // 下载返回文件
    handleFileDownload (option, index, val) {
      let fileNamePoint = option.fileName.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      let fileNameLength = option.fileName.length // 取到文件名长度
      let imgType = option.fileName.substring(fileNamePoint + 1, fileNameLength) // 截

      if (imgType === 'pdf') { // 如果文件类型是pdf
        window.open(option.url)
      } else { // 如果是其他文件类型
        const ele = document.createElement('a')
        ele.setAttribute('href', option.url) // 设置下载文件的url地址
        ele.setAttribute('download', option.fileName) // 用于设置下载文件的文件名
        ele.click()
      }
    },
    // 下载返回音频、视频
    handleVideoDownload (option, index, val) {
      // const ele = document.createElement('a')
      // ele.setAttribute('href', option.url) // 设置下载文件的url地址
      // ele.setAttribute('download', option.fileName) // 用于设置下载文件的文件名
      // ele.click()
      // 将lob对象转换为域名结合式的url
      // const link = document.createElement('a')
      // link.style.display = 'none'
      // // 设置a标签的下载属性，设置文件名及格式，后缀名最好让后端在数据格式中返回
      // link.download = option.fileName
      // var blob = new Blob(option.url)
      // link.href = URL.createObjectURL(blob)
      // // 自触发click事件
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
      window.open(option.url)
    },
    // 删除返回文件
    handleFileDelete (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error('上传已审核，不能更改！删除文件请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.fileSrcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    },
    // 删除返回音频、视频
    handleVideoDelete (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error('上传已审核，不能更改！删除音频、视频请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.videoSrcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.uploadali-el-card-style {
    .el-card__header {
        padding: 14px 20px !important;
        border-bottom: 0 !important;
    }
}
.uploadImage {
    position: relative;
    display: inline-block;
    margin-left: 17px;
    .optionMark {
        display: none;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50px;
        top: 50px;
        background:linear-gradient(to top left,rgba(0,0,0,.5) 40%,transparent 40%);

        .el-upload-list__item-actions {
            position: absolute;
            font-size: 15px;
            left: 6px;
            top: 26px;
            width: 100%;
            height: 100%;
            text-align: right;
            color: #fff;
            transition: opacity .3s;
        }
    }
}
.uploadImage:hover {
    .optionMark {
        display: block;
        z-index: 999;
        .el-upload-list__item-preview {
            cursor: pointer;
        }
    }
}
/*文件下载删除 鼠标移入样式改变*/
.file_download:hover{
    cursor: pointer;
    .file_download_delete{display: inline-block};
}
/*文件下载删除按钮*/
.file_download_delete{
  display: none;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-uploadAli-research/index.vue"
}
</vue-filename-injector>
