import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/dtlogistic/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/dtlogistic',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/dtlogistic/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/dtlogistic/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/dtlogistic',
    method: 'put',
    data: obj
  })
}
/**
 * 承运单位 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/dtlogistic/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/basic/dtlogistic/lockenable',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位 临时启用
 */
export function temporaryenableObjs (id) {
  return request({
    url: '/basic/dtlogistic/activate',
    method: 'get',
    params: { id: id }
  })
}
/**
 * 承运单位 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/basic/dtlogistic/lockstop',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/dtlogistic/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过搜索 得到承运单位名称
 */
export function getLogisticName (search) {
  return request({
    url: '/basic/dtlogistic/logisticname',
    method: 'get',
    params: { search: search }
  })
}
/**
 * 通过搜索 得到承运单位名称
 */
export function getLogistic (search) {
  return request({
    url: '/basic/dtlogistic/getlogistic',
    method: 'get',
    params: { searchName: search }
  })
}
/**
 * 运营中心通过搜索 得到承运单位名称(停用的过滤不显示查询)
 */
export function buslogistic (search) {
  return request({
    url: '/basic/dtlogistic/buslogistic',
    method: 'get',
    params: { searchName: search }
  })
}
/**
 * 上传阿里 供应商资料保存到后台
 */
export function uploadAliLogistic (obj) {
  return request({
    url: '/tms/ossobject/logistic',
    method: 'post',
    data: obj
  })
}
/**
 * 批量上传阿里 保存到后台
 */
export function uploadAliLogisticBatch (obj) {
  return request({
    url: '/tms/ossobject/batchCarrierContract',
    method: 'post',
    data: obj
  })
}
/**
 * 营运单导入 返回结果
 */
export function returnImportPrice () {
  return request({
    url: '/basic/dtlogistic/import/progress',
    method: 'get'
  })
}
/**
 * 通过ids进行 财务审核
 */
export function supRetrial (ids) {
  return request({
    url: '/basic/dtlogistic/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 财务反审核
 */
export function supUnRetrial (ids) {
  return request({
    url: '/basic/dtlogistic/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行结算审核
 */
export function supSettlement (ids) {
  return request({
    url: '/basic/dtlogistic/auditSettlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 结算反审核
 */
export function supUnSettlement (ids) {
  return request({
    url: '/basic/dtlogistic/unAuditSettlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运力审核
 */
export function supOperate (ids) {
  return request({
    url: '/basic/dtlogistic/operate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 运力反审核
 */
export function supUnOperate (ids) {
  return request({
    url: '/basic/dtlogistic/unoperate',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位只修改基础资料信息(不需要未审核、未审单状态，保留启用状态)
 */
export function updateBasicData (obj) {
  return request({
    url: '/basic/dtlogistic/updateBasicData',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 总经理审核
 */
export function supManager (ids) {
  return request({
    url: '/basic/dtlogistic/managerExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 总经理反审核
 */
export function supUnManager (ids) {
  return request({
    url: '/basic/dtlogistic/managerApproval',
    method: 'post',
    params: ids
  })
}
/**
 * 通过承运单位id 检验是否10天内快到期
 */
export function checkOverdue (id) {
  return request({
    url: '/basic/dtlogistic/costSheetMaturityHint',
    method: 'post',
    params: id
  })
}
/**
 * 承运单位 弹框查询
 */
export function logisticSearch (query) {
  return request({
    url: '/basic/dtlogistic/buslogistic/page',
    method: 'post',
    data: query
  })
}
/**
 * 通过承运单位id 部门id 选择承运商信息判断是否有录入权限
 */
export function determineCarrierGate (obj) {
  return request({
    url: '/basic/dtlogistic/determineCarrierGate',
    method: 'post',
    params: obj
  })
}
