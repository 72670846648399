
















































































export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-theme/components/d2-theme-list/index.vue"
}
