<template>
  <el-cascader
    style="width: 100%"
    size="mini"
    v-model="state"
    :options="options"
    :props="{value: 'itemId', label: 'itemName', children: 'children'}"
    @change="handleChange"
    ref="cascaderSub"
    :disabled="disabled"
    :clearable="clearable"
    :show-all-levels="false">
  </el-cascader>
</template>
<script>
import { fetchtree } from '@/api/tms/financesubject'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-subject',
  data () {
    return {
      options: [],
      state: this.stringToArray()
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    // 数据进行String to ArrayList
    stringToArray () {
      return this.value.split(',')
    },
    // 获取表格数据
    getList () {
      fetchtree().then(response => {
        this.options = this.getTreeData(response.data)
      })
    },
    handleChange () {
      if (this.state.length > 0) {
        // console.log('handleChange', this.state)
        // console.log('this.$refs[\'cascaderSub\'].getCheckedNodes()', this.$refs['cascaderSub'].getCheckedNodes())
        let thsName = this.$refs['cascaderSub'].getCheckedNodes()[0].pathLabels.join('-')
        let val = { value: this.state.toString(), label: thsName }
        this.$emit('change', val)
      }
    },
    // 最后一级children为空时，递归方式设为不显示
    getTreeData (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined
        } else {
          this.getTreeData(data[i].children)
        }
      }
      return data
    }
  },
  watch: {
    state: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      if (!validatenull(val)) {
        this.state = val.split(',')
      } else {
        this.state = []
      }
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-subject/index.vue"
}
</vue-filename-injector>
