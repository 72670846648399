// import request from '@/router/axios'
import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/haiercustomer/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/haiercustomer',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/haiercustomer/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/haiercustomer/' + id,
    method: 'delete'
  })
}
/**
 * 客户引入 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/haiercustomer/delbyids',
    method: 'post',
    params: ids
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/haiercustomer',
    method: 'put',
    data: obj
  })
}
/**
 * 判断MDM编码 是否重复
 */
export function getMdmCode (obj) {
  return request({
    url: '/tms/haiercustomer/getmdmcode/' + obj,
    method: 'get'
  })
}
/**
 * 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/tms/haiercustomer/lockEnable',
    method: 'post',
    params: ids
  })
}
/**
 * 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/tms/haiercustomer/lockStop',
    method: 'post',
    params: ids
  })
}
