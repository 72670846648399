import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/finacedefrayment/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/finacedefrayment',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/finacedefrayment/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/finacedefrayment/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/finacedefrayment',
    method: 'put',
    data: obj
  })
}
/**
 * 保存
 */
export function cpadd (obj) {
  return request({
    url: '/tms/finacedefrayment/cpadd',
    method: 'post',
    data: obj
  })
}
/**
 * 新增保存
 */
export function cpDeptAdd (obj) {
  return request({
    url: '/tms/finacedefrayment/cpDeptAdd',
    method: 'post',
    data: obj
  })
}
/**
 * 修改保存
 */
export function cpedit (obj) {
  return request({
    url: '/tms/finacedefrayment/cpupdate',
    method: 'post',
    data: obj
  })
}
/**
 * 暂存新增保存
 */
export function transientadd (obj) {
  return request({
    url: '/tms/finacedefrayment/transientadd',
    method: 'post',
    data: obj
  })
}
/**
 * 暂存修改保存
 */
export function transientUpdate (obj) {
  return request({
    url: '/tms/finacedefrayment/transientUpdate',
    method: 'post',
    data: obj
  })
}
export function delids (ids) {
  return request({
    url: '/tms/finacedefrayment/delids',
    method: 'post',
    params: ids
  })
}
/**
   * 通过ids进行审单
   */
export function sfkAudit (ids) {
  return request({
    url: '/tms/finacedefrayment/sfkAudit',
    method: 'post',
    data: Object.assign({ type: '0', state: '1', isAudit: '1' }, ids)
  })
}
/**
 * 通过ids进行反审单
 */
export function sfkUnaudit (ids) {
  return request({
    url: '/tms/finacedefrayment/sfkUnaudit',
    method: 'post',
    data: Object.assign({ type: '0', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 通过ids进行审核
 */
export function sfkExamine (ids) {
  return request({
    url: '/tms/finacedefrayment/sfkExamine',
    method: 'post',
    data: Object.assign({ type: '1', state: '1', isAudit: '1' }, ids)
  })
}
/**
 * 通过ids进行反审核
 */
export function sfkUnexamine (ids) {
  return request({
    url: '/tms/finacedefrayment/sfkUnexamine',
    method: 'post',
    data: Object.assign({ type: '1', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 关联当前部门对应的员工档案的数据赋值到从表
 */
export function getImportData (query) {
  return request({
    url: '/tms/finacedefrayment/getDeptAll',
    method: 'post',
    data: query
  })
}
/**
 * 人工详情
 */
export function getFinacDetail (id) {
  return request({
    url: '/tms/finacedefrayment/getFinacDetail/' + id,
    method: 'get'
  })
}
/**
 * 复制数据
 */
export function getCopyFinacDetail (id) {
  return request({
    url: '/tms/finacedefrayment/getCopyFinacDetail/' + id,
    method: 'get'
  })
}
/**
 * 通过deptId 获取当前部门所有员工档案里 非冻结的 人员数据
 */
export function getDtCustomerAll (deptId) {
  return request({
    url: '/tms/finacedefrayment/getDtCustomerAll',
    method: 'post',
    data: deptId
  })
}
/**
 * 通过deptId查询项目管理中的项目名称
 */
export function getCustomer (deptId) {
  return request({
    url: '/tms/finacedefrayment/queryTargetByFinace',
    method: 'post',
    params: deptId
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliArtificial (obj) {
  return request({
    url: '/tms/ossobject/fin',
    method: 'post',
    data: obj
  })
}
/**
 * 导出明细表*
 */
export function exportDetail (obj) {
  return request({
    url: '/ex/export/finceExport',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
