import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/customerProfile/customerProfilePage',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/customerProfile/insertCustomerProfile',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/customerProfile/byIdQueryMessage/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/customerProfile/updateCustomerProfile',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/customerProfile/deleteByPrimaryKey',
    method: 'post',
    params: ids
  })
}
/**
 * 判断客户名称是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeWhetherRepeat',
    method: 'post',
    params: { customerName: obj }
  })
}
/**
 * 导入
 */
export function excelImport (file) {
  return request({
    url: '/tms/tmsCustomers/importTmsCustomersData',
    method: 'post',
    params: file
  })
}
/**
 * 通过ids进行审核
 */
export function passExamine (ids) {
  return request({
    url: '/tms/customerProfile/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function passUnExamine (ids) {
  return request({
    url: '/tms/customerProfile/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行拒审
 */
export function refuseExamine (ids) {
  return request({
    url: '/tms/customerProfile/unExamineFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 获取客户档案（客户名称搜索下拉）
 */
export function getCustomerProfile (customerName) {
  return request({
    url: '/tms/customerProfile/queryCustomerProfile',
    method: 'post',
    params: { customerName: customerName }
  })
}
/**
 * 获取客户档案（项目名称搜索下拉）
 */
export function getProjectProfile (projectName) {
  return request({
    url: '/tms/customerProfile/queryCustomerProfileByPro',
    method: 'post',
    params: { projectName: projectName }
  })
}
/**
 * 通过ids进行副总审核
 */
export function passReview (ids) {
  return request({
    url: '/tms/tmsCustomers/review',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总反审核
 */
export function passUnReview (ids) {
  return request({
    url: '/tms/tmsCustomers/unReview',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总拒审
 */
export function refuseReview (ids) {
  return request({
    url: '/tms/tmsCustomers/unReviewFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 判断信用代码是否重复
 */
export function getCreditCode (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeCreditCodeRepeat',
    method: 'post',
    params: { creditCode: obj }
  })
}
// 客源管理  跳转 立项报告
export function getIntroduceMsg (id) {
  return request({
    url: '/tms/tmsCustomers/adoptQueryReviewData/' + id,
    method: 'get'
  })
}
/**
 * 判断项目名称是否重复
 */
export function getProjectName (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeCustomerRepeat',
    method: 'post',
    params: { projectName: obj }
  })
}
/**
 *
 获取跟踪记录
 */
export function getObjList (customersId) {
  return request({
    url: '/tms/customersTrack/selectCustomersTrack',
    method: 'post',
    params: { customersId: customersId }
  })
}
/**
 *
 新增跟踪记录
 */
export function addCustomerTrack (obj) {
  return request({
    url: '/tms/customersTrack/insertCustomersTrack',
    method: 'post',
    data: obj
  })
}
/**
 *
 通过id删除跟踪记录
 */
export function delObj (id) {
  return request({
    url: '/tms/customersTrack/deleteCustomersTrack',
    method: 'post',
    params: { id: id }
  })
}
