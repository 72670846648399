import request from '@/plugin/axios'
/**
 * 分页查询
 */
export function fetchList (query) {
  return request({
    url: '/basic/dtdispatcher/page',
    method: 'post',
    data: query
  })
}
/**
 * 新增
 */
export function addObj (obj) {
  return request({
    url: '/basic/dtdispatcher',
    method: 'post',
    data: obj
  })
}
/**
 * 通过id查询 编辑获取数据
 */
export function getObj (id) {
  return request({
    url: '/basic/dtdispatcher/' + id,
    method: 'get'
  })
}
/**
 * 通过id删除
 */
export function delObj (id) {
  return request({
    url: '/basic/dtdispatcher/' + id,
    method: 'delete'
  })
}
/**
 *
 修改
 */
export function putObj (obj) {
  return request({
    url: '/basic/dtdispatcher',
    method: 'put',
    data: obj
  })
}
/**
 *  批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/dtdispatcher/delbyids',
    method: 'post',
    params: ids
  })
}
/*
*用户管理  获取员工档案的用户*
*/
export function fetchDispatchName () {
  return request({
    url: '/basic/dtdispatcher/list',
    method: 'get'
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliDispatch (obj) {
  return request({
    url: '/tms/ossobject/dtDispatcher',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行审单
 */
export function dispatchAudit (ids) {
  return request({
    url: '/basic/dtdispatcher/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function dispatchUnAudit (ids) {
  return request({
    url: '/basic/dtdispatcher/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function dispatchExamine (ids) {
  return request({
    url: '/basic/dtdispatcher/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function dispatchUnExamine (ids) {
  return request({
    url: '/basic/dtdispatcher/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行复审
 */
export function dispatchReExamine (ids) {
  return request({
    url: '/basic/dtdispatcher/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反复审
 */
export function dispatchUnReExamine (ids) {
  return request({
    url: '/basic/dtdispatcher/unRetrial',
    method: 'post',
    params: ids
  })
}
/**
* 通过ids进行启用
*/
export function dispatchEnable (ids) {
  return request({
    url: '/basic/dtdispatcher/enable',
    method: 'POST',
    params: ids
  })
}
/**
 * 通过ids进行停用
 */
export function dispatchDeactivate (ids) {
  return request({
    url: '/basic/dtdispatcher/deactivate',
    method: 'POST',
    params: ids
  })
}
/**
 * 根据调度人名称查询负责项目详情
 */
export function selectProjectDetails (obj) {
  return request({
    url: '/basic/dtdispatcher/selectResponsibleProjectDetails',
    method: 'POST',
    params: obj
  })
}
/**
 * 项目详情 新增项目
 */
export function addDispatch (obj) {
  return request({
    url: '/basic/dtcustomer/increaseOverseeingTheProject',
    method: 'post',
    params: obj
  })
}
/**
 * 项目详情 删除项目
 */
export function delDispatch (obj) {
  return request({
    url: '/basic/dtcustomer/decreaseOverseeingTheProject',
    method: 'post',
    params: obj
  })
}
/*
*根据大区查询外部调度*
*/
export function fetchExternalPerson (areaId) {
  return request({
    url: '/basic/dtdispatcher/queryExternalPersonnelByRegion',
    method: 'get',
    params: { areaId: areaId }
  })
}
