<template>
    <el-select class="td_select"
               v-model="values"
               default-first-option
               filterable
               remote
               reserve-keyword
               multiple
               collapse-tags
               placeholder="请选择法人公司"
               :clearable="clearable"
               :disabled="disabled"
               :remote-method="remoteMethod"
               @clear="clearAction"
               :loading="loading">
        <el-option
                class="td_option"
                v-for="(item, index) in options"
                :key="index"
                :label="item.companyCode+ '  ' +item.companyName"
                :value="item.companyCode"
                @click.native="handleSelect(item)">
            <span style="float: left;width: 130px;">{{ item.companyCode }}</span>
            <span style="float: left; color: #8492a6; font-size: 13px;">{{ item.companyName }}</span>
        </el-option>
    </el-select>
</template>
<script>
import { fetchTree } from '@/api/admin/company'

export default {
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    sourcetype: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      fetchTree().then(response => {
        console.log('返回值：', response)
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
      // this.$emit('change', '')
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-objinput-selects/index.vue"
}
</vue-filename-injector>
