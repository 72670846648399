import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/collectionBudget/page',
    method: 'post',
    data: query
  })
}

/**
 * 通过id删除
 */
export function delObj (id) {
  return request({
    url: '/tms/collectionBudget/deleteCollectionBudget/' + id,
    method: 'delete'
  })
}

/**
 * 新增保存
 */
export function savaDD (obj) {
  return request({
    url: '/tms/collectionBudget/insertCollectionBudget',
    method: 'post',
    data: obj
  })
}
/**
 * 修改保存
 */
export function updateDD (obj) {
  return request({
    url: '/tms/collectionBudget/updateCollectionBudget',
    method: 'post',
    data: obj
  })
}
/**
 * 修改、详情页 获取数据
 */
export function getData (id) {
  return request({
    url: '/tms/collectionBudget/queryCollectionBudget/' + id,
    method: 'get'
  })
}
/**
 * 根据deptId查询部门结算专员
 */
export function getSettleCommissioner (deptId) {
  return request({
    url: '/basic/dtcustomer/queryMonthlyDept/' + deptId,
    method: 'get'
  })
}
/**
 * 查询结算专员
 */
export function getSettleName (settleName) {
  return request({
    url: '/basic/dtcustomer/querySettleName',
    method: 'post',
    params: settleName
  })
}
/**
 * 通过 deptId,月份,结算专员 查询应收账款报表信息 返回到明细
 */
export function getEarlyGoal (obj) {
  return request({
    url: '/tms/collectionBudget/selectReceivablesData',
    method: 'post',
    params: obj
  })
}
/**
 * 批量审单
 */
export function auditObjs (ids) {
  return request({
    url: '/tms/collectionBudget/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 批量反审单
 */
export function unauditObjs (ids) {
  return request({
    url: '/tms/collectionBudget/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 批量审核
 */
export function examineObjs (ids) {
  return request({
    url: '/tms/collectionBudget/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 批量反审核
 */
export function unexamineObjs (ids) {
  return request({
    url: '/tms/collectionBudget/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 批量复审
 */
export function retrialObjs (ids) {
  return request({
    url: '/tms/collectionBudget/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 批量反复审
 */
export function unretrialObjs (ids) {
  return request({
    url: '/tms/collectionBudget/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 导出明细*
 */
export function exportDetail (obj) {
  return request({
    url: '/tms/tmstarget/exportImportTmsTarget',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 详情页 开票表格获取数据
 * @param obj
 * @returns {AxiosPromise}
 */
export function getfpdetails (obj) {
  return request({
    url: '/tms/monthlyCollection/receivablesMonthlyDetails',
    method: 'post',
    data: obj
  })
}
export function secondFloorExport (obj) {
  return request({
    url: '/ex/export/exportImportMonthlyCollection',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
