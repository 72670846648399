




















































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-itemsName/index.vue"
}
