<template class="contractPriceType_style">
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="9">
        <el-button size="mini" type="primary" style="padding: 5px 12px;border-radius: 4px;" :disabled="disabled" @click="handleClick">编辑</el-button>
      </el-col>
    </el-row>
    <el-dialog class="inquiryMethod_dialog" style="margin-top: -50px;" width="858px" title="合同价类型" :visible.sync="inquiryVisible" :before-close="inquiryHandleClose" :close-on-click-modal="false" append-to-body>

      <!-- 表单一 -->
      <el-row type="flex" align="middle" style="margin-bottom: 10px;" v-for="(details, index) in formList" :key="index">
        <el-col :span="21">
          <el-card shadow="hover">
            <el-form :model="details" ref="details" label-width="100px" class="demo-dynamic" size="mini">
              <el-col :span="8">
                <el-form-item prop="inquiryMode" label="合同价类型" label-width="100px" :rules="[ { required: true, message: '请选择合同价类型', trigger: 'change' } ]">
                  <el-select style="width: 100%;" v-model="details.inquiryMode" placeholder="请选择" clearable @clear="clearEvent" @change="val => inquiryModeChange(val, index)">
                    <el-option
                        v-for="(item) in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                        @click.native="handleSelect(item, index)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div v-if="details.inquiryMode === '零担件数' || details.inquiryMode === '零担重量' || details.inquiryMode === '零担体积'">
                  <el-form-item v-for="(domain, itemIndex) in details.domains" :label="'坎级' + (itemIndex + 1)" :key="domain.key" required>
                    <el-col :span="6">
                      <el-form-item :prop="'domains.' + itemIndex + '.lowestValue'" :rules="[{ required: true, message: '坎级不能为空', trigger: 'blur' },{ type: 'number', message: '坎级必须为数字值'}]">
                        <el-input v-model.number="domain.lowestValue"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2"><div style="display: flex;justify-content: center;"><span>-</span></div></el-col>
                    <el-col :span="6">
                      <el-form-item :prop="'domains.' + itemIndex + '.upperValue'" :rules="[{ required: true, message: '坎级不能为空', trigger: 'blur' },{ type: 'number', message: '坎级必须为数字值'}]">
                        <el-input v-model.number="domain.upperValue"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2" :offset="1">
                      <!--                  <el-button @click.prevent="removeDomain(domain)">删除</el-button>-->
                      <el-button v-if="itemIndex !== 0" icon="el-icon-minus" type="danger" plain circle @click.prevent="removeDomain(details, domain, index)"></el-button>
                    </el-col>
                  </el-form-item>
                  <el-form-item>
                    <!--                <el-button @click="addDomain">新增</el-button>-->
                    <el-col :span="2" :offset="6">
                      <el-button style="margin-left: 8px;" icon="el-icon-plus" type="primary" plain circle @click="addDomain(details, index)"></el-button>
                    </el-col>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24">
                <div v-if="details.inquiryMode === '整车车长'">
                  <el-form-item v-for="(domain, itemIndex) in details.carLength" :label="'坎级' + (itemIndex + 1)" :key="domain.key" required>
                    <el-col :span="14">
                      <el-form-item :prop="'carLength.' + itemIndex + '.vehicleLength'" :rules="[ { required: true, message: '请选择车辆长度', trigger: 'change' } ]">
                        <el-select style="width: 100%;" v-model="domain.vehicleLength" placeholder="请选择" clearable @clear="clearCarLength">
                          <el-option
                              v-for="(item) in carLengthOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              :disabled="item.disabled"
                              @click.native="handleCarLength(item, itemIndex)">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2" :offset="1">
                      <!--                  <el-button @click.prevent="removeDomain(domain)">删除</el-button>-->
                      <el-button v-if="itemIndex !== 0" icon="el-icon-minus" type="danger" plain circle @click.prevent="removeDomain(details, domain, index)"></el-button>
                    </el-col>
                  </el-form-item>
                  <el-form-item>
                    <!--                <el-button @click="addDomain">新增</el-button>-->
                    <el-col :span="2" :offset="6">
                      <el-button style="margin-left: 8px;" icon="el-icon-plus" type="primary" plain circle @click="addDomain(details, index)"></el-button>
                    </el-col>
                  </el-form-item>
                </div>
<!--                <el-form-item v-if="details.inquiryMode === '整车车长'" prop="carLength" label="车辆长度" :rules="[ { required: true, message: '请选择车辆长度', trigger: 'change' } ]">-->
<!--                  <el-select style="width: 100%;" v-model="details.carLength" placeholder="请选择" multiple clearable>-->
<!--                    <el-option-->
<!--                        v-for="(item) in carLengthOptions"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
              </el-col>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="2" :offset="1">
          <!--            <el-button @click="addClick">添加</el-button>-->
          <el-button v-if="index === 0" icon="el-icon-plus" type="primary" plain circle @click="addClick"></el-button>
          <el-button v-else icon="el-icon-minus" type="danger" plain circle @click="deleteClick(details)"></el-button>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="margin-top: 30px;">
        <el-col :span="4">
          <el-button type="primary" @click="submitForm('details')">确定</el-button>
          <el-button @click="cancelForm('details')">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { getdict } from '@/api/admin/dict'
import { deepClone } from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-contractPriceType',
  data () {
    return {
      values: '',
      inquiryVisible: false,
      formList: [
        {
          domains: [{
            lowestValue: '',
            upperValue: ''
          }],
          inquiryMode: '',
          carLength: [{
            vehicleLength: ''
          }]
        }
      ],
      form: {
        domains: [{
          lowestValue: '',
          upperValue: ''
        }],
        domains1: [{
          lowestValue: '',
          upperValue: ''
        }],
        domains2: [{
          lowestValue: '',
          upperValue: ''
        }],
        domains3: [{
          lowestValue: '',
          upperValue: ''
        }],
        inquiryMode: '',
        inquiryMode1: '',
        inquiryMode2: '',
        inquiryMode3: '',
        carLength: [],
        carLength1: [],
        carLength2: [],
        carLength3: []
      },
      options: [
        { value: '零担件数', label: '零担件数', disabled: false },
        { value: '零担重量', label: '零担重量', disabled: false },
        { value: '零担体积', label: '零担体积', disabled: false },
        { value: '整车车长', label: '整车车长', disabled: false }
      ],
      carLengthOptions: [],
      formFVisible: false,
      formSVisible: false,
      formTVisible: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    mdmStr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    getInit () {
      // 获取 车长 下拉加载项
      getdict('car_size').then(response => {
        let listItem = []
        response.data.forEach(item => {
          let option = {}
          option.label = item.label
          option.value = item.value
          option.disabled = false
          listItem.push(option)
        })
        this.carLengthOptions = listItem
      })
    },
    // 添加表单
    addClick () {
      if (this.formList.length < 4) {
        this.formList.push(
          {
            domains: [{
              lowestValue: '',
              upperValue: ''
            }],
            inquiryMode: '',
            carLength: [{
              vehicleLength: ''
            }]
          }
        )
      } else {
        this.$message('最多只有四种询价方式！')
      }
    },
    // 删除表单二
    deleteClick (item) {
      var index = this.formList.indexOf(item)
      if (index !== -1) {
        this.formList.splice(index, 1)
      }
      this.changeDisableState()
      this.changeDisableCarLength()
    },
    // 关闭按钮
    inquiryHandleClose () {
      let promiseArr = this.formList.map((item, index) => this.$refs['details'][index].resetFields())
      // 等待全部表单清除
      Promise.all(promiseArr).then(res => {
        this.inquiryVisible = false
        this.changeDisableState()
        this.changeDisableCarLength()
      })
    },
    // 打开模态框
    handleClick () {
      this.$emit('change', 'edit')
      this.changeDisableState()
      this.changeDisableCarLength()
    },
    getChange (val) {
      if (validatenull(val)) {
        this.inquiryVisible = true
        this.formList = [
          {
            domains: [{
              lowestValue: '',
              upperValue: ''
            }],
            inquiryMode: '',
            carLength: [{
              vehicleLength: ''
            }]
          }
        ]
      } else {
        // 坎级表有值 转换成表单
        let data = deepClone(val)
        let dataInfo = {}
        data.forEach((item, index) => {
          let { inquiryMode, camGrade } = item
          if (!dataInfo[inquiryMode]) {
            dataInfo[inquiryMode] = {
              inquiryMode,
              carLength: [],
              domains: []
            }
          }
          if (inquiryMode === '整车车长') {
            dataInfo[inquiryMode].inquiryMode = inquiryMode
            // dataInfo[inquiryMode].carLength.push(camGrade)
            dataInfo[inquiryMode].carLength.push({
              'vehicleLength': camGrade
            })
            dataInfo[inquiryMode].domains = []
          } else {
            dataInfo[inquiryMode].inquiryMode = inquiryMode
            dataInfo[inquiryMode].carLength = []
            dataInfo[inquiryMode].domains.push({
              'lowestValue': Number(camGrade.split('-')[0]),
              'upperValue': Number(camGrade.split('-')[1])
            })
          }
        })
        let list = Object.values(dataInfo)
        this.formList = list
        this.inquiryVisible = true
      }
    },
    // 清空合同价类型
    clearEvent () {
      this.changeDisableState()
    },
    // 选择合同价类型
    handleSelect (item, index) {
      this.changeDisableState()
    },
    // 合同价类型 选择禁用状态变更
    changeDisableState () {
      this.options.forEach(items => {
        let inlist = this.formList.filter(e => e.inquiryMode === items.value)
        if (!validatenull(inlist)) {
          items.disabled = true
        } else {
          items.disabled = false
        }
      })
    },
    // 清空车长
    clearCarLength () {
      this.changeDisableCarLength()
    },
    // 选择车长
    handleCarLength (item, index) {
      this.changeDisableCarLength()
    },
    // 车长 选择禁用状态变更
    changeDisableCarLength () {
      let carArr = this.formList.filter(item => item.inquiryMode === '整车车长')
      if (!validatenull(carArr)) {
        this.carLengthOptions.forEach(items => {
          let inlist = carArr[0].carLength.filter(e => e.vehicleLength === items.value)
          if (!validatenull(inlist)) {
            items.disabled = true
          } else {
            items.disabled = false
          }
        })
      }
    },
    inquiryModeChange (val, index) {
      if (val === '零担件数' || val === '零担重量' || val === '零担体积') {
        this.formList[index].domains = []
        this.formList[index].domains.push({
          lowestValue: '',
          upperValue: '',
          key: Date.now()
        })
      } else if (val === '整车车长') {
        this.formList[index].carLength = []
        this.formList[index].carLength.push({
          vehicleLength: '',
          key: Date.now()
        })
      }
    },
    // 确定提交
    submitForm (formName) {
      let promiseArr = this.formList.map((item, index) => this.$refs[formName][index].validate())
      // 等待全部表单验证完  再转换数据
      Promise.all(promiseArr).then(res => {
        const loading = this.$loading({
          lock: true,
          text: '正在转换保存询价方式，请稍等...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // 表单转换
        let listItem = [] // 坎级表数据
        // let carLengthList = [] // 整车车长坎级list
        // let intervalJSList = [] // 零担件数坎级list
        // let intervalZLList = [] // 零担重量坎级list
        // let intervalTJList = [] // 零担体积坎级list
        // let camGradeArr = [] // 四种合集坎级list
        let formList = deepClone(this.formList)
        formList.map((details, index) => {
          if (details.inquiryMode === '整车车长') {
            details.carLength.forEach(item => {
              let option = {}
              option.inquiryMode = details.inquiryMode
              option.camGrade = item.vehicleLength
              option.lowestValue = ''
              option.upperValue = ''
              listItem.push(option)
              // carLengthList.push(item.vehicleLength) // 整车车长list
            })
            // camGradeArr.push(carLengthList) // 车长push进合集
          } else if (details.inquiryMode === '零担件数' || details.inquiryMode === '零担重量' || details.inquiryMode === '零担体积') {
            details.domains.forEach(item => {
              let option = {}
              option.inquiryMode = details.inquiryMode
              option.camGrade = item.lowestValue + '-' + item.upperValue
              option.lowestValue = item.lowestValue
              option.upperValue = item.upperValue
              listItem.push(option)
              // if (details.inquiryMode === '零担件数') {
              //   intervalJSList.push(item.lowestValue + '-' + item.upperValue)
              // } else if (details.inquiryMode === '零担重量') {
              //   intervalZLList.push(item.lowestValue + '-' + item.upperValue)
              // } else if (details.inquiryMode === '零担体积') {
              //   intervalTJList.push(item.lowestValue + '-' + item.upperValue)
              // }
            })
            // if (details.inquiryMode === '零担件数') {
            //   camGradeArr.push(intervalJSList) // 件数坎级push进合集
            // } else if (details.inquiryMode === '零担重量') {
            //   camGradeArr.push(intervalZLList) // 重量坎级push进合集
            // } else if (details.inquiryMode === '零担体积') {
            //   camGradeArr.push(intervalTJList) // 体积坎级push进合集
            // }
          }
        })
        // 合同价类型拼接转换
        // let inquiryArr = formList.filter(item => !validatenull(item.inquiryMode)).map(items => { return items.inquiryMode })
        // let inquiry = inquiryArr.join(' / ')
        // 坎级拼接转换
        // let camGrade = camGradeArr.join(' / ')

        setTimeout(() => {
          this.$nextTick(() => {
            // console.log('listItem', listItem)
            // console.log('合同价类型inquiry', inquiry)
            // console.log('坎级camGrade', camGrade)
            this.$emit('change', 'submit', listItem)
            loading.close()
            this.inquiryVisible = false
          })
        }, 200)
      }).catch(() => {
        return false
      })
    },
    // 取消
    cancelForm (formName) {
      let promiseArr = this.formList.map((item, index) => this.$refs[formName][index].resetFields())
      // 等待全部表单清除
      Promise.all(promiseArr).then(res => {
        this.inquiryVisible = false
        this.changeDisableState()
        this.changeDisableCarLength()
      })
    },
    // 表单 减坎级
    removeDomain (details, item, indexs) {
      if (details.inquiryMode === '整车车长') {
        let index = this.formList[indexs].carLength.indexOf(item)
        if (index !== -1) {
          this.formList[indexs].carLength.splice(index, 1)
        }
        this.changeDisableCarLength()
      } else {
        let index = this.formList[indexs].domains.indexOf(item)
        if (index !== -1) {
          this.formList[indexs].domains.splice(index, 1)
        }
      }
    },
    // 表单 增坎级
    addDomain (details, index) {
      if (details.inquiryMode === '整车车长') {
        this.formList[index].carLength.push({
          vehicleLength: '',
          key: Date.now()
        })
      } else {
        this.formList[index].domains.push({
          lowestValue: '',
          upperValue: '',
          key: Date.now()
        })
      }
    },
    getStartValues (val) {
      if (!validatenull(this.mdmStr)) {
        let model = this.$parent.elForm.model[this.mdmStr]
        if (!validatenull(model)) {
          this.values = model
        } else {
          this.values = ''
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.values = val
    }
  },
  mounted () {
    this.getStartValues(this.value)
    this.getInit()
  }
}
</script>

<style lang="scss" scoped>
.contractPriceType_style {
  .el-form-item__error {
    left: 40% !important;
  }
}
</style>
<style lang="scss">
.contractPriceType_dialog {
  .el-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #DCDFE6;
    }
    .el-dialog__body {
      padding: 30px 40px 30px 60px !important;
    }
    .el-form-item__content {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-contractPriceType/index.vue"
}
</vue-filename-injector>
