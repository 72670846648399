import request from '@/plugin/axios'
// 主表分页查询
export function fetchList (query) {
  return request({
    url: '/basic/basicInquiry/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObj (obj) {
  return request({
    url: '/basic/basicInquiry/insertBasicInquiry',
    method: 'post',
    data: obj
  })
}
// 通过id删除主表
export function delObj (id) {
  return request({
    url: '/basic/basicInquiry/deleteBasicInquiry',
    method: 'post',
    params: id
  })
}
// 修改保存
export function putObj (obj) {
  return request({
    url: '/basic/basicInquiry/updateBasicInquiry',
    method: 'post',
    data: obj
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/basic/basicInquiry/queryBasicInquiryMessage/' + id,
    method: 'get'
  })
}
/*
** 运营指派保存
*/
export function assignSave (obj) {
  return request({
    url: '/basic/basicInquiry/assignmentBasicInquiry',
    method: 'post',
    params: obj
  })
}
/*
** 运力指派保存
*/
export function assignCapacitySave (obj) {
  return request({
    url: '/basic/basicInquiry/assignmentCapacityQuote',
    method: 'post',
    params: obj
  })
}
/*
** 判断当前用户是否为指派报价人
*/
export function judgeQuoteUser (obj) {
  return request({
    url: '/basic/basicInquiry/judgmentCurrentUserStatus',
    method: 'post',
    params: obj
  })
}
/*
* 通过id查询报价*
*/
export function trQuote (id) {
  return request({
    url: '/basic/basicInquiry/selectQuoteInformation/' + id,
    method: 'get'
  })
}
/*
* 通过id查询报价详情*
*/
export function trQuoteDetail (id) {
  return request({
    url: '/basic/basicInquiry/selectQuoteDetailsInformation/' + id,
    method: 'get'
  })
}
// 暂存保存
export function stagingSave (obj) {
  return request({
    url: '/basic/basicInquiry/inquiryInitiateQuotation',
    method: 'post',
    data: obj
  })
}
// 报价
export function quoteSave (data, type) {
  return request({
    url: '/basic/basicInquiry/insertInitiateQuotation',
    method: 'post',
    data: { basicInquiryQuote: data, quoteState: type }
  })
}
/*
** 判断发起报价是否过期 ( quoteState : 0.运力,1.运营,2.区总,3.行业主,4.市场总)
*/
export function judgeOverdue (id, quoteState) {
  return request({
    url: '/basic/basicInquiry/judgmentQuoteExpired/' + id + '/' + quoteState,
    method: 'get'
  })
}
/**
 * 获取导入返回值
 */
export function getImportData (key) {
  return request({
    url: '/basic/basicInquiry/revertImportInquiryDetails',
    method: 'post',
    params: key
  })
}
/**
 * 获取导入模板
 */
export function downloadTemplate (id, quoteState) {
  return request({
    url: '/basic/basicInquiry/exportQuoteTemplate',
    method: 'post',
    responseType: 'blob',
    params: { id: id, quoteState: quoteState }
  })
}
/**
 * 获取导入返回值
 */
export function getImportQuoteData (key) {
  return request({
    url: '/basic/basicInquiry/revertImportInitiationQuote',
    method: 'post',
    params: key
  })
}
/**
 * 计算更新公里数
 */
export function calculateCityKilometers (obj) {
  return request({
    url: '/basic/basicInquiry/calculateCityKilometers',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 初审
 */
export function trAudit (ids) {
  return request({
    url: '/basic/clientFreight/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function trUnaudit (ids) {
  return request({
    url: '/basic/clientFreight/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function trExamine (ids) {
  return request({
    url: '/basic/clientFreight/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function trUnexamine (ids) {
  return request({
    url: '/basic/clientFreight/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 启用/停用
 */
export function priceLock (ids, type) {
  return request({
    url: '/basic/clientFreight/enableStopState',
    method: 'post',
    params: { ids: ids, type: type }
  })
}
/**
 * 二级表导出*
 */
export function secondFloorExport (ids) {
  return request({
    url: '/basic/clientFreight/exportClientFreightTable',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 导入 返回结果
 */
export function returnImportPriceCus () {
  return request({
    url: '/basic/project/price/progress',
    method: 'get'
  })
}
// 预订单、托运单 自动计费 调用运价
// export function tmsydGetPrice (obj) {
//   return request({
//     url: '/basic/project/price/tmsyd',
//     method: 'post',
//     data: obj
//   })
// }
export function tmsydGetPrice (obj) {
  return request({
    url: '/basic/clientFreight/selectTmsYd',
    method: 'post',
    data: obj
  })
}
/*
** 判断运力运营指派是否指派
*/
export function judgeAssignState (obj) {
  return request({
    url: '/basic/basicInquiry/judgeAssignmentQuoteState',
    method: 'post',
    params: obj
  })
}
/**
 * 上传影像 保存到后台
 */
export function uploadAliInquiry (obj) {
  return request({
    url: '/tms/ossobject/uploadBasicInquiry',
    method: 'post',
    data: obj
  })
}
/*
** 判断是运力还是运营
*/
export function judgeCurrentState (id) {
  return request({
    url: '/basic/basicInquiry/judgmentCurrentAccount',
    method: 'post',
    params: { id: id }
  })
}
