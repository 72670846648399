import request from '@/plugin/axios'
export function fetchList (query) {
  return request({
    url: '/tms/tmsCustomers/queryTenderContract',
    method: 'get',
    params: query
  })
}
/**
 * 上传招标合同 保存到后台
 */
export function uploadTenderContract (obj) {
  return request({
    url: '/tms/ossobject/uploadTenderContract',
    method: 'post',
    data: obj
  })
}
export function addObj (obj) {
  return request({
    url: '/basic/scene',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/scene/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/scene/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/scene',
    method: 'put',
    data: obj
  })
}

export function sceneExport (ids) {
  return request({
    url: '/basic/scene/export',
    responseType: 'blob',
    method: 'get',
    params: ids
  })
}
