<template>
  <el-upload
      class="upload-ali-demo"
      ref="refUploadAli"
      action
      :data="uploadData"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :auto-upload="false"
      accept=".jpg,.jpeg,.png,.pdf,.gif,.xls,.xlsx,.doc,.docx,.avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4,.mp3,.wma,.flac,.ape,.wav,.aac,.m4a"
      :before-upload="beforeAvatarUpload"
      multiple
      :on-exceed="handleExceed"
      :on-change="handleChange"
      :on-success="onSuccess"
      :on-error="onError"
      :file-list="fileList">
    <el-button slot="trigger" size="mini" type="primary" icon="el-icon-folder-opened">选择文件</el-button>
<!--    <el-button style="margin-left: 10px;" size="mini" type="info" @click="submitUpload" :loading="loading">开始上传</el-button>-->
  </el-upload>
</template>
<script>

import store from '@/store/index'
import {
  uploadAli
} from '@/api/basic/customer'
import axios from 'axios'
// import dayjs from 'dayjs'
import { validatenull } from '@/tdcommon/validate'
// import { takePicture, dtaURItoBlob } from '@/tdcommon/beeutil'

export default {
  name: 'd2-container-uploadAli-addFile',
  data () {
    return {
      loading: false,
      // fileList: [
      //   // { name: '261微信图片_20210529092403.jpg', url: 'https://file.56tms.com/261微信图片_20210529092403.jpg' },
      //   // { name: '844微信图片_20210529092331.jpg', url: 'https://file.56tms.com/844微信图片_20210529092331.jpg' },
      //   // { name: '891微信图片_20210529092323.jpg', url: 'https://file.56tms.com/891微信图片_20210529092323.jpg' }
      // ],
      fileList: [],
      fileListMsg: [],
      fileOptions: [],
      formData: [],
      resultData: [],
      totalNumbers: 0,
      importNumbers: 0,
      filelistdata: {},
      uploadData: { module: this.customerType },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      uploadForm: [],
      delOssId: [],
      updateNo: '',
      updateType: '',
      uploadStatus: false,
      openLoading: false,
      auditStatus: '', // 上传审核状态
      auditText: '', // 已审核的文字提示
      uploadClassify: '' // 上传分类
    }
  },
  props: {
    fileListArray: {
      type: Array,
      default: () => []
    },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    uploadVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    // url: {
    //   type: String,
    //   default: () => '/basic/dtcustomer/import/yyd'
    // },
    // customerNo: {
    //   type: String,
    //   default: () => ''
    // },
    customerType: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    customerType: function (val, oldVal) {
      this.$set(this.uploadData, 'module', val)
    },
    fileListArray: function (val, oldVal) {
      this.fileList = val
    }
  },
  methods: {
    // 上传
    submitUpload (importType) {
      // this.loading = true
      if (!validatenull(this.fileList)) { // 判断文件是否为空
        this.fileOptions = []
        this.picUpload(this.fileList, importType)
        // this.fileList.forEach(item => {
        //   if (item.status !== 'success') {
        //     this.picUpload(item)
        //   }
        // })
      } else {
        this.$emit('afterUploadChange', [])
        // this.loading = false
      }
    },
    picUpload (fileList, importType) {
      let options = []
      fileList.forEach(file => {
        if (file.status !== 'success') {
          uploadAli().then(response => {
            var data = response.data
            var ossData = new FormData()
            // var imgType = file.raw.name.split('.')[1]
            let fileNamePoint = file.raw.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            let fileNameLength = file.raw.name.length // 取到文件名长度
            let imgType = file.raw.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
            // console.log('fileName', fileNamePoint + 1)
            // console.log('fileNameLength', fileNameLength)
            // console.log('imgType', imgType)
            // var fileName = this.updateNo + '_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.' + imgType // 上传阿里保存 文件名=单位编码+日期（年月日时分秒+毫秒3位数）+4位随机码+文件类型
            // var fileName = this.updateNo + Math.random().toString().substr(2, 3) + file.raw.name // 上传阿里保存 文件名=单位编码+3位随机码+原有文件名
            var fileName = file.raw.name // 上传阿里保存 文件名=单位编码+3位随机码+原有文件名
            ossData.append('name', fileName)
            // key就代表文件层级和阿里云上的文件名
            // let filename = file.file.name + file.file.size
            // let keyValue = data.dir + '/' + filename + '.' + imgType
            let keyValue = data.dir + Math.random().toString().substr(2, 3) + fileName
            ossData.append('key', keyValue)
            ossData.append('policy', data.policy)
            ossData.append('OSSAccessKeyId', data.accessid)
            ossData.append('success_action_status', 200)
            ossData.append('signature', data.signature)
            ossData.append('callback', data.callback)
            ossData.append('file', file.raw, file.raw.name)
            axios.post(data.host, ossData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(res => {
              let fileType = ''
              if (imgType === 'jpg' || imgType === 'jpeg' || imgType === 'png' || imgType === 'gif') { // .jpg,.jpeg,.png,.gif
                fileType = 'picture'
              } else if (imgType === 'xls' || imgType === 'xlsx' || imgType === 'doc' || imgType === 'docx' || imgType === 'pdf') { // xls,.xlsx,.doc,.docx,.pdf
                fileType = 'file'
              } else if (imgType === 'avi' || imgType === 'wmv' || imgType === 'mpeg' || imgType === 'mov' || imgType === 'rm' || imgType === 'rmvb' || imgType === 'flv' || imgType === 'mkv' || imgType === 'mp4') { // .avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4
                fileType = 'video'
              } else if (imgType === 'mp3' || imgType === 'wma' || imgType === 'flac' || imgType === 'ape' || imgType === 'wav' || imgType === 'aac' || imgType === 'm4a') { // .mp3,.wma,.flac,.ape,.wav,.aac,.m4a
                fileType = 'music'
              }
              if (res.status === 200) {
                let option = {}
                option.name = fileName
                option.url = 'https://file.56tms.com/' + keyValue
                option.status = 'success'
                option.uid = ''
                option.source = 'tms' // 来源
                option.itemType = importType // 类型
                option.fileType = fileType // 文件类型
                options.push(option)
              }
            }).catch(() => {
            })
          })
        }
      })
      this.fileOptions = options
      setTimeout(() => {
        this.$nextTick(() => {
          let options = this.fileList.concat(this.fileOptions).filter(item => item.status !== 'ready')
          this.$emit('afterUploadChange', options)
        })
      }, 1500)
      // return options
    },
    handleChange (file, fileList) {
      this.fileList = fileList
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    // 判断导入的格式是不是xls、xlsx、...，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      const isIMAGE = file.type === '.jpg' || '.jpeg' || '.png' || '.gif' // .jpg,.jpeg,.png,.gif
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isFile = testmsg === '.xls' || '.xlsx' || '.doc' || '.docx' || '.pdf'
      const isVideo = testmsg === '.avi' || '.wmv' || '.mpeg' || '.mov' || '.rm' || '.rmvb' || '.flv' || '.mkv' || '.mp4' // .avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4
      const isMusic = testmsg === '.mp3' || '.wma' || '.flac' || '.ape' || '.wav' || '.aac' || '.m4a' // .mp3,.wma,.flac,.ape,.wav,.aac,.m4a
      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isFile || !isVideo || !isMusic || !isIMAGE) {
        this.$message.error('上传文件格式不支持！')
        return false
      }
      // if (!isIMAGE) {
      //   this.$message.error('上传文件只能是图片格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 50MB!')
      }
      return (isIMAGE || isFile || isVideo || isMusic) && isLt2M
      // return isIMAGE
    },
    // 上传成功
    onSuccess (res, file, fileList) {
      // let val = ''
      // fileList.forEach(item => {
      //   if (val !== '') {
      //     val += ','
      //   }
      //   if (item.response) {
      //     val += item.response.data.downloadPath
      //   } else {
      //     val += item.url
      //   }
      // })
      // this.$emit('input', val)
      // this.uploadAliyun(res, file)
    },
    // 上传失败
    onError () {
    },
    // 获取客户编号
    getupdateNo (cusNo, type, auditStatus, classify, auditText) {
      this.updateNo = cusNo
      this.updateType = type
      this.auditStatus = auditStatus // 上传审核状态（'1'为已审核，'0'为未审核）
      this.uploadClassify = classify // 上传分类
      this.auditText = auditText // 已审核的文字提示
      this.$set(this.uploadData, 'objectNo', cusNo)
    },
    // 关闭时清空
    clearSrcList () {
      this.$refs.refUploadAli.clearFiles() // 清空上传文件列表
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.uploadali-el-card-style {
    .el-card__header {
        padding: 14px 20px !important;
        border-bottom: 0 !important;
    }
}
.uploadImage {
    position: relative;
    display: inline-block;
    margin-left: 17px;
    .optionMark {
        display: none;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50px;
        top: 50px;
        background:linear-gradient(to top left,rgba(0,0,0,.5) 40%,transparent 40%);

        .el-upload-list__item-actions {
            position: absolute;
            font-size: 15px;
            left: 6px;
            top: 26px;
            width: 100%;
            height: 100%;
            text-align: right;
            color: #fff;
            transition: opacity .3s;
        }
    }
}
.uploadImage:hover {
    .optionMark {
        display: block;
        z-index: 999;
        .el-upload-list__item-preview {
            cursor: pointer;
        }
    }
}
/*文件下载删除 鼠标移入样式改变*/
.file_download:hover{
    cursor: pointer;
    .file_download_delete{display: block};
}
/*文件下载删除按钮*/
.file_download_delete{
  display: none;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-uploadAli-addFile/index.vue"
}
</vue-filename-injector>
