<template>
  <el-autocomplete
    popper-class="td-autocomplete"
    v-model="values"
    style="width: 100%"
    :clearable="clearable"
    :disabled="disabled"
    :fetch-suggestions="querySearchAsync"
    placeholder='请输入内容'
    @change="changeValues"
    @select="handleSelect">
    <template slot-scope="{ item }">
      <span style="float: left;font-size: 13px"><i class="fa fa-gg-circle" :style="{'color':item.sourceType==='cus'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.objName }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.objCode }}</span>
    </template>
  </el-autocomplete>
</template>
<script>
import { firsOjbName } from '@/api/basic/comlist'
import { validatenull } from '@/tdcommon/validate'
export default {
  name: 'd2-container-address',
  data () {
    return {
      values: this.value,
      oldValue: '',
      restaurants: []
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    loadAll () {
    },
    querySearchAsync (queryString, cb) {
      var searchStr = validatenull(queryString) ? '' : queryString
      firsOjbName(searchStr, '').then(response => {
        if (response.code === 0) {
          this.restaurants = response.data
          this.oldValue = queryString
          cb(this.restaurants)
        } else {
          cb()
        }
      })
    },
    handleSelect (item) {
      this.values = item.objName
      this.$emit('change', item)
    },
    changeValues (item) {
      if (typeof item === 'string') {
        this.$emit('change', '')
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.values = val
    }
  },
  mounted () {
    this.loadAll()
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>
<style lang="scss">
/*el-autocomplete下拉加载框宽度设置*/
.el-autocomplete-suggestion {
    width: 355px !important;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-objinput/index.vue"
}
</vue-filename-injector>
