import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsPerformance/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsPerformance/insertTmsPerformance',
    method: 'post',
    data: obj
  })
}

export function tredit (id) {
  return request({
    url: '/tms/tmsPerformance/queryTmsPerformanceMessage/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsPerformance/updateTmsPerformance',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsPerformance/deleteTmsPerformance',
    method: 'delete',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function trExamine (ids) {
  return request({
    url: '/tms/tmsPerformance/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function trUnexamine (ids) {
  return request({
    url: '/tms/tmsPerformance/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 统计
 */
export function statisticsMsg () {
  return request({
    url: '/tms/tmsPerformance/performanceBondStatistics',
    method: 'post'
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliPerformance (obj) {
  return request({
    url: '/tms/ossobject/performance',
    method: 'post',
    data: obj
  })
}
/**
 * 履约保证金导入 返回结果
 */
export function returnPerformImport () {
  return request({
    url: '/tms/tmsPerformance/import/progress',
    method: 'get'
  })
}
