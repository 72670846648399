import request from '@/plugin/golde'
/***
 * 高德
 * @returns {AxiosPromise}
 * @constructor
 */
const key = '646f14ffb3994bf0de3d9a21632a6798'
/**
 * 通过IP取得地理位置
 * @returns {AxiosPromise}
 * @constructor
 */
export const IpLocation = () => {
  return request({
    url: '/v3/ip',
    method: 'get',
    params: Object.assign({
      key: key
    })
  })
}
/**
 * 输入信息查询相关地址
 * @returns {AxiosPromise}
 */
export const InputTips = (keywords) => {
  return request({
    url: '/v3/assistant/inputtips',
    method: 'get',
    params: Object.assign({
      key: key,
      keywords: keywords
    })
  })
}

/**
 * 输入信息查询相关地址
 * @returns {AxiosPromise}
 */
export const InputDistrict = (keywords, subdistrict) => {
  return request({
    url: '/v3/config/district',
    method: 'get',
    params: Object.assign({
      key: key,
      keywords: keywords,
      subdistrict: subdistrict
    })
  })
}
