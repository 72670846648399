import request from '@/plugin/axios'
/*
* 分页查询*
*/
export function fetchList (query) {
  return request({
    url: '/tms/billincome/page',
    method: 'post',
    data: query
  })
}
/*
*通过id删除*
*/
export function delObj (id) {
  return request({
    url: '/tms/billincome/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids进行审单
 */
export function fpAudit (ids) {
  return request({
    url: '/tms/billincome/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function fpUnaudit (ids) {
  return request({
    url: '/tms/billincome/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function fpExamine (ids) {
  return request({
    url: '/tms/billincome/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function fpUnexamine (ids) {
  return request({
    url: '/tms/billincome/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过id进行作废
 */
export function fpcancell (id) {
  return request({
    url: '/tms/billincome/invalid/' + id,
    method: 'get'
  })
}
/**
 * 通过id进行作废红冲撤销
 */
export function fprescind (id) {
  return request({
    url: '/tms/billincome/cancelRemoveFms/' + id,
    method: 'get'
  })
}
/**
 * 通过ids进行反作废
 */
export function fpUncancell (ids) {
  return request({
    url: '/tms/accfp/invalidundo',
    method: 'post',
    params: ids
  })
}
/**
 * 修改保存
 */
export function fpupdate (obj) {
  return request({
    url: '/tms/billincome',
    method: 'put',
    data: obj
  })
}
/**
 * 新增保存
 */
export function fpsava (obj) {
  return request({
    url: '/tms/billincome',
    method: 'post',
    data: obj
  })
}
/**
 * 库存信息
 */
export function fpoutpage (query) {
  return request({
    url: '/tms/billincomedetail/getBillIncomeDetailListByCondition',
    method: 'post',
    data: query
  })
}
/**
 * 传参取得库存
 */
export function fpgenerate (obj) {
  return request({
    url: '/tms/accfp/search/verify',
    method: 'post',
    data: obj
  })
}
/**
 * 通过搜索客户名称 下拉option
 */
export function getsalesman (search) {
  return request({
    url: '/tms/accfprealcompany/list',
    method: 'get',
    params: { companyName: search }
  })
}
/**
 *
 库存预装删除操作
 */
export function fpoutdel (id) {
  return request({
    url: '/tms/accfp/details/remove/' + id,
    method: 'get'
  })
}
/**
 * 发票登记
 */
export function fpregister (obj) {
  return request({
    url: '/tms/billincome/register',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function fpedit (id) {
  return request({
    url: '/tms/billincome/' + id,
    method: 'get'
  })
}
/**
 * 发票明细导出*
 */
export function fpDetailsExport (ids) {
  return request({
    url: '/tms/billincome/export',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 打印 开票申请单
 */
export function printKpApplication (obj) {
  return request({
    url: '/tms/billincome/billingApplication',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 打印 发票签收单
 */
export function printFpSign (obj) {
  return request({
    url: '/tms/billincome/invoiceReceipt',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 销项发票跳转客户结算 获取销项发票运单明细数据
 */
export function toCussettle (ids) {
  return request({
    url: '/tms/billincome/settle/details',
    method: 'get',
    params: ids
  })
}
/*
* 上传阿里 对账单*
*/
export function uploadAliReceivableStatement (obj) {
  return request({
    url: '/tms/ossobject/billIncomeStatements',
    method: 'post',
    data: obj
  })
}
/*
* 上传阿里 签收单*
*/
export function uploadAliReceivableSignature (obj) {
  return request({
    url: '/tms/ossobject/billIncomeSignStatements',
    method: 'post',
    data: obj
  })
}
/*
* 批量上传 签收单*
*/
export function uploadAliReceivableSignatureBatch (obj) {
  return request({
    url: '/tms/ossobject/batchBillInSignStatements',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 批量下载
 */
export function aliBatchSalesDownload (obj) {
  return request({
    url: '/tms/ossobject/salesInvoiceAli',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 通过ids进行上传阿里 对账单审核
 */
export function uploadAliStatement (ids) {
  return request({
    url: '/tms/billincome/accountStatementFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 对账单反审
 */
export function uploadAliUnStatement (ids) {
  return request({
    url: '/tms/billincome/accountStatementUndoFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 签收单审核
 */
export function uploadAliReceiptForm (ids) {
  return request({
    url: '/tms/billincome/receiptFormFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行上传阿里 签收单反审
 */
export function uploadAliUnReceiptForm (ids) {
  return request({
    url: '/tms/billincome/receiptFormFpIdsUndoFpIds',
    method: 'post',
    params: ids
  })
}
/**
 * 寄件登记
 */
export function mailRegister (obj) {
  return request({
    url: '/tms/billincome/mailRegisterContent',
    method: 'post',
    data: obj
  })
}
/**
 * 通过搜索客户名称 下拉option
 */
export function gethaiercustomer (search) {
  return request({
    url: '/tms/haiercustomer/getHaireCustomerByName',
    method: 'get',
    params: { searchStr: search }
  })
}
/**
 * 企业微信审批
 */
export function dingtalk (processCode, id) {
  return request({
    url: '/tms/billincome/dingtalk/' + processCode + '/' + id,
    method: 'get'
  })
}
/*
* 通过id、红冲单号 红冲*
*/
export function redDashed (obj) {
  return request({
    url: '/tms/billincome/redDashed',
    method: 'post',
    params: obj
  })
}
/*
* 发票签收单提醒*
*/
export function settlementReceivableBill () {
  return request({
    url: '/tms/billincome/settlementReceivableBill',
    method: 'post'
  })
}
/**
 * 获取导入返回值
 */
export function getImportData (key) {
  return request({
    url: '/tms/billSales/revertImportBillSales',
    method: 'post',
    params: key
  })
}
/**
 * 发票明细导出*
 */
export function exportBillSales (ids) {
  return request({
    url: '/tms/billSales/exportBillSales',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 查询拆分明细*
 */
export function searchBillSummary (id) {
  return request({
    url: '/tms/billincome/bDetailGroup/' + id,
    method: 'get'
  })
}
/**
 * 拆分明细导出*
 */
export function exportBillSummary (id) {
  return request({
    url: '/tms/billincome/bDGroupExport/' + id,
    method: 'get',
    responseType: 'blob'
  })
}
