<template>
  <div class="uploadAli-applet">
    <el-upload
        style="width: 200px;"
        action="#"
        v-if="uploadType === 'picture'"
        :ref="uploadRefs"
        list-type="picture-card"
        :auto-upload="false"
        :multiple="false"
        :limit="1"
        accept=".jpg,.jpeg,.png"
        :before-upload="beforeAvatarUploadPicture"
        :on-change="onChange"
        :file-list="fileList">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="imageUrls" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过50MB</div>
    </el-upload>
    <el-upload
        v-if="uploadType === 'file'"
        action="#"
        :ref="uploadRefs"
        :auto-upload="false"
        :multiple="false"
        :limit="1"
        accept=".pdf,.xls,.xlsx,.doc,.docx"
        :before-upload="beforeAvatarUpload"
        :before-remove="beforeRemove"
        :on-change="handleFileChange"
        :on-remove="handleFileRemove"
        :file-list="fileList">
      <el-button size="mini" type="primary" :disabled="!validateNull(fileList)">点击上传</el-button>
      <el-button v-if="!validateNull(fileList) && !disabled" size="mini" type="success" icon="el-icon-download" :disabled="disabled" @click="handleFileDownload">下载</el-button>
      <div slot="tip" class="el-upload__tip">只能上传xls/xlsx/doc/docx/pdf文件，且不超过50MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>

import store from '@/store/index'
import { uploadAli } from '@/api/basic/customer'
import axios from 'axios'
import dayjs from 'dayjs'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-uploadAli-applet',
  data () {
    return {
      loading: false,
      fileList: this.getFileList(),
      fileListMsg: this.getFileList(),
      uploadData: { module: this.uploadRef },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      updateNo: '',
      updateType: '',
      uploadClassify: '', // 上传分类
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrls: this.fileUrl,
      uploadRefs: this.uploadRef
    }
  },
  props: {
    uploadRef: {
      type: String,
      default: () => ''
    },
    uploadType: {
      type: String,
      default: () => ''
    },
    fileUrl: {
      type: String,
      default: () => ''
    },
    fileName: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    examineType: {
      type: String,
      default: () => ''
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    validateNull (val) {
      return validatenull(val)
    },
    getFileList () {
      let fileList = []
      if (!validatenull(this.fileUrl)) {
        var obj = {
          name: this.fileName,
          url: this.fileUrl,
          id: ''
        }
        fileList.push(obj)
        if (this.uploadType === 'picture') {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[this.uploadRefs].$children[1].$el.style.display = 'none'
            }, 100)
          })
        }
      }
      return fileList
    },
    onChange (file, fileList) {
      if (file.status === 'fail') {
        this.$refs[this.uploadRefs].clearFiles()
        this.imageUrls = ''
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs[this.uploadRefs].$children[0].$el.style.display = 'none'
            this.$refs[this.uploadRefs].$children[1].$el.style.display = ''
            this.$emit('change', this.uploadRefs, '', '')
          }, 100)
        })
      } else {
        let fileNamePoint = file.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
        let fileNameLength = file.name.length // 取到文件名长度
        let imgType = file.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
        const isIMAGE = imgType === 'jpg' || imgType === 'jpeg' || imgType === 'png' || imgType === 'gif' // .jpg,.jpeg,.png,.gif
        const isLt2M = file.size / 1024 / 1024 < 50
        if (!isIMAGE) {
          this.$message.error('上传文件只能是图片格式!')
          this.clearImgList()
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 50MB!')
          this.clearImgList()
          return false
        }
        this.imageUrls = file.url
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs[this.uploadRefs].$children[0].$el.style.display = ''
            this.$refs[this.uploadRefs].$children[1].$el.style.display = 'none'
          }, 100)
        })
        this.picUpload(file, this.uploadRefs)
      }
    },
    clearImgList () {
      this.$refs[this.uploadRefs].clearFiles()
      this.imageUrls = ''
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.uploadRefs].$children[0].$el.style.display = 'none'
          this.$refs[this.uploadRefs].$children[1].$el.style.display = ''
          this.$emit('change', this.uploadRefs, '', '')
        }, 100)
      })
    },
    handleRemove (file) {
      this.$refs[this.uploadRefs].clearFiles()
      this.imageUrls = ''
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.uploadRefs].$children[0].$el.style.display = 'none'
          this.$refs[this.uploadRefs].$children[1].$el.style.display = ''
          this.$emit('change', this.uploadRefs, '', '')
        }, 100)
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.imageUrls = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      this.downFn(file)
    },
    handleFileChange (file, fileList) {
      if (file.status === 'fail') {
        this.$refs[this.uploadRefs].clearFiles()
        this.fileList = []
        this.fileListMsg = []
        this.$emit('change', this.uploadRefs, '', '')
      } else {
        let fileNamePoint = file.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
        let fileNameLength = file.name.length // 取到文件名长度
        let testmsg = file.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
        const isFile = testmsg === 'xls' || testmsg === 'xlsx' || testmsg === 'doc' || testmsg === 'docx' || testmsg === 'pdf'
        const isLt2M = file.size / 1024 / 1024 < 50
        if (!isFile) {
          this.$message.error('上传文件格式不支持！')
          this.clearFileList()
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 50MB!')
          this.clearFileList()
          return false
        }
        this.fileList = fileList
        this.fileListMsg = fileList
        this.picUpload(file, this.uploadRefs)
      }
    },
    clearFileList () {
      this.$refs[this.uploadRefs].clearFiles()
      this.fileList = []
      this.fileListMsg = []
      this.$emit('change', this.uploadRefs, '', '')
    },
    handleFileRemove (file, fileList) {
      this.fileList = []
      this.fileListMsg = []
      this.$emit('change', this.uploadRefs, '', '')
    },
    // 上传
    picUpload (file, type) {
      uploadAli().then(response => {
        var data = response.data
        var ossData = new FormData()
        let dateValue = dayjs(new Date()).format('YYYYMMDD')
        var fileName = dateValue + Math.random().toString().substr(2, 3) + file.raw.name // 上传阿里保存 文件名=单位编码+3位随机码+原有文件名
        ossData.append('name', fileName)
        // key就代表文件层级和阿里云上的文件名
        let keyValue = data.dir + fileName
        ossData.append('key', keyValue)
        ossData.append('policy', data.policy)
        ossData.append('OSSAccessKeyId', data.accessid)
        ossData.append('success_action_status', 200)
        ossData.append('signature', data.signature)
        ossData.append('callback', data.callback)
        ossData.append('file', file.raw, file.raw.name)
        axios.post(data.host, ossData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$message.success('上传成功！')
            }, 100)
            this.$emit('change', type, file.raw.name, 'https://file.56tms.com/' + keyValue)
            let obj = {
              name: file.raw.name,
              url: 'https://file.56tms.com/' + keyValue,
              id: ''
            }
            this.fileListMsg = []
            this.fileListMsg.push(obj)
          }
        }).catch(() => {
        })
      })
    },
    beforeRemove (file, fileList) {
      if (this.examineType === 'examine') {
        this.$message.error('审核页面禁止删除！')
        return false
      }
      if (this.examineType === 'detail') {
        this.$message.error('详情页面禁止删除！')
        return false
      }
    },
    beforeAvatarUploadPicture (file, fileList) {
      const isIMAGE = file.type === '.jpg' || '.jpeg' || '.png' || '.gif' // .jpg,.jpeg,.png,.gif
      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 50MB!')
      }
      return isIMAGE && isLt2M
    },
    // 判断导入的格式是不是xls、xlsx、...，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isFile = testmsg === '.xls' || '.xlsx' || '.doc' || '.docx' || '.pdf'
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isFile) {
        this.$message.error('上传文件格式不支持！')
      }
      // if (!isIMAGE) {
      //   this.$message.error('上传文件只能是图片格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 50MB!')
      }
      return isFile && isLt2M
      // return isIMAGE
    },
    listString (imageList) {
      var imageArray = []
      imageList.forEach(item => {
        imageArray.push(item.url)
      })
      return imageArray
    },
    // 下载图片
    downFn (file) {
      let Img = new Image()
      let dataURL = ''
      let fileName = file.name
      let fileNamePoint = file.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      let fileNameLength = file.name.length // 取到文件名长度
      let imgType = file.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
      Img.src = file.url
      // 处理跨域
      Img.setAttribute('crossOrigin', 'Anonymous')
      // 监听图片加载好
      Img.onload = function () {
        let canvas = document.createElement('canvas')
        let width = Img.width
        let height = Img.height
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height)
        dataURL = canvas.toDataURL('image/' + imgType)
        let eleLink = document.createElement('a')
        eleLink.download = fileName
        eleLink.style.display = 'none'
        eleLink.href = dataURL
        document.body.appendChild(eleLink)
        eleLink.click()
        document.body.removeChild(eleLink)
      }
    },
    // 下载返回文件
    handleFileDownload (option, index, val) {
      let file = this.fileListMsg[0]
      let fileName = this.fileListMsg[0].name
      let fileNamePoint = file.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      let fileNameLength = file.name.length // 取到文件名长度
      let imgType = file.name.substring(fileNamePoint + 1, fileNameLength) // 截

      if (imgType === 'pdf') { // 如果文件类型是pdf
        window.open(file.url)
      } else { // 如果是其他文件类型
        const link = document.createElement('a')
        link.download = fileName
        link.style.display = 'none'
        link.href = file.url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}
</script>

<style lang="scss">
.uploadAli-applet {
  .el-upload--picture-card {
    width: 200px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 200px;
  }
}
.uploadali-el-card-style {
    .el-card__header {
        padding: 14px 20px !important;
        border-bottom: 0 !important;
    }
    .el-upload-list {
      max-height: 233px;
      overflow-y: auto;
    }
}
.uploadImage {
    position: relative;
    display: inline-block;
    margin-left: 17px;
    .optionMark {
        display: none;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50px;
        top: 50px;
        background:linear-gradient(to top left,rgba(0,0,0,.5) 40%,transparent 40%);

        .el-upload-list__item-actions {
            position: absolute;
            font-size: 15px;
            left: 6px;
            top: 26px;
            width: 100%;
            height: 100%;
            text-align: right;
            color: #fff;
            transition: opacity .3s;
        }
    }
}
.uploadImage:hover {
    .optionMark {
        display: block;
        z-index: 999;
        .el-upload-list__item-preview {
            cursor: pointer;
        }
    }
}
.file_download {
  display: flex;
  justify-content: center;
  align-items: center;
  //border-bottom: 1px solid #EBEEF5;
}
/*文件下载删除 鼠标移入样式改变*/
.file_download:hover{
    cursor: pointer;
    .file_download_delete{display: block};
}
/*文件下载删除按钮*/
.file_download_delete{
  display: none;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-uploadAli-applet/index.vue"
}
</vue-filename-injector>
