<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :remote-method="remoteMethod"
             :clearable="clearable"
             :disabled="disabled"
             :loading="loading"
             :multiple="multiple">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.customerName"
      :value="item.id"
      @click.native="handleSelect(item)">
      <span><i class="fa fa-gg-circle" :style="{'color': '#66b1ff','margin-right': '5px'}"></i>{{ item.customerName }}</span>
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { cusAllName } from '@api/basic/customer'
// 应收账单 项目名称id下拉搜索
export default {
  name: 'd2-container-senderId',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (val, query) {
      this.loading = true
      cusAllName(val).then(response => {
        this.loading = false
        if (response.code === 0) {
          let options = []
          response.data.forEach(item => {
            let option = {}
            option.customerName = item.customerName
            option.id = item.id.toString()
            option.customerNo = item.customerNo
            options.push(option)
          })
          this.options = options
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.values = validatenull(val) ? '' : val
      this.remoteMethod(this.values)
      if (validatenull(this.values)) {
        this.$emit('change', '')
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-senderId/index.vue"
}
</vue-filename-injector>
