import Vue from 'vue'
import VueRouter from 'vue-router'

// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store/index'
import util from '@/libs/util.js'

// 路由数据
import routes from './routes'
import menuAside from '@/menu/aside'
import { frameInRoutes, frameErrorRoutes, frameOutRoutes, frameEditRoutes } from '@/router/routes'
// import fa from 'element-ui/src/locale/lang/fa'

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

// 定义一个新的路由规则
const constantRouterMap = [...frameInRoutes, ...frameOutRoutes, ...frameEditRoutes]

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes
})

// 路由动态 添加状态
let routerStatus = true

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  // 确认已经加载多标签页数据
  await store.dispatch('d2admin/page/isLoaded')
  // 确认已经加载组件尺寸设置
  await store.dispatch('d2admin/size/isLoaded')
  // 进度条
  NProgress.start()
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some(r => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const token = store.getters.access_token
    if (token && token !== 'undefined') {
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        // 判断是否已有相关菜单权限信息
        if (store.getters.roles.length === 0) {
          // 取得用户信息
          store.dispatch('d2admin/account/GetUserInfo').then(() => {
            // 取得用户对应权限菜单信息
            store.dispatch('d2admin/account/GetMenu').then(() => {
              // 处理路由 得到每一级的路由设置
              store.commit('d2admin/page/init', [...frameInRoutes, ...frameEditRoutes, ...store.getters.userRoutes])
              // 设置侧边栏菜单
              store.commit('d2admin/menu/asideSet', [...menuAside, ...store.getters.menu])
              // 初始化菜单搜索功能
              store.commit('d2admin/search/init', [...menuAside, ...store.getters.menu])
              // 动态添加路由
              router.$addRoutes(store.getters.userRoutes)
              next({ ...to })
            }).catch(() => {
              store.dispatch('d2admin/account/FedLogOut').then(() => {
                next({ path: '/login' })
              })
            })
          }).catch(() => {
            store.dispatch('d2admin/account/FedLogOut').then(() => {
              next({ path: '/login' })
            })
          })
        } else {
          next()
        }
      }
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
      NProgress.done()
    }
  } else {
    if (typeof to.matched !== 'undefined' && to.matched.length === 0 && (store.getters.access_token === 'undefined' || store.getters.access_token.length === 0)) {
      next({ path: '/login' })
    } else {
      next()
    }
  }
})

router.afterEach(to => {
  // 进度条
  NProgress.done()
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to)
  // 更改标题
  util.title(to.meta.title)
})

router.$addRoutes = (params) => {
  router.matcher = new VueRouter({
    routes: constantRouterMap
  }).matcher
  routerStatus = false
  try {
    router.addRoutes(params)
    router.addRoutes(frameErrorRoutes)
  } catch (error) {
    console.log(error)
  }
}

// 完成初始导航
router.onReady(() => {
  if (store.getters.userRoutes.length > 0) {
    // 处理路由 得到每一级的路由设置
    store.commit('d2admin/page/init', [...frameInRoutes, ...frameEditRoutes, ...store.getters.userRoutes])
    if (routerStatus) {
      router.addRoutes(store.getters.userRoutes)
      router.addRoutes(frameErrorRoutes)
    }
  }
})

export default router
