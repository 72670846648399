import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmssg/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmssg',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmssg/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmssg/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmssg',
    method: 'put',
    data: obj
  })
}

/**
 * 事故 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmssg/delsgids',
    method: 'post',
    params: ids
  })
}
/**
 * 事故 批量审单
 */
export function auditExeam (ids) {
  return request({
    url: '/tms/tmssg/auditExeam',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反审单
 */
export function unauditExeam (ids) {
  return request({
    url: '/tms/tmssg/unauditExeam',
    method: 'post',
    params: ids
  })
}
/**
 * 事故 批量审核
 */
export function audit (ids) {
  return request({
    url: '/tms/tmssg/audit',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反审核
 */
export function auditundo (ids) {
  return request({
    url: '/tms/tmssg/auditundo',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量复审
 */
export function retrial (ids) {
  return request({
    url: '/tms/tmssg/retrial',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反复审
 */
export function unretrial (ids) {
  return request({
    url: '/tms/tmssg/unretrial',
    method: 'post',
    params: ids
  })
}

/*
* 通过托运单号查询*
*/
export function getSgListByTydId (ydNo) {
  return request({
    url: '/tms/tmssg/yddetails',
    method: 'get',
    params: ydNo
  })
}

/**
 * 上传阿里 事故中心保存到后台
 */
export function addUploadAliAccident (obj) {
  return request({
    url: '/tms/ossobject/accident',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printSg (obj) {
  return request({
    url: '/tms/tmssg/reportPrinting',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 打印
 */
export function getCostNo (eventType, ydNo) {
  return request({
    url: '/tms/tmssg/getDocNo',
    method: 'get',
    params: { eventType: eventType, ydNo: ydNo }
  })
}

/**
 * 保存事故处理结果
 * @param obj
 * @returns {AxiosPromise}
 */
export function detailsAdd (obj) {
  return request({
    url: '/tms/tmssg/details/add',
    method: 'post',
    data: obj
  })
}
/**
 * 更新事故处理结果
 * @param obj
 * @returns {AxiosPromise}
 */
export function detailsUpdate (obj) {
  return request({
    url: '/tms/tmssg/details/update',
    method: 'post',
    data: obj
  })
}
/**
 * 通过id取得事故订单及处理结果
 * @param obj
 * @returns {AxiosPromise}
 */
export function getSgById (obj) {
  return request({
    url: '/tms/tmssg/details/getSgById',
    method: 'get',
    params: { sgId: obj }
  })
}

export function delSgById (obj) {
  return request({
    url: '/tms/tmssg/details/delSgById',
    method: 'get',
    params: { sgId: obj }
  })
}

export function colseById (ids) {
  return request({
    url: '/tms/tmssg/colseById',
    method: 'post',
    params: ids
  })
}

export function openById (ids) {
  return request({
    url: '/tms/tmssg/openById',
    method: 'post',
    params: ids
  })
}

export function getGodByDId (id) {
  return request({
    url: '/tms/tmssg/details/' + id,
    method: 'get'
  })
}
export function exportSgTable (obj) {
  return request({
    url: '/ex/export/exportSgPrimaryInformation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
export function exportSgSecondTable (obj) {
  return request({
    url: '/ex/export/exportSgInformation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 修改结案信息
 */
export function updateCase (obj) {
  return request({
    url: '/tms/tmssg/editFinishInfo',
    method: 'post',
    data: obj
  })
}
/**
 * 事故结案 审单
 */
export function auditExeamCase (ids) {
  return request({
    url: '/tms/tmssg/finishAuditExeam',
    method: 'post',
    params: ids
  })
}

/**
 * 事故结案 反审单
 */
export function unauditExeamCase (ids) {
  return request({
    url: '/tms/tmssg/finishUnauditExeam',
    method: 'post',
    params: ids
  })
}
/**
 * 事故结案 审核
 */
export function auditCase (ids) {
  return request({
    url: '/tms/tmssg/finishAudit',
    method: 'post',
    params: ids
  })
}

/**
 * 事故结案 反审核
 */
export function auditundoCase (ids) {
  return request({
    url: '/tms/tmssg/finishAuditundo',
    method: 'post',
    params: ids
  })
}

/**
 * 事故结案 复审
 */
export function retrialCase (ids) {
  return request({
    url: '/tms/tmssg/finishRetrial',
    method: 'post',
    params: ids
  })
}

/**
 * 事故结案 反复审
 */
export function unretrialCase (ids) {
  return request({
    url: '/tms/tmssg/finishUnretrial',
    method: 'post',
    params: ids
  })
}
