<template>
  <div>
    <el-tooltip
        effect="dark"
        content="导出记录"
        placement="bottom">
      <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
        <d2-icon name="download" style="font-size: 18px;"/>
      </el-button>
    </el-tooltip>
    <d2-export-records ref="export_table" :exportRecordsVisible="exportRecordsVisible" @exportHandleClose="exportHandleClose"></d2-export-records>
  </div>
</template>

<script>
export default {
  data () {
    return {
      exportRecordsVisible: false
    }
  },
  methods: {
    handleClick () {
      // this.$emit('click')
      console.log('导出记录')
      this.exportRecordsVisible = true
      this.$refs.export_table.remoteMethod()
    },
    exportHandleClose () {
      this.exportRecordsVisible = false
    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-export-records/index.vue"
}
</vue-filename-injector>
