<template>
  <el-select
    v-model="state"
    filterable
    remote
    reserve-keyword
    placeholder="请输入关键词"
    :remote-method="remoteMethod"
    :clearable="clearable"
    :disabled="disabled"
    :loading="loading">
    <el-option
      v-for="item in restaurants"
      :key="item.name"
      :label="item.name"
      :value="item.name">
    </el-option>
  </el-select>
</template>
<script>
import { InputDistrict } from '@/api/sys.golde'

export default {
  name: 'd2-container-district',
  data () {
    return {
      LocationName: '中国',
      // 查询结果
      restaurants: [],
      state: this.value,
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    loadAll () {
      InputDistrict(this.LocationName, 1).then(response => {
        if (response.info === 'OK') {
          this.restaurants = response.districts[0].districts
        } else {
          this.restaurants = []
        }
      }).catch(() => {
        this.restaurants = []
      })
    },
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        InputDistrict(query, 0).then(response => {
          this.loading = false
          if (response.info === 'OK' && response.districts.length > 0) {
            this.restaurants = response.districts
          } else {
            this.restaurants = []
          }
        }).catch(() => {
          this.loading = false
          this.restaurants = []
        })
      } else {
        this.restaurants = []
      }
    },
    handleChange (value) {
    }
  },
  watch: {
    state: function (val, oldVal) {
      this.$emit('input', val)
    }
  },
  mounted () {
    this.loadAll()
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-district/index.vue"
}
</vue-filename-injector>
