import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmstransaction/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmstransaction',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmstransaction/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmstransaction/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmstransaction',
    method: 'put',
    data: obj
  })
}

/**
 * 通过ids批量删除运单
 */
export function deltrids (ids) {
  return request({
    url: '/tms/tmstransaction/deltrids',
    method: 'post',
    params: ids
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/tms/tmstransaction/tredit/' + id,
    method: 'get'
  })
}

/**
 * 新增异动单保存
 */
export function savetr (obj) {
  return request({
    url: '/tms/tmstransaction/savetr',
    method: 'post',
    data: obj
  })
}
/*
* 更新异动单
*/
export function updatetr (obj) {
  return request({
    url: '/tms/tmstransaction/updatetr',
    method: 'post',
    data: obj
  })
}
/*
* 中转单详情接口*
*/
export function getTrDetails (id) {
  return request({
    url: '/tms/tmstransaction/trdetails/' + id,
    method: 'get'
  })
}
/**
 * 通过ids进行中转单审单
 */
export function trAudit (ids) {
  return request({
    url: '/tms/tmstransaction/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单反审单
 */
export function trUnaudit (ids) {
  return request({
    url: '/tms/tmstransaction/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行异动单复审
 */
export function trRetrial (ids) {
  return request({
    url: '/tms/tmstransaction/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行异动单反复审
 */
export function trUnretrial (ids) {
  return request({
    url: '/tms/tmstransaction/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单审核
 */
export function trExamine (ids) {
  return request({
    url: '/tms/tmstransaction/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单反审核
 */
export function trUnexamine (ids) {
  return request({
    url: '/tms/tmstransaction/unexamine',
    method: 'post',
    params: ids
  })
}
/*
* 通过托运单ID查询*
*/
export function getYdListByTydId (ydNo) {
  return request({
    url: '/tms/tmstransaction/yddetails',
    method: 'get',
    params: ydNo
  })
}

/**
 * 上传阿里 异动保存到后台
 */
export function addUploadAliAberrant (obj) {
  return request({
    url: '/tms/ossobject/aberrant',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printTransaction (obj) {
  return request({
    url: '/tms/tmstransaction/reportPrinting',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 二级表导出*
 */
export function secondFloorExport (obj) {
  return request({
    url: ' /tms/tmstransaction/secondFloorExport',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 会计科目 获取树形数据
 */
export function fetchTree (query) {
  return request({
    url: '/tms/tmstransaction/changeClassificationModular',
    method: 'get',
    data: query
  })
}

/**
 * 企业微信审批
 */
export function dingtalk (processCode, id) {
  return request({
    url: '/tms/tmstransaction/dingtalk/' + processCode + '/' + id,
    method: 'get'
  })
}
export function getYdDetails (obj) {
  return request({
    url: '/tms/tmstransaction/ydhjDetaile',
    method: 'post',
    data: obj
  })
}
/**
 * 获取收支大类
 */
export function getFeeType () {
  return request({
    url: '/basic/arap/getList',
    method: 'get'
  })
}
/**
 * 根据收支大类 带出税率
 */
export function getFeeRate (obj) {
  return request({
    url: '/basic/arap/selectIncomeExpenditure',
    method: 'post',
    params: obj
  })
}
/**
 * 导出表格数据
 */
export function exportFyTable (obj) {
  return request({
    url: '/ex/export/exportFyInformation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 导出二级表
 */
export function exportSecondFloorTable (obj) {
  return request({
    url: '/ex/export/secondFloorExport',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
