<template class="pricingType_style">
  <el-row>
    <el-col :span="leftSpan">
      <el-select class="td_select"
                 v-model="values"
                 placeholder="请选择"
                 :clearable="clearable"
                 :disabled="disabled">
      <el-option
          class="td_option"
          v-for="(item) in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          @click.native="handleClick('select')">
      </el-option>
    </el-select>
    </el-col>
    <el-col :span="rightSpan">
      <el-button size="mini" type="primary" style="padding: 5px 12px;border-radius: 4px;" :disabled="disabled" @click="handleClick('button')">自动计费</el-button>
    </el-col>
  </el-row>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
// 托运单计费方式+自动计费按钮
// import {validatenull} from "@/tdcommon/validate";

export default {
  name: 'd2-container-priceType',
  data () {
    return {
      values: '',
      options: [
        // { value: '0', label: '件数' },
        // { value: '1', label: '重量' },
        // { value: '2', label: '体积' },
        // { value: '3', label: '货物-件数' },
        // { value: '4', label: '货物-重量' },
        // { value: '5', label: '货物-体积' }
        { value: '0', label: '零担件数' },
        { value: '1', label: '零担重量' },
        { value: '2', label: '零担体积' },
        { value: '3', label: '整车车长' },
        { value: '4', label: '一票一议' }
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    leftSpan: {
      type: Number,
      default: () => 14
    },
    rightSpan: {
      type: Number,
      default: () => 6
    },
    mdmStr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleClick (type) {
      this.$emit('change', type)
    },
    getStartValues (val) {
      if (!validatenull(this.mdmStr)) {
        let model = this.$parent.elForm.model[this.mdmStr]
        if (!validatenull(model)) {
          this.values = model
        } else {
          this.values = ''
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.values = val
    }
  },
  mounted () {
    this.getStartValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
.pricingType_style {
  .el-form-item__error {
    left: 40% !important;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-pricingType/index.vue"
}
</vue-filename-injector>
