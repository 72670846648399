import Vue from 'vue'

import d2Container from './d2-container'
import d2ContainerFrame from './d2-container-frame'
import d2ContainerQuery from './d2-container-query'
import d2ContainerQuerySetting from './d2-container-query-setting'
import d2ContainerTableHeader from './d2-container-table-header'
import d2ContainerMoreButton from './d2-container-more-button'
import d2ContainerTable from './d2-container-table'
import d2ContainerData from './d2-container-data'
import d2ContainerDataEdit from './d2-container-data-edit'
import d2ContainerDataMulHeader from './d2-container-data-mulheader'
import d2ContainerDataMulHeaderEdit from './d2-container-data-mulheader-edit'
import d2ContainerDataDynamic from './d2-container-data-dynamic'
import d2ContainerTableSingle from './d2-container-table-single'
import d2ContainerEdit from './d2-container-edit'
import d2ContainerPagination from './d2-container-pagination'
import d2ContainerAddress from './d2-container-address'
import d2ContainerDistrict from './d2-container-district'
import d2ContainerCascader from './d2-container-cascader'
import d2ContainerSubject from './d2-container-subject'
import d2ContainerSubjectEdit from './d2-container-subject-edit'
import d2ContainerMdmselect from './d2-container-mdmselect'
import d2ContainerObjinput from './d2-container-objinput'
import d2ContainerMember from './d2-container-member'
import d2ContainerEmployee from './d2-container-employee'
import d2ContainerCussup from './d2-container-cussup'
import d2ContainerSettlemdm from './d2-container-settlemdm'
import d2ContainerBusSup from './d2-container-bussup'
import d2ContainerBank from './d2-container-bank'
import d2ContainerTableSetting from './d2-container-table-setting'
import d2ContainerBankMoreSelect from './d2-container-bank-moreselect'
import d2ContainerDept from './d2-container-dept'
import d2ContainerForwardingUnit from './d2-container-forwarding-unit'
import d2ContainerReceivingUnit from './d2-container-receiving-unit'
import d2ContainerDeptMoreSelect from './d2-container-dept-moreselect'
import d2ContainerCompanySelect from './d2-container-company-moreselect'
import d2ContainerBuyer from './d2-container-buyer'
import d2ContainerProgress from './d2-container-progress'
import d2ContainerImport from './d2-container-import'
import d2ContainerImportDetail from './d2-container-import-detail'
import d2ContainerImportKy from './d2-container-import-ky'
import d2ContainerInput from './d2-container-input'
import d2ContainerUploadAli from './d2-container-uploadAli'
import d2ContainerUploadAliBatch from './d2-container-uploadAli-batch'
import d2ContainerUploadAliResearch from './d2-container-uploadAli-research'
import d2ContainerUploadAliAddFile from './d2-container-uploadAli-addFile'
import d2ContainerUploadAliCancel from './d2-container-uploadAli-cancel'
import d2ContainerCarCertification from './d2-container-car-certification'
import d2ContainerMsgList from './d2-container-msg-list'
import d2ContainerResponsibleParty from './d2-container-responsible-party'
import d2ContainerGlodeLocation from './d2-container-golde-location'
import d2ContainerGlodeLocation2 from './d2-container-golde-location-2'
import d2ContainerFpMdm from './d2-container-fpmdm'
import d2ContainerSalesFpMdm from './d2-container-fpsalesmdm'
import d2ContainerInputFpMdm from './d2-container-fpinputmdm'
import d2ContainerEmployeeDispatch from './d2-container-employee-dispatch'
import d2ContainerObjinputSelect from './d2-container-objinput-select2'
import d2ContainerObjAccident from './d2-container-obj-accident'
import d2ContainerEchartsBar from './d2-container-echarts-bar'
import d2ContainerEchartsLine from './d2-container-echarts-line'
import d2ContainerEchartsPie from './d2-container-echarts-pie'
import d2ContainerObjinputSelects from './d2-container-objinput-selects'
import d2ContainerEchartsOperateBar from './d2-container-echarts-operate-bar'
import d2ContainerEchartsOperateLine from './d2-container-echarts-operate-line'
import d2ContainerCompanyDefault from './d2-container-company-default'
import d2ContainerSite from './d2-container-site'
import d2ContainerPaginationSlotinput from './d2-container-pagination-slotinput'
import d2ContainerEchartsLineSupplyHistory from './d2-container-echarts-line-supplyhistory'
import d2ContainerDeptOperation from './d2-container-dept-operation'
import d2ContainerDispatcher from './d2-container-dispatcher'
import d2ContainerDeptDispatch from './d2-container-dept-dispatch'
import d2ContainerUsername from './d2-container-username'
import d2ContainerSettlemdmOil from './d2-container-settlemdm-oil'
import d2ContainerSettlemdmOther from './d2-container-settlemdm-other'
import d2ContainerSettlemdmPay from './d2-container-settlemdm-pay'
import d2ContainerTransactionType from './d2-container-transaction-type'
import d2ContainerItemsName from './d2-container-itemsName'
import d2ContainerMetering from './d2-container-metering'
import d2ContainerObjinputSelectAll from './d2-container-objinput-selectAll'
import d2ContainerForwardingFuzzy from './d2-container-forwarding-fuzzy'
import d2ContainerForwardingFuzzyAll from './d2-container-forwarding-fuzzy-all'
import d2ContainerCarRegistration from './d2-container-car-registration'
import d2ContainerExportRecords from './d2-container-export-records'
import d2ContainerCustomerName from './d2-container-customer-name'
import d2ContainerReceivableReceiverId from './d2-container-receivable-receiverId'
import d2ContainerSenderId from './d2-container-senderId'
import d2ContainerEchartsChainGroupBar from './d2-container-echarts-chaingroup-bar'
import d2ContainerEchartsChainGroupLine from './d2-container-echarts-chaingroup-line'
import d2ContainerEchartsChainGroupPie from './d2-container-echarts-chaingroup-pie'
import d2ContainerPricingType from './d2-container-pricingType'
import d2ContainerIncomeExpenditureSearchTr from './d2-container-income-expenditure-search-tr'
import d2ContainerIncomeExpenditureSearchSg from './d2-container-income-expenditure-search-sg'
import d2ContainerIncomeExpenditureSearchYS from './d2-container-income-expenditure-search-ys'
import d2ContainerIncomeExpenditureEditTr from './d2-container-income-expenditure-edit-tr'
import d2ContainerIncomeExpenditureEditSg from './d2-container-income-expenditure-edit-sg'
import d2ContainerIncomeExpenditureEditYs from './d2-container-income-expenditure-edit-ys'
import d2ContainerEchartsFreightLine from './d2-container-echarts-freight-line'
import d2ContainerVehicle from './d2-container-vehicle'
import d2ContainerCustomerSelect from './d2-container-customer-select'
import d2ContainerCarrierSelect from './d2-container-carrier-select'
import d2ContainerObjinputEditSelect from './d2-container-objinput-edit-select'
import d2ContainerObjinputEditSelectAll from './d2-container-objinput-edit-selectAll'
import d2ContainerInquiryMethod from './d2-container-inquiryMethod'
import d2ContainerDeptRegion from './d2-container-dept-region'
import d2ContainerCapacityUser from './d2-container-capacity-user'
import d2ContainerEntryName from './d2-container-entryName'
import d2ContainerExpiryDate from './d2-container-expiry-date'
import d2ContainerIntroduceProject from './d2-container-introduce-project'
import d2ContainerIntroduceProjectSearch from './d2-container-introduce-project-search'
import d2ContainerPassengerProject from './d2-container-passenger-project'
import d2ContainerPassengerProjectSearch from './d2-container-passenger-project-search'
import d2ContainerTrailer from './d2-container-trailer'
import d2ContainerContractPriceType from './d2-container-contractPriceType'
import d2ContainerChildProjectSelect from './d2-container-child-project-select'
import d2ContainerChildSubprojectCostSelect from './d2-container-child-subprojectCost-select'
import d2ContainerChildSubprojectQuotationSelect from './d2-container-child-subprojectQuotation-select'
import d2ContainerDeptOperationSettle from './d2-container-dept-operationSettle'
import d2ContainerEchartsLineTrend from './d2-container-echarts-line-trend'
import d2ContainerEchartsLineTotalTrend from './d2-container-echarts-line-totalTrend'
import d2ContainerEchartsBarTrend from './d2-container-echarts-bar-trend'
import d2ContainerBatchDownload from './d2-container-batch-download'
import d2ContainerPaymdmSelect from './d2-container-paymdm-select'
import d2ContainerInventorySubject from './d2-container-inventory-subject'
import d2ContainerCustomerProfile from './d2-container-customer-profile'
import d2ContainerProjectProfile from './d2-container-project-profile'
import d2ContainerCustomerFunnel from './d2-container-customer-funnel'
import d2ContainerRoleDispatch from './d2-container-role-dispatch'
import d2ContainerSettleName from './d2-container-settleName'
import d2ContainerPrintRecords from './d2-container-print-records'
import d2ContainerGetLocation from './d2-container-get-location'
import d2ContainerUploadAliApplet from './d2-container-uploadAli-applet'

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container)
Vue.component('d2-container-frame', d2ContainerFrame)
Vue.component('d2-icon', () => import('./d2-icon'))
Vue.component('d2-icon-svg', () => import('./d2-icon-svg/index.vue'))
Vue.component('d2-container-query', d2ContainerQuery)
Vue.component('d2-container-query-setting', d2ContainerQuerySetting)
Vue.component('d2-container-table-header', d2ContainerTableHeader)
Vue.component('d2-container-more-button', d2ContainerMoreButton)
Vue.component('d2-container-table', d2ContainerTable)
Vue.component('d2-container-table-single', d2ContainerTableSingle)
Vue.component('d2-container-edit', d2ContainerEdit)
Vue.component('d2-container-pagination', d2ContainerPagination)
Vue.component('d2-address', d2ContainerAddress)
Vue.component('d2-district', d2ContainerDistrict)
Vue.component('d2-cascader', d2ContainerCascader)
Vue.component('d2-subject', d2ContainerSubject)// 树形
Vue.component('d2-subject-edit', d2ContainerSubjectEdit)// 非树形
Vue.component('d2-mdmselect', d2ContainerMdmselect)
Vue.component('d2-objinput', d2ContainerObjinput)
Vue.component('d2-member', d2ContainerMember)
Vue.component('d2-employee', d2ContainerEmployee)
Vue.component('d2-cussup', d2ContainerCussup)
Vue.component('d2-settlemdm', d2ContainerSettlemdm)
Vue.component('d2-bussup', d2ContainerBusSup)
Vue.component('d2-bank', d2ContainerBank)
Vue.component('d2-bank-moreselect', d2ContainerBankMoreSelect)
Vue.component('d2-table-setting', d2ContainerTableSetting)
Vue.component('d2-dept', d2ContainerDept)
Vue.component('d2-dept-moreselect', d2ContainerDeptMoreSelect)
Vue.component('d2-company-moreselect', d2ContainerCompanySelect)
Vue.component('d2-forwarding', d2ContainerForwardingUnit)
Vue.component('d2-receiving', d2ContainerReceivingUnit)
Vue.component('d2-buyer', d2ContainerBuyer)
Vue.component('d2-progress', d2ContainerProgress)
Vue.component('d2-import', d2ContainerImport)
Vue.component('d2-import-detail', d2ContainerImportDetail)
Vue.component('d2-import-ky', d2ContainerImportKy)
Vue.component('d2-input', d2ContainerInput)
Vue.component('d2-uploadAli', d2ContainerUploadAli)
Vue.component('d2-uploadAli-batch', d2ContainerUploadAliBatch)
Vue.component('d2-uploadAli-research', d2ContainerUploadAliResearch)
Vue.component('d2-uploadAli-addFile', d2ContainerUploadAliAddFile)
Vue.component('d2-uploadAli-cancel', d2ContainerUploadAliCancel)
Vue.component('d2-carCertification', d2ContainerCarCertification)
Vue.component('d2-msg-list', d2ContainerMsgList)
Vue.component('d2-responsible-party', d2ContainerResponsibleParty)
Vue.component('d2-goldeLocation', d2ContainerGlodeLocation)
Vue.component('d2-goldeLocation-2', d2ContainerGlodeLocation2)
Vue.component('d2-fpMdm', d2ContainerFpMdm)
Vue.component('d2-salesFpMdm', d2ContainerSalesFpMdm)
Vue.component('d2-InputFpMdm', d2ContainerInputFpMdm)
Vue.component('d2-employee-dispatch', d2ContainerEmployeeDispatch)
Vue.component('d2-objinput-select', d2ContainerObjinputSelect)
Vue.component('d2-data', d2ContainerData)
Vue.component('d2-data-edit', d2ContainerDataEdit)
Vue.component('d2-data-mulheader', d2ContainerDataMulHeader)
Vue.component('d2-data-mulheader-edit', d2ContainerDataMulHeaderEdit)
Vue.component('d2-data-dynamic', d2ContainerDataDynamic)
Vue.component('d2-obj-accident', d2ContainerObjAccident)
Vue.component('d2-echarts-bar', d2ContainerEchartsBar)
Vue.component('d2-echarts-line', d2ContainerEchartsLine)
Vue.component('d2-echarts-pie', d2ContainerEchartsPie)
Vue.component('d2-objinput-selects', d2ContainerObjinputSelects)
Vue.component('d2-echarts-operate-bar', d2ContainerEchartsOperateBar)
Vue.component('d2-echarts-operate-line', d2ContainerEchartsOperateLine)
Vue.component('d2-company-default', d2ContainerCompanyDefault)
Vue.component('d2-site', d2ContainerSite)
Vue.component('d2-container-pagination-slotinput', d2ContainerPaginationSlotinput)
Vue.component('d2-echarts-line-supply', d2ContainerEchartsLineSupplyHistory)
Vue.component('d2-dept-operation', d2ContainerDeptOperation)
Vue.component('d2-dispatcher', d2ContainerDispatcher)
Vue.component('d2-dept-dispatch', d2ContainerDeptDispatch)
Vue.component('d2-username', d2ContainerUsername)
Vue.component('d2-settlemdmOil', d2ContainerSettlemdmOil)
Vue.component('d2-settlemdmOther', d2ContainerSettlemdmOther)
Vue.component('d2-settlemdmPay', d2ContainerSettlemdmPay)
Vue.component('d2-transactionType', d2ContainerTransactionType)
Vue.component('d2-itemsName', d2ContainerItemsName)
Vue.component('d2-metering', d2ContainerMetering)
Vue.component('d2-objinput-selectAll', d2ContainerObjinputSelectAll)
Vue.component('d2-forwarding-fuzzy', d2ContainerForwardingFuzzy)
Vue.component('d2-forwarding-fuzzy-all', d2ContainerForwardingFuzzyAll)
Vue.component('d2-registration', d2ContainerCarRegistration)
Vue.component('d2-export-records', d2ContainerExportRecords)
Vue.component('d2-customer-name', d2ContainerCustomerName)
Vue.component('d2-receiverId', d2ContainerReceivableReceiverId)
Vue.component('d2-senderId', d2ContainerSenderId)
Vue.component('d2-echarts-chainGroup-bar', d2ContainerEchartsChainGroupBar)
Vue.component('d2-echarts-chainGroup-line', d2ContainerEchartsChainGroupLine)
Vue.component('d2-echarts-chainGroup-pie', d2ContainerEchartsChainGroupPie)
Vue.component('d2-pricingType', d2ContainerPricingType)
Vue.component('d2-income-expenditure-search-tr', d2ContainerIncomeExpenditureSearchTr)
Vue.component('d2-income-expenditure-search-sg', d2ContainerIncomeExpenditureSearchSg)
Vue.component('d2-income-expenditure-search-ys', d2ContainerIncomeExpenditureSearchYS)
// Vue.component('d2-income-search', d2ContainerIncomeExpenditureSearchYS)
Vue.component('d2-income-expenditure-edit-tr', d2ContainerIncomeExpenditureEditTr)
Vue.component('d2-income-expenditure-edit-sg', d2ContainerIncomeExpenditureEditSg)
Vue.component('d2-income-expenditure-edit-ys', d2ContainerIncomeExpenditureEditYs)
// Vue.component('d2-income-edit', d2ContainerIncomeExpenditureEditYs)
Vue.component('d2-echarts-freight-line', d2ContainerEchartsFreightLine)
Vue.component('d2-vehicle', d2ContainerVehicle)
Vue.component('d2-customer-select', d2ContainerCustomerSelect)
Vue.component('d2-carrier-select', d2ContainerCarrierSelect)
Vue.component('d2-objinput-edit-select', d2ContainerObjinputEditSelect)
Vue.component('d2-objinput-edit-selectAll', d2ContainerObjinputEditSelectAll)
Vue.component('d2-inquiryMethod', d2ContainerInquiryMethod)
Vue.component('d2-dept-region', d2ContainerDeptRegion)
Vue.component('d2-capacity-user', d2ContainerCapacityUser)
Vue.component('d2-entryName', d2ContainerEntryName)
Vue.component('d2-expiry-date', d2ContainerExpiryDate)
Vue.component('d2-introduce-project', d2ContainerIntroduceProject)
Vue.component('d2-introduce-project-search', d2ContainerIntroduceProjectSearch)
Vue.component('d2-passenger-project', d2ContainerPassengerProject)
Vue.component('d2-passenger-project-search', d2ContainerPassengerProjectSearch)
Vue.component('d2-trailer', d2ContainerTrailer)
Vue.component('d2-contractPriceType', d2ContainerContractPriceType)
Vue.component('d2-child-project-select', d2ContainerChildProjectSelect)
Vue.component('d2-child-subprojectCost-select', d2ContainerChildSubprojectCostSelect)
Vue.component('d2-child-subprojectQuotation-select', d2ContainerChildSubprojectQuotationSelect)
Vue.component('d2-dept-operation-settle', d2ContainerDeptOperationSettle)
Vue.component('d2-echarts-line-trend', d2ContainerEchartsLineTrend)
Vue.component('d2-echarts-line-totalTrend', d2ContainerEchartsLineTotalTrend)
Vue.component('d2-echarts-bar-trend', d2ContainerEchartsBarTrend)
Vue.component('d2-batch-download', d2ContainerBatchDownload)
Vue.component('d2-paymdm-select', d2ContainerPaymdmSelect)
Vue.component('d2-inventory-subject', d2ContainerInventorySubject)
Vue.component('d2-customer-profile', d2ContainerCustomerProfile)
Vue.component('d2-project-profile', d2ContainerProjectProfile)
Vue.component('d2-customer-funnel', d2ContainerCustomerFunnel)
Vue.component('d2-role-dispatch', d2ContainerRoleDispatch)
Vue.component('d2-settleName', d2ContainerSettleName)
Vue.component('d2-print-records', d2ContainerPrintRecords)
Vue.component('d2-get-location', d2ContainerGetLocation)
Vue.component('d2-uploadAli-applet', d2ContainerUploadAliApplet)
