import request from '@/plugin/axios'
const scope = 'server'

// eslint-disable-next-line camelcase
export const AccountLogin = (username, password, code, randomStr, company_legal, company_name) => {
  // eslint-disable-next-line camelcase
  const grant_type = 'password'

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      'Authorization': 'Basic YmVlOmJlZQ=='
    },
    method: 'post',
    params: { username, password, randomStr, code, grant_type, scope, company_legal, company_name }
  })
}

export const getUserInfo = () => {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

// eslint-disable-next-line camelcase
export const refreshToken = (refresh_token) => {
  // eslint-disable-next-line camelcase
  const grant_type = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      'isToken': false,
      'TENANT-ID': '1',
      'Authorization': 'Basic YmVlOmJlZQ=='
    },
    method: 'post',
    params: { refresh_token, grant_type, scope }
  })
}

export const loginout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

export const loginchange = (companyCode, companyName) => {
  return request({
    url: '/auth/token/logchang',
    method: 'post',
    data: { companyCode, companyName }
  })
}
