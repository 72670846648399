import { Message, MessageBox } from 'element-ui'
import router from '@/router'
import { getMenuD2, getMenuToRoutes, encryption } from '@/tdcommon/beeutil'
import { getStore, clearStore, setStore } from '@/tdcommon/store'
import { getUserInfo, AccountLogin, loginchange, loginout, refreshToken } from '@/api/sys.login'
import { GetMenu } from '@/api/sys.menu'
import watermark from 'watermark-dom'

export default {
  namespaced: true,
  state: {
    userInfo: {},
    permissions: [],
    roles: [],
    userRoutes: getMenuToRoutes(getStore({
      name: 'menu'
    }) || [], true),
    menu: getStore({
      name: 'menu'
    }) || [],
    menuAll: [],
    expires_in: getStore({
      name: 'expires_in'
    }) || '',
    access_token: getStore({
      name: 'access_token'
    }) || '',
    refresh_token: getStore({
      name: 'refresh_token'
    }) || ''
  },
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login ({ commit, dispatch }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: 'beecloudbeecloud',
        param: ['password']
      })
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        AccountLogin(user.username, user.password, user.code, user.randomStr, user.company_legal, user.company_name)
          .then(async res => {
            // 设置store-session一定要存 uuid 和 token 两个 cookie
            // 整个系统依赖这两个数据进行校验和存储
            // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
            // token 代表用户当前登录状态 建议在网络请求中携带 token
            // 如有必要 token 需要定时更新，默认保存一天
            commit('SET_ACCESS_TOKEN', res.access_token)
            commit('SET_REFRESH_TOKEN', res.refresh_token)
            commit('SET_EXPIRES_IN', res.expires_in)
            // commit('CLEAR_LOCK')
            // 结束
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * @description 退出用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 退出
       */
      async function logout () {
        // 删除cookie
        loginout().then(() => {
          commit('REMOVESTORE')
          commit('SET_MENU', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_COMPANY', {})
          commit('SET_USER_INFO', {})
          commit('SET_ACCESS_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          commit('SET_EXPIRES_IN', '')
          commit('SET_ROLES', [])
        })
        // 清空 vuex 用户信息
        await dispatch('d2admin/user/set', {}, { root: true })
        // 跳转路由
        router.push({
          name: 'login'
        })
        // 清空水印
        watermark.load({ watermark_txt: ' ' })
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        MessageBox.confirm('确定要退出当前用户吗', '退出用户', {
          type: 'warning'
        })
          .then(() => {
            commit('d2admin/gray/set', false, { root: true })
            // 清空 vuex 前端缓存
            logout()
          })
          .catch(() => {
            commit('d2admin/gray/set', false, { root: true })
            Message({
              message: '取消退出操作'
            })
          })
      } else {
        logout()
      }
    },
    /**
     * 切换法人公司信息
     * @param commit
     * @param dispatch
     * @param confirm
     */
    loginchange ({ commit, dispatch }, company) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        loginchange(company.companyCode, company.companyName)
          .then(async res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load ({ dispatch }) {
      return new Promise(async resolve => {
        // DB -> store 加载用户
        await dispatch('d2admin/user/load', null, { root: true })
        // DB -> store 加载主题
        await dispatch('d2admin/theme/load', null, { root: true })
        // DB -> store 加载页面过渡效果设置
        await dispatch('d2admin/transition/load', null, { root: true })
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('d2admin/page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('d2admin/menu/asideCollapseLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('d2admin/size/load', null, { root: true })
        // DB -> store 持久化数据加载颜色设置
        await dispatch('d2admin/color/load', null, { root: true })
        // end
        resolve()
      })
    },
    /**
     * 取得用户基本信息
     */
    GetUserInfo ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(async res => {
          const data = res.data || {}
          // 系统逻辑处理
          commit('SET_USER_INFO', data.sysUser)
          commit('SET_ROLES', data.roles || [])
          commit('SET_PERMISSIONS', data.permissions || [])
          // commit('SET_COMPANY', data.sysCompanyLegal || {})
          // data.sysUser.companyCode = data.sysCompanyLegal.companyCode
          // data.sysUser.companyName = data.sysCompanyLegal.companyName
          // 设置 vuex 用户信息
          await dispatch('d2admin/user/set', data.sysUser, { root: true })
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          resolve(data)
        }).catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        })
      })
    },

    /**
     * 刷新token
     */
    RefreshToken ({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token).then(response => {
          commit('SET_ACCESS_TOKEN', response.access_token)
          commit('SET_REFRESH_TOKEN', response.refresh_token)
          commit('SET_EXPIRES_IN', response.expires_in)
          // commit('CLEAR_LOCK')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    /**
     * 退出session
     * @param commit
     * @returns {Promise<unknown>}
     * @constructor
     */
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('REMOVESTORE')
        commit('SET_MENU', [])
        commit('SET_PERMISSIONS', [])
        commit('SET_COMPANY', {})
        commit('SET_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_ROLES', [])
        // commit('DEL_ALL_TAG')
        // commit('CLEAR_LOCK')
        resolve()
      })
    },
    // 获取系统菜单
    GetMenu ({ commit }) {
      return new Promise(resolve => {
        GetMenu().then((res) => {
          const data = res.data
          const menu = getMenuD2(data)
          const route = getMenuToRoutes(menu)
          commit('SET_MENU', menu)
          commit('SET_USERROUTES', route)
          resolve(menu)
        })
      })
    }
  },
  mutations: {
    // eslint-disable-next-line camelcase
    SET_ACCESS_TOKEN: (state, access_token) => {
      // eslint-disable-next-line camelcase
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token,
        type: 'session'
      })
    },
    // eslint-disable-next-line camelcase
    SET_EXPIRES_IN: (state, expires_in) => {
      // eslint-disable-next-line camelcase
      state.expires_in = expires_in
      setStore({
        name: 'expires_in',
        content: state.expires_in,
        type: 'session'
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token,
        type: 'session'
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({
        name: 'menu',
        content: state.menu,
        type: 'session'
      })
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {}
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true
      }
      state.permissions = list
    },
    SET_USERROUTES: (state, userRoutes) => {
      state.userRoutes = userRoutes
    },
    SET_COMPANY: (state, sysCompanyLegal) => {
      state.sysCompanyLegal = sysCompanyLegal
    },
    REMOVESTORE: () => {
      clearStore({ type: 'session' })
    }
  }
}
