<template>
  <div class="d2-container-full">
    <div v-if="$slots.header" class="d2-container-full__header" ref="header">
      <slot name="header"/>
    </div>
    <div v-if="$slots.body_td" class="d2-container-full__body__td" ref="body_td">
      <slot name="body_td"/>
    </div>
    <div class="d2-container-full__body" :hidden="$slots.body_td" ref="body">
      <slot/>
    </div>
    <div v-if="$slots.footer" class="d2-container-full__footer" ref="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import scroll from './mixins/normal'

export default {
  name: 'd2-container-full',
  mixins: [
    scroll
  ],
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    // 增加滚动事件监听
    this.addScrollListener()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    // 移除滚动事件监听
    this.removeScrollListener()
  },
  destroyed () {
  },
  methods: {
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container/components/d2-container-full.vue"
}
</vue-filename-injector>
