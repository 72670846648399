import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsyd/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsyd',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmsyd/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmsyd/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsyd',
    method: 'put',
    data: obj
  })
}

/**
 * 运单审核
 */
export function ydExamine (ids) {
  return request({
    url: '/tms/tmsyd/examine',
    method: 'post',
    params: ids
  })
}

/**
 * 运单反审核
 */
export function ydUnexamine (ids) {
  return request({
    url: '/tms/tmsyd/unexamine',
    method: 'post',
    params: ids
  })
}

/**
 * 运单审单
 */
export function ydAudit (ids) {
  return request({
    url: '/tms/tmsyd/audit',
    method: 'post',
    params: ids
  })
}

/**
 * 运单反审单
 */
export function ydUnaudit (ids) {
  return request({
    url: '/tms/tmsyd/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 新增运单主表
 */
export function tydsavaDD (obj) {
  return request({
    url: '/tms/tmsyd/ydadd',
    method: 'post',
    data: obj
  })
}
/**
 * 更新运单信息
 */
export function ydupdateDD (obj) {
  return request({
    url: '/tms/tmsyd/ydupdate',
    method: 'post',
    data: obj
  })
}
/**
 * 编辑通过id取得运单信息
 */
export function ydedit (id) {
  return request({
    url: '/tms/tmsyd/ydedit/' + id,
    method: 'post'
  })
}
/**
 * 通过id取得运单明细信息
 */
export function getYdDetails (id) {
  return request({
    url: '/tms/tmsyd/yddetails/' + id,
    method: 'post'
  })
}
/**
 * 通过ids批量删除运单
 */
export function yddelObjs (ids) {
  return request({
    url: '/tms/tmsyd/delids',
    method: 'post',
    params: ids
  })
}
/**
 *
 中转库存单分页查询
 */
export function zzoutpage (query) {
  return request({
    url: '/tms/tmsyd/zzoutpage',
    method: 'post',
    data: query
  })
}
/**
 * 中转 预出库信息
 */
export function zzoutcheck (query) {
  return request({
    url: '/tms/tmsyd/zzoutcheck',
    method: 'post',
    params: query
  })
}
/**
 *
 中转-运单预装删除操作
 */
export function zzoutdel (id) {
  return request({
    url: '/tms/tmsyd/zzoutdel/' + id,
    method: 'get'
  })
}
/**
 *
 送货库存单分页查询
 */
export function shoutpage (query) {
  return request({
    url: '/tms/tmsyd/shoutpage',
    method: 'post',
    data: query
  })
}
/**
 * 送货 预出库信息
 */
export function shoutcheck (query) {
  return request({
    url: '/tms/tmsyd/shoutcheck',
    method: 'post',
    params: query
  })
}
/**
 *
 送货-运单预装删除操作
 */
export function shoutdel (id) {
  return request({
    url: '/tms/tmsyd/shoutdel/' + id,
    method: 'get'
  })
}
/**
 *
 短驳托运单库存分页查询
 */
export function dtoutpage (query) {
  return request({
    url: '/tms/tmsyd/dtoutpage',
    method: 'post',
    data: query
  })
}
/**
 * 短驳 预出库信息
 */
export function dtoutcheck (query) {
  return request({
    url: '/tms/tmsyd/dtoutcheck',
    method: 'post',
    params: query
  })
}
/**
 *
 短驳-运单预装删除操作
 */
export function dtoutdel (id) {
  return request({
    url: '/tms/tmsyd/dtoutdel/' + id,
    method: 'get'
  })
}
/*
* 判断托运单号是否重复 *
*/
export function getYdNo (obj) {
  return request({
    url: '/tms/tmsyd/exist/' + obj,
    method: 'get'
  })
}
/**
 * 上传阿里 托运单保存到后台
 */
export function uploadAliTyd (obj) {
  return request({
    url: '/tms/ossobject/tyd',
    method: 'post',
    data: obj
  })
}

/**
 * 打印
 */
export function print (obj) {
  return request({
    url: '/tms/tmsyd/prt',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 科顺打印
 */
export function printKeshun (obj) {
  return request({
    url: '/tms/tmsyd/prt/keshun',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 科顺打印
 */
export function printAllKeshun (obj) {
  return request({
    url: '/tms/tmsyd/allprint',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 固克打印
 */
export function printAllGK (obj) {
  return request({
    url: '/tms/tmsyd/printAllGK',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 视源打印
 */
export function printShiYuan (obj) {
  return request({
    url: '/tms/tmsyd/shiyuanPrt',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 通过ids 批量下载视源签收单
 */
export function bacthShiYuanDownload (ids) {
  return request({
    url: '/tms/tmsyd/downloadPdf',
    method: 'get',
    params: { ids: ids }
  })
}
/*
*科顺打印单号查询 *
*/
export function getPrtYdNo (obj) {
  return request({
    url: '/tms/tmsyd/prt/search',
    method: 'get',
    params: { ydno: obj }
  })
}
/**
 * 复核
 */
export function ydReview (ids) {
  return request({
    url: '/tms/tmsyd/review',
    method: 'post',
    params: ids
  })
}
/**
 * 获取是否已出库已开票状态
 */
export function getYdOut (ids) {
  return request({
    url: '/tms/tmsyd/verify',
    method: 'get',
    params: ids
  })
}
export function exportYdTable (obj) {
  return request({
    url: '/ex/export/exportYdInformation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 通过ids批量修改备案状态
 */
export function batchUpdateInsure (ids) {
  return request({
    url: '/tms/tmsyd/batchUpdateInsureFiling',
    method: 'post',
    params: ids
  })
}
