





























































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-golde-location-2/index.vue"
}
