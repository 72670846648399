import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/bms/accdtaccount/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/bms/accdtaccount',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/bms/accdtaccount/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/bms/accdtaccount/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/bms/accdtaccount',
    method: 'put',
    data: obj
  })
}

/**
 * 银行管理 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/bms/accdtaccount/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 银行管理 银行转账 获取银行账户
 */
export function getBankList () {
  return request({
    url: '/bms/accdtaccount/list',
    method: 'get'
  })
}
/**
 * 银行管理 账户转账 保存
 */
export function transferSave (obj) {
  return request({
    url: '/bms/accdtaccount/transfer',
    method: 'post',
    data: obj
  })
}
/**
 * 银行管理 查询
 */
export function searchList (query) {
  return request({
    url: '/bms/accdtaccount/search',
    method: 'post',
    data: query
  })
}
/**
 * 银行管理 交易历史 查询
 */
export function searchHistory (query) {
  return request({
    url: '/bms/accdtaccount/history',
    method: 'post',
    data: query
  })
}
/*
*银行账户 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/bms/accdtaccount/account/' + obj,
    method: 'get'
  })
}
